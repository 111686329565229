<ng-container *ngIf="isAuthenticated(); else unauthenticated">
  <dx-drawer fxFill fxLayout="column" class="drawer" position="before" [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode" [revealMode]="menuRevealMode" [minSize]="minMenuSize" [shading]="shaderEnabled"
    [(opened)]="menuOpened">
    <!-- SideNav -->
    <div fxLayout="column" id="menu" *dxTemplate="let dataMenu of 'panel'">
      <dx-toolbar fxLayout="row" id="navigation-header">
        <dxi-item *ngIf="minMenuSize !== 0" location="before" cssClass="menu-button" widget="dxButton" [options]="{
          icon: 'assets/images/menu.png',
          stylingMode: 'text',
          onClick: toggleMenu
        }">
        </dxi-item>
        <dxi-item location="center" cssClass="header-title" html={{cmtLogo}}></dxi-item>
      </dx-toolbar>
      <div fxLayout="row" class="menu-container" style="height: 100%;">
        <dx-tooltip [target]="target" [(visible)]="isVisible">
          <div *dxTemplate="let data = data of 'content'">
            <span>{{tooltipContent}}</span>
          </div>
        </dx-tooltip>
        <dx-tree-view #treeview [items]="navigation" keyExpr="path" selectionMode="single" [focusStateEnabled]="false"
          expandEvent="click" (onContentReady)="onContentReady($event)" (onItemClick)="onMenuItemClick($event)"
          width="100%">
        </dx-tree-view>
      </div>
    </div>
    <!-- End SideNav -->
    <div fxFlex="100" fxLayout="column" class="container">
      <header fxLayout="row" id="header">
        <dx-toolbar class="header-toolbar">
          <dxi-item location="before" *ngIf="appService.showMeasureIcon;">
            <img class="riskMeasureStyle" src="assets/images/measure.svg" />
          </dxi-item>
          <dxi-item location="before" *ngIf="appService.showRiskIcon;">
            <img class="riskMeasureStyle" src="assets/images/risikokatalog.svg" />
          </dxi-item>
          <dxi-item location="before" [text]="'Compliance Management Tool'"></dxi-item>
          <dxi-item location="center" [text]="title">
          </dxi-item>
          <dxi-item location="after">
            <dx-switch [(value)]="isDefaultLanguageDe" (onValueChanged)="changeLanguage()" [switchedOffText]="'De'"
              [width]="'100%'" [switchedOnText]="'En'"></dx-switch>
          </dxi-item>

          <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
            <div class="user-info">
              <div class="image-container" *ngIf="currentUser?.hasAvatar"></div>
              <div *ngIf="!currentUser?.hasAvatar">
                <img class="userLogo" src="assets/images/User.svg" />
              </div>
              <div class="user-name">
                {{ currentUser?.displayName }}
              </div>
            </div>
          </dxi-item>
          <!-- <dxi-item location="after">
            <img class="notification" src="assets/images/Notification.svg" />
          </dxi-item> -->
          <dxi-item style="cursor: pointer;" location="after" menuItemTemplate="menuItem">
            <img class="contextMenuHeader" src="assets/images/ContextMenu.svg" />
          </dxi-item>
        </dx-toolbar>
        <dx-context-menu class="settings-context-menu" target=".contextMenuHeader" showEvent="dxclick"
          [items]="contextMenuItems" [position]="{ my: 'top right', at: 'bottom right' }">
        </dx-context-menu>
      </header>
      <!-- Body -->
      <router-outlet fxFill fxLayout="row" (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>
  </dx-drawer>
</ng-container>
<ww-toast #toast></ww-toast>
<ng-template #unauthenticated>
  <core-authentication-login #login class="login" [appTitle]="this.resource.res('Cmt-Login-Title')"
    appLogo="{{cmtWhiteLogo}}" [stylingMode]="'underlined'" [enableForgotPassword]="false" [hasInfoLinks]="false">
  </core-authentication-login>
</ng-template>