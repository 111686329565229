import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { AppService } from '../../../services/app.service';
import { Standard } from '@xmt-models';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumn, QueryColumnSortOrder, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardComponent implements OnInit, OnDestroy {

  @ViewChild('standardGrid') standardGrid: GridComponent;
  public standardList: Standard[] = [];
  public showLoader: boolean;
  public title: string;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  public columns: QueryColumn[] = [];
  private readonly VISIBLE_RISK_COLUMNS = ['Name', 'Designation', 'Description'];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService,
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.STANDARDLIBRARY })
      .pipe(switchMap(layoutStructure => {
        layoutStructure.Columns.forEach(column => {
          this.columnsHeader.push({
            dataField: column.Name,
            caption: this.dataService.res('Cmt-' + column.DisplayText),
            dataType: this.dataService.getDataType(column.DataType),
            alignment: 'left',
            visibleIndex: this.VISIBLE_RISK_COLUMNS.indexOf(column.Name),
            visible: (this.VISIBLE_RISK_COLUMNS.includes(column.Name)) ? true : false,
            showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
            width: this.getColumnWidth(column.Name),
          });
          this.columns.push(this.coreDataService.createQueryColumn(column.Name, column.Name, QueryColumnSortOrder.None));
        });
        return this.dataService.readObjects<Standard[]>(ObjectKeys.STANDARDLIBRARY, this.columns)
          .pipe(map(Standards => (Standards)));
      }))
      .subscribe((Standards) => {
        if (Array.isArray(Standards) && Standards.length > 0) {
          this.standardList = Standards;
        }
      }, error => {
        console.error(error);
      }, () => {
        this.cdr.markForCheck();
        this.showLoader = false;
      });
  }

  public addRow() {
    this.standardGrid.addRow();
  }

  private getColumnWidth(key: string): string {
    if (key === 'Description') {
      return '50%';
    } else if (key === 'Designation') {
      return '25%';
    } else {
      return 'auto'
    }
  }

  public persistStandard = (event): void => {
    if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.STANDARDLIBRARY, event.changes[0].key.Id).subscribe(standard => {
        this.dataService.updateGridData(standard);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
      }));
    }
    else {
      const objectPersistQuery: TargetObjectData = new TargetObjectData();
      objectPersistQuery.ObjectKey = new ObjectKey();
      objectPersistQuery.ObjectKey.ObjectType = ObjectKeys.STANDARDLIBRARY;
      const objectColumns: TargetColumnValue[] = [
        { Name: 'Name', Value: event.changes[0].key.Name },
        { Name: 'Designation', Value: event.changes[0].key.Designation },
        { Name: 'Description', Value: event.changes[0].key.Description }
      ];
      if (event.changes[0].type !== ChangeMode.Insert && event.changes[0].type !== ChangeMode.Remove) {
        objectPersistQuery.Mode = PersistMode.Update;
        objectPersistQuery.ObjectKey.Id = event.changes[0].key.Id;
      }
      else {
        objectPersistQuery.Mode = PersistMode.Insert;
      }
      objectPersistQuery.TargetColumns = objectColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = objectPersistQuery;
      this.persistenceService.executePersistObjectQuery(persistObject).subscribe(
        (data) => {
          this.standardList[this.standardList.length - 1].Id = data.Id;
          this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
        },
        error => {
          console.error(error);
          this.appService.callNotification({ message: error, type: ToastType.ERROR });
        },
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

}
