import { Injectable } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumnSortOrder, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { LegalBasis, LegalBasisLibrary, RiskIssue } from '@xmt-models';
import { Observable } from 'rxjs';
import { DataService, ObjectKeys } from '../../../services/data.service';
@Injectable({
  providedIn: 'root'
})
export class LegalBasisService {


  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    public dataService: DataService
  ) { }

  public persistlegalBasis(mode: PersistMode, legalBasisLibrary): Observable<any> {
    const legalBasisLibraryPersistQuery: TargetObjectData = new TargetObjectData();
    legalBasisLibraryPersistQuery.ObjectKey = new ObjectKey();
    legalBasisLibraryPersistQuery.ObjectKey.ObjectType = ObjectKeys.LEGALBASISLIBRARY;

    const legalBasisColumns: TargetColumnValue[] = [
      { Name: 'Description', Value: legalBasisLibrary.Description },
      { Name: 'Designation', Value: legalBasisLibrary.Designation },
      { Name: 'Link', Value: legalBasisLibrary.Link }
    ];

    legalBasisLibraryPersistQuery.Mode = mode;
    if (mode === PersistMode.Update) legalBasisLibraryPersistQuery.ObjectKey.Id = legalBasisLibrary.Id;
    legalBasisLibraryPersistQuery.TargetColumns = legalBasisColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = legalBasisLibraryPersistQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }
}
