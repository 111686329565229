import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PersistMode } from '@wissenswerft/core/data';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Column } from 'devextreme/ui/data_grid';
import { forkJoin, Subscription } from 'rxjs';
import { PersistModel } from '../../../models/general.model';
import { Module } from '../../../../../../../../libs/xmt-structure/src/lib/models/module.model';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { SharedModule } from '../../../shared/shared.module';
import { ScopeService } from './scope.service';
import { SubModule } from 'libs/xmt-structure/src/lib/models/sub-module.model';

@Component({
  selector: 'scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeComponent implements OnInit, OnDestroy {
  @ViewChild("scopeGrid") scopeGrid: GridComponent;
  public showLoader = true;
  public subscriptions: Subscription[] = [];
  public scopes = [];
  public editingMode = EditingMode.Row;
  public columnsHeader: Column[] = [
    {
      caption: sessionStorage.getItem('fixedCulture') === "en" ? "Designation" : "Bezeichnung",
      dataField: 'Designation',
      dataType: 'string',
      visibleIndex: 1,
    },
    {
      caption: sessionStorage.getItem('fixedCulture') === "en" ? "Category" : "Kategorie",
      dataField: 'ParentId',
      dataType: 'string',
      visibleIndex: 2,
      lookup: {
        dataSource: [],
        displayExpr: 'Designation',
        valueExpr: 'Id'
      }

    }
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    private appService: AppService,
    private scopeService: ScopeService) { }

  ngOnInit(): void {
    const subModulesColumns = [
      { Name: 'Id', OPath: 'Id' },
      { Name: 'Designation', OPath: 'Designation' },
      { Name: 'ParentId', OPath: 'ParentId' }
    ];
    forkJoin([
      this.dataService.readObjects<Module[]>(ObjectKeys.MODULE),
      this.dataService.readObjects<SubModule[]>(ObjectKeys.SUBMODULE, subModulesColumns)
    ]).subscribe((data) => {
      const modules = data[0];
      const subModules = data[1];

      this.scopeGrid.dxDataGrid.instance.columnOption(1, 'lookup.dataSource', modules);
      this.scopes = subModules;
    }, (error) => {
      this.appService.callNotification({
        message: this.dataService.res('Cmt-Notification-Success'),
        type: ToastType.ERROR
      });
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    });
  }


  public onRowUpdated(event): void {
    this.subscriptions.push(
      this.scopeService.persistSubModule<PersistModel>(ObjectKeys.SUBMODULE, PersistMode.Update, event.key.ParentId, event.key.Designation, event.key.Id)
        .subscribe((persistedSubModule) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Success'),
            type: ToastType.SUCCESS
          });
        }, (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
  }
  public onRowInserted(event): void {
    this.subscriptions.push(
      this.scopeService.persistSubModule<PersistModel>(ObjectKeys.SUBMODULE, PersistMode.Insert, event.data.ParentId, event.data.Designation)
        .subscribe((persistedSubModule) => {
          this.scopes[this.scopes.length - 1] = { Id: persistedSubModule.Id, ...this.scopes[this.scopes.length - 1] }

          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Success'),
            type: ToastType.SUCCESS
          });
        }, (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
  }
  public onRowRemoved(event): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.SUBMODULE, event.data.Id).subscribe((deletedSubModule) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Delete'),
            type: ToastType.INFO
          });
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.ERROR
          });
        }
        ))

  }
  public addRow = (): void => {
    this.scopeGrid.addRow();
    this.scopeGrid.dxDataGrid.instance.deselectAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

