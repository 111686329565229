import { GeoDsMeta, Person } from "@geods/base";
import { Measure } from "./measure.model";

export class ResponsiblePlan extends GeoDsMeta {
    PlannedDays: number;
    RemainingDays: number;
    Responsible: string;
    Measure: string;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}