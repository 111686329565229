<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Cmt-App-Measure-Type')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Measure-Benefit-Create')" icon="add"
                    style="width: fit-content;" (onClick)="addRow()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <ww-grid #measureTypesGrid class="grid-group-by-style" [id]="'measureTypeId'" [gridData]="measureTypes"
                [columnsHeader]='columnsHeader' [showRowLines]="true" [enableColumnChooser]="false"
                [editingMode]="'row'" [allowAdding]="false" [enableStateStorage]="false"  (onSaving)="onSavingMeasureType($event)"
                (onSaved)="persistMeasureType($event)">
            </ww-grid>
        </div>
    </div>
</div>
<cmt-loader *ngIf="showLoader"></cmt-loader>