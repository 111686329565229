<dx-scroll-view class="home-scroll" (onReachBottom)="updateContent($event)">
    <div class="layout-body">
        <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="20px" ngClass.gt-xs="ml-10" id="company-card">
            <mat-card fxFlex.sm="0 1 calc(50%-20px)" fxFlex.xs="0 1 calc(50%-20px)" fxFlex.md="0 1 calc(33%-20px)"
                fxFlex.gt-md="0 1 calc(25%-20px)" class="company-card" fxFlex="25" *ngFor="let company of companies">
                <mat-card-content (click)="itemClick(company.Id,company.name)">
                    <div class="company-container">
                        <div fxLayout="row" class="company-name-bloc">
                            <div fxFlex="50px">
                                <img class="image-style" src="assets/images/company.svg">
                            </div>
                            <div fxLayout="column" fxFlex="calc(100% - 50px)">
                                <p class="company-name" id="'company.name'" matTooltip="{{company.name}}">
                                    {{company.name}}
                                </p>
                                <span class="company-match-code" *ngIf="company.companyTitle">{{company.companyTitle}}
                                </span>
                            </div>
                        </div>
                        <div fxLayout="row" class="phone-bloc" *ngIf="company.phone">
                            <div fxFlex="50px">
                                <mat-icon class="phone" style="color: #00569c;font-size: 22px !important;">
                                    phone
                                </mat-icon>
                            </div>
                            <div fxFlex="calc(100% - 50px)">
                                <p class="company-phone">{{company.phone}} </p>
                            </div>
                        </div>
                        <div fxLayout="row" class="contact-bloc" *ngIf="company.eMail">
                            <div fxFlex="50px">
                                <mat-icon class="email" style="color: #00569c; font-size: 22px !important;">
                                    email
                                </mat-icon>
                            </div>
                            <div fxFlex="calc(100% - 50px)">
                                <p class="company-email">{{company.eMail}} </p>
                            </div>
                        </div>
                        <div class="address-div" fxLayout="row">
                            <div fxFlex="50px">
                                <img class="location-style" *ngIf="this.validationFunction(company)"
                                    src="assets/images/land.svg">
                            </div>
                            <div fxLayout="column" class="address-bloc" fxFlex="calc(100% - 50px)">
                                <p class="company-address">
                                    <span *ngIf="company.houseNumber">
                                        {{company.houseNumber}},
                                    </span>
                                    <span>{{company.street}} </span>
                                </p>
                                <p class="company-address">
                                    <span *ngIf="company.zip">{{company.zip}},</span>
                                    <span class="company-address" *ngIf="company.city"> {{company.city}}
                                    </span>
                                </p>
                                <p class="company-address">{{company.country}} </p>
                            </div>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>
</dx-scroll-view>