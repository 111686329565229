<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="titleStyle">{{dataService.res('Cmt-Risks')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Create-Risk')" icon="add"
                    style="width: fit-content;" (onClick)="navigateToCreateRisk()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <div fxLayout="row" class="responsive-paddings">
                <ww-grid #scopeGrid [height]="'100%'" class="grid-group-by-style" [id]="'categoryGrid'"
                    [(gridData)]='risks' [columnsHeader]='columnsHeader' [enableExport]="false"
                    (onCellPrepared)="cellPrepared($event)" [enableColumnChooser]="true" [rowAlternationEnabled]="false"
                    [allowColumnReordering]="false" [allowDeleting]="true" [allowAdding]="false" [allowUpdating]="true"
                    [enableColumnChooser]="false" [enableStateStorage]="false" [hoverStateEnabled]="false"
                    (onCellPrepared)='onGridCellPrepared($event)'>
                </ww-grid>
            </div>
        </div>
    </div>
</div>
<dx-popup #riskPopup [showTitle]="true" [showCloseButton]="false"
    [title]="dataService.res('Cmt-RiskAssesment-Invest-Risk')" [width]="'50%'" [maxWidth]="'700px'" [height]="'auto'"
    [deferRendering]="'false'">
    <cmt-risk-assessment-create (closePopup)="onClosePopup()">
    </cmt-risk-assessment-create>
</dx-popup>
<dx-popup #deleteRiskPopup [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
    [height]="'auto'" [showCloseButton]="false">
    <div class="delete-popup">
        <div>{{dataService.res('Cmt-Delete-Record-Question')}}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <dx-button class="button-confirm" [text]="dataService.res('Cmt-Yes')" (onClick)="deleteRisk()">
            </dx-button>
            <dx-button class="button-cancel" [text]="dataService.res('Cmt-No')" (onClick)="onCloseDeletePopup()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>
<cmt-loader *ngIf="showLoader"></cmt-loader>