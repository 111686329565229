import { Injectable } from "@angular/core";
import { GeoDsCoreDataService, QueryColumnSortOrder } from "@wissenswerft/core/data";
import { Measure, RiskAssesmentMeasure } from "@xmt-models";
import { Observable } from "rxjs";
import { DashBoardMeasure } from "../../models/dashboard.model";
import { DataService, ObjectKeys } from "../../services/data.service";

@Injectable({
  providedIn: 'root'
})

export class AmountOfRiskAssesmentMeasureService {
  public dashBoardMeasure: DashBoardMeasure[] = [];
  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
  ) { }

  public prepareMeasure(): Observable<Measure[]> {
    const columnsModule = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate', QueryColumnSortOrder.None)
    ];
    return this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, columnsModule);
  }

  public prepareRiskAssesmentMeasure(): Observable<RiskAssesmentMeasure[]> {
    const columnsModule = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Value', 'Value', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('measure.Id', 'measureId', QueryColumnSortOrder.None)
    ];
    return this.dataService.readObjects<RiskAssesmentMeasure[]>(ObjectKeys.RISKASSESMENTMEASURE, columnsModule);
  }
}