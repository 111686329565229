<div class="contentStyleClass">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="titleStyle">{{ res('Lib-Document-Document')}}</h2>
        </div>
        <div fxLayout="column" class="add-button-style">
            <dx-button fxLayoutAlign="end" text="{{ res('Lib-Document-Add-Document')}}" icon="add"
                style="width: fit-content;" (onClick)="addDocument()"></dx-button>
        </div>
    </div>

    <div class="content-block">
        <div class="dx-card responsive-paddings">
            <ww-grid [id]="'DokumentsGrid'" class="gridgroupbyStyle iconsStyle" [groupable]="false"
                [searchPanel]="false" [gridData]="documents" [columnsHeader]="documentsColumnsHeader"
                [enableColumnChooser]="false" [enableExport]="false" [showfilterrow]="true" [allowAdding]="false"
                [selectionMode]="'none'" [columnFilter]="false" [showRowLines]="false" [editingMode]="'row'"
                [showColumnLines]="false" [allowDeleting]="false" [allowUpdating]="false" [linkProperty]="'text'"
                [filterPanel]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false">
            </ww-grid>
        </div>

        <dx-popup #addDocumentPopup [showTitle]="true" title="{{ res('Lib-Document-Upload-Document') }}"
            [position]="'center'" [height]="'auto'" [width]="'300px'" [deferRendering]="'false'">
            <dx-file-uploader #fileUploader class="file-uploader"
                selectButtonText="{{ res('Lib-Document-Select-Document') }}" labelText="" accept="*"
                uploadMode="useButtons" [uploadFile]="uploadFile" (onUploaded)="closeDocumentPopUp()">
            </dx-file-uploader>
        </dx-popup>
    </div>

    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }"
        message="{{ res('Lib-Document-Downloading')}}" [(visible)]="showLoader" [showIndicator]="true"
        [showPane]="false" [shading]="true" [closeOnOutsideClick]="false">
    </dx-load-panel>
</div>