import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Measure } from '@xmt-models';
import { DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MeasureCreateComponent } from '../measure-create/measure-create.component';
import { MeasureService } from '../measure.service';

@Component({
  selector: 'cmt-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureListComponent implements OnInit, OnDestroy {
  @ViewChild('measureGrid') measureGrid: GridComponent;
  @ViewChild('createMeasurePopup') createMeasurePopup: DxPopupComponent;
  @ViewChild('confirmDeletePopup') confirmDeletePopup: DxPopupComponent;
  @ViewChild('creationForm') creationForm: MeasureCreateComponent;
  public showLoader = true;
  public measures: Measure[] = [];
  public measure: Measure;
  public measureColumns: QueryColumn[] = [];
  public columnsHeader: Column[] = [];
  private readonly VISIBLE_MEASURE_COLUMNS = ['Designation', 'ParentId', 'IdRefPersonResponsible', 'IdRefPersonAreaManager', 'StartDate', 'EndDate', 'CurrentGrade', 'Status'];
  private subscriptions: Subscription[] = [];
  private measureId: string;

  constructor(
    public dataService: DataService,
    private measureService: MeasureService,
    private coreDataService: GeoDsCoreDataService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.measure = new Measure(null);
    this.subscriptions.push(this.dataService.prepareStaffMembers().pipe(switchMap(staffMembers => {
      if (Array.isArray(staffMembers) && staffMembers.length > 0) {
        this.dataService.cachedResponsiblesResponse = staffMembers;
        this.dataService.cachedResponsiblesResponse.sort((a, b) => a.LastName.localeCompare(b.LastName));
      }
      return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.MEASURE }).pipe(switchMap(layoutStructure => {
        layoutStructure.Columns.forEach(column => {
          this.columnsHeader.push({
            dataField: this.getColumnName(column.Name),
            caption : this.dataService.res('Cmt-Measure-List-' + this.getColumnCaption(column.Name, column.DisplayText) ),
            dataType: this.dataService.getDataType(column.DataType),
            visibleIndex: this.VISIBLE_MEASURE_COLUMNS.indexOf(column.Name),
            visible: (this.VISIBLE_MEASURE_COLUMNS.includes(column.Name)) ? true : false,
            showInColumnChooser: (!this.dataService.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
            groupIndex: (this.getColumnName(column.Name) === 'SubProject') ? 0 : undefined,
            lookup: (this.getLookupColumn(column.Name))
          });
          const OPath = '!.Designation';
          (this.getColumnName(column.Name) === 'SubProject') ? this.measureColumns.push(this.coreDataService.createQueryColumn(OPath, this.getColumnName(column.Name), QueryColumnSortOrder.None)) :
            this.measureColumns.push(this.coreDataService.createQueryColumn(column.Name, this.getColumnName(column.Name), QueryColumnSortOrder.None));
        });
        this.columnsHeader.push({
          type: "buttons",
          caption: '',
          alignment: 'left',
          minWidth: 70,
          dataField: 'edit',
          buttons: [{
            icon: 'edit',
            onClick: (event) => { this.openMeasureDetails(event); }
          }, {
            icon: "trash",
            onClick: (event) => { this.openConfirmDialog(event); }
          }]
        });
        const measureOPath = '!.!.ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'";
        return this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, this.measureColumns, measureOPath).pipe(map(measures => (measures)));

      }));
    })).subscribe((measures) => {
      if (Array.isArray(measures) && measures.length > 0) {
        this.measures = measures;
        this.showLoader = false;
      } else {
        this.dataService.appService.callNotification({ message: this.dataService.res('Ubt-NoData-message'), type: ToastType.INFO });
      }
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }, () => {
      this.cdr.markForCheck();
      this.showLoader = false;
    }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe((measures) => {
      this.measures.push(new Measure(measures));
      this.measureGrid.refreshGrid();
      this.cdr.markForCheck();
    }))

  }
  private openMeasureDetails(event): void {
    const measureDetail = new Measure(event.row.data.measure || event.row.data);
    this.dataService.sendMeasureDetail(measureDetail);
    this.dataService.appService.showMeasureIcon = true;
    this.router.navigate(['measureDetail', measureDetail.Id]);
  }

  public openCreationDialog(): void {
    this.measure = new Measure(null);
    this.creationForm.form.instance.resetValues();
    this.createMeasurePopup.instance.show();
  }

  public closeCreationDialog(): void {
    this.createMeasurePopup.instance.hide();
  }

  private getLookupColumn(key: string) {
    if (key === 'IdRefPersonResponsible' || key === 'IdRefPersonAreaManager' || key === 'IdRefPersonConsultant') {
      return { valueExpr: 'Id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.dataService.cachedResponsiblesResponse };
    } else if (key === 'Status') {
      return { valueExpr: 'name', displayExpr: 'value', dataSource: this.measureService.measureStatus }
    }
  }

  private getColumnName(key: string): string {
    let columnName
    switch (key) {
      case 'IdRefPersonAreaManager':
        columnName = 'AreaManager';
        break;
      case 'IdRefPersonResponsible':
        columnName = 'Responsible';
        break;
      case 'IdRefPersonConsultant':
        columnName = 'ResponsibleConsultant';
        break;
      case 'Designation':
        columnName = 'Title';
        break;
      case 'ParentId':
        columnName = 'SubProject';
        break;
      default:
        columnName = key;
    }
    return columnName;
  }

  private openConfirmDialog(event): void {
    this.measureId = event.row.data.Id;
    this.confirmDeletePopup.instance.show();
  }

  public removeMeasure(): void {
    this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.MEASURE, this.measureId).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Delete"), type: ToastType.INFO });
      this.confirmDeletePopup.instance.hide();
      const index = this.measures.findIndex(item => item.Id === this.measureId);
      this.measures.splice(index, 1);
      this.measureGrid.refreshGrid();
    }, error => {
      console.error(error);
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  private getColumnWidth(key: string): string {
    if (key === 'Designation') {
      return '40%';
    } else {
      return 'auto'
    }
  }

  private getColumnCaption(Name: string, DisplayText: string): string {
    let caption
    switch (Name) {
      case "ParentId":
        caption = "SubProject";
        break;
      case "IdRefPersonResponsible":
        caption = "Responsible";
        break;
      case "IdRefPersonAreaManager":
        caption = "Area-Manager";
        break;
        case "StartDate":
          caption = "Start-Date";
          break;
          case "EndDate":
            caption = "End-Date";
            break;
            case "CurrentGrade":
              caption = "Current-Grade";
              break;
      default:
        caption = DisplayText;
        break;
    }
    return caption;
  }

  public abortDelete(): void {
    this.confirmDeletePopup.instance.hide();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
