/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectorRef, Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, Output, } from '@angular/core';
import { GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../services/data.service';
import { map, switchMap } from 'rxjs/operators';
import { Module, RiskIssue } from '@xmt-models';
import { RiskAssessmentService } from '../customer-space/risk-assesment/risk-assessment.service';
import { MatrixComponent } from '@wissenswerft/ww-library';
import { Column } from 'devextreme/ui/data_grid';
import { DxPolarChartComponent, DxScrollViewComponent } from 'devextreme-angular';
import { DashBoardMeasure, DashBoardRisk, RiskDistribution } from '../models/dashboard.model';
import { DashboardService } from './dashboard.service';
import { AmountOfRiskAssesmentMeasureService } from '../charts/amount-of-risk-assesment-measure/amount-of-risk-assesment-measure.service';

@Component({
  selector: 'cmt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardComponent {

  
  @ViewChild('riskMatrix') riskMatrix: MatrixComponent;
  @ViewChild('spiderChart') spiderChart: DxPolarChartComponent;
  public riskDistributionDataSource: RiskDistribution[] = [];
  private data: RiskDistribution[] = [];
  public dataSource = {};
  private subscriptions: Subscription[] = [];
  public averageRiskAmountOfDamages = 0;
  public showLoader: boolean;
  public measures: DashBoardMeasure[] = [];
  public dashboardRisk: DashBoardRisk[] = [];
  public modules: Module[] = [];
  public companyId = sessionStorage.getItem('currentCompany');
  public riskIssueColumns: QueryColumn[] = [];


  constructor(
    public riskAssessmentService: RiskAssessmentService,
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    private coreDataService: GeoDsCoreDataService,
    public dashboardService: DashboardService,
    private amountRAMService: AmountOfRiskAssesmentMeasureService
  ) { }

  ngOnInit(): void {
    this.riskAssessmentService.risks = [];
    this.dashboardService.dashboardRisk = [];
    this.amountRAMService.dashBoardMeasure=[];

    this.subscriptions.push(
      this.dashboardService.prepareModule().pipe(switchMap(modules => {
        this.modules = modules;
        modules.forEach(element => {
          const dashboardRisk: DashBoardRisk = new DashBoardRisk(element.Id, element.Designation, [], '');
          this.dashboardRisk.push(dashboardRisk);
        });
        return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.RISKISSUE }).pipe(switchMap(layoutStructure => {
          this.riskIssueColumns.push(
            this.coreDataService.createQueryColumn('subModule.Module.Designation', 'module', QueryColumnSortOrder.None),
            this.coreDataService.createQueryColumn('subModule.Module.Id', 'ModuleId', QueryColumnSortOrder.None)
          );
          layoutStructure.Columns.forEach(column => {
            (column.Name === 'IdRefsubModule') ? this.riskIssueColumns.push(this.coreDataService.createQueryColumn('subModule.Designation', column.Name, QueryColumnSortOrder.None)) :
              this.riskIssueColumns.push(this.coreDataService.createQueryColumn(column.Name, column.Name, QueryColumnSortOrder.None));
          });
          const opath = `ParentId='${this.companyId}'`;
          return this.dataService.readObjects<RiskIssue[]>(ObjectKeys.RISKISSUE, this.riskIssueColumns, opath).pipe(map(risks => (risks)));
        }))
      })).subscribe((risks) => {
        if (Array.isArray(risks) && risks.length > 0) {
          let total: number = 0;
          this.riskAssessmentService.risks = risks;
          this.dashboardService.dashboardRisk = this.dashboardRisk;
          risks.forEach(risk => {

            if (risk.Probability && risk.AmountOfDamage) {
              const avrgAD: number = (risk.AmountOfDamage * 100) / 7;
              total += avrgAD;
            }
          });
          this.averageRiskAmountOfDamages = total / this.riskAssessmentService.risks.length;

          const riskAssessments = risks;
          riskAssessments.forEach(risk => {
            this.dashboardRisk.find(element => {
              if (element.id === risk['ModuleId']) {
                element.damage.push(risk.AmountOfDamage)
              }
            })
          });

          this.dashboardRisk.map(element => {
            let riskTotal: number = 0;
            element.damage.map(damage => {
              const avrgAD: number = (damage * 1);
              riskTotal += avrgAD;
            });
            if (element.damage.length != 0) {
              const riskPerModule: number = riskTotal / element.damage.length;
              element.risk = this.prepareRiskLabel(riskPerModule);
            }
          })

          this.cdr.markForCheck();
          this.showLoader = false;
        }
        else {
          this.amountRAMService.dashBoardMeasure=[];
          this.cdr.markForCheck();
          this.showLoader = false;
        }
      }));

  }

  private prepareRiskLabel = (value: number): string => {
    if (value >= 1 && value < 3) {
      return (this.dataService.getLanguage() === 'de') ? 'Niedrig' : "Low";
    } else if (value >= 3 && value < 5) {
      return (this.dataService.getLanguage() === 'de') ? 'Mittel' : "Medium";
    } else if (value >= 5 && value < 7) {
      return (this.dataService.getLanguage() === 'de') ? 'Hoch' : "High";
    } else if (value === 7) {
      return (this.dataService.getLanguage() === 'de') ? 'Sehr Hoch' : "Very High";
    }
  }
public measureOutput(data):void{
  console.log(data);
  
}
  public calculateCellValue(arg: any) {
    return arg.riskReduction + " %";
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}


