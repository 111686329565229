import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { RiskIssue } from '@xmt-models';
import { DxPolarChartComponent } from 'devextreme-angular';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { Damage, SpiderChartAxis, SpiderDataModel } from '../../models/dashboard.model';

@Component({
  selector: 'cmt-risk-per-module-spider-chart',
  templateUrl: './risk-per-module-spider-chart.component.html',
  styleUrls: ['./risk-per-module-spider-chart.component.scss']
})
export class RiskPerModuleSpiderChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('polarChart') polarChart: DxPolarChartComponent;
  @Input() risks: RiskIssue[];
  private subscriptions: Subscription[] = [];
  private overAllSpiderData: SpiderDataModel[] = [];
  private probabilitySpiderData: SpiderDataModel[] = [];
  private damageSpiderData: SpiderDataModel[] = [];
  public selectedSwitch = (this.dataService.getLanguage() === 'de') ? 'Gesamtübersicht' : 'General overview';
  public spiderData: SpiderDataModel[] = [];

  public spiderChartOptions: SpiderChartAxis[] = [{ name: Damage.LOW, color: '#5CE160', valueField: 'countLow', key: 'Cmt-DamageLow' },
  { name: Damage.MEDIUM, color: '#FFDD33', valueField: 'countMedium', key: 'Cmt-DamageMedium' },
  { name: Damage.HIGH, color: '#FFA233', valueField: 'countHigh', key: 'Cmt-DamageHigh' },
  { name: Damage.VERYHIGH, color: '#FF5733', valueField: 'countVeryHigh', key: 'Cmt-DamageVeryHigh' }
  ];

  public spiderChartDataSource = [(this.dataService.getLanguage() === 'de') ? 'Gesamtübersicht' : 'General overview',
  (this.dataService.getLanguage() === 'de') ? 'Schadenshöhe' : 'Damage level',
  (this.dataService.getLanguage() === 'de') ? 'Eintrittswahrscheinlichkeit' : 'Probability of occurrence'
  ];
  constructor(public dataService: DataService) { }

  ngOnInit(): void {

  }

  public ngAfterViewInit() {
    const intervalId = setInterval(() => {
      this.spiderChartOptions.forEach(item => {
        item.name = this.dataService.res(item.key);
        clearInterval(intervalId);
        this.polarChart.instance.option('series', this.spiderChartOptions);
      })
    }, 500);
  }

  public ngOnChanges(): void {
    this.risks.forEach(risk => {
      const moduleExists = this.spiderData?.filter((item) => item.arg === risk['module'])
      if (moduleExists.length === 0) {
        this.probabilitySpiderData.push({ arg: risk['module'], countHigh: 0, countLow: 0, countMedium: 0, countVeryHigh: 0 });
        this.overAllSpiderData.push({ arg: risk['module'], countHigh: 0, countLow: 0, countMedium: 0, countVeryHigh: 0 });
        this.damageSpiderData.push({ arg: risk['module'], countHigh: 0, countLow: 0, countMedium: 0, countVeryHigh: 0 });
      }
    })
    this.prepareChartData();
    this.spiderData = this.overAllSpiderData;

  }

  private prepareChartData(): void {
    this.risks.forEach(risk => {
      if (risk.Probability && risk.AmountOfDamage) {
        this.fillSpiderChartData(risk['module'], risk.AmountOfDamage, this.damageSpiderData);
        this.fillSpiderChartData(risk['module'], risk.Probability, this.probabilitySpiderData);
        this.fillSpiderChartData(risk['module'], this.setProbabilityAverage(risk.AmountOfDamage, risk.Probability), this.overAllSpiderData, true);
      }
    });
  }

  private setProbabilityAverage(amountOfDamage: number, probability: number): number {
    const overAllValue = ((amountOfDamage * probability));
    return overAllValue;
  }


  private fillSpiderChartData(module, value, spiderData: Array<SpiderDataModel>, renderOverAllChart = false): void {
    spiderData.find(x => {
      if (x.arg === module) {
        if (renderOverAllChart == false) {
          if (value?.toString() === '1') {
            x.Niedrig = value;
            x.countLow++;
          } else if (value?.toString() === '3') {
            x.Mittel = value;
            x.countMedium++;
          }
          else if (value?.toString() === '5') {
            x.Hoch = value;
            x.countHigh++;
          } else if (value?.toString() === '7') {
            x.SehrHoch = value;
            x.countVeryHigh++;
          }
        }
        else {
          if (1 <= value && value <= 7) {
            x.Niedrig = value;
            x.countLow++;
          } else if (7 <= value && value <= 21) {
            x.Mittel = value;
            x.countMedium++;
          } else if (21 <= value && value <= 35) {
            x.Hoch = value;
            x.countHigh++;
          } else if (35 <= value && value <= 49) {
            x.SehrHoch = value;
            x.countVeryHigh++;
          }
        }
      }
    });
  }

  public switchChanged(): void {
    if (this.selectedSwitch == 'Eintrittswahrscheinlichkeit' || this.selectedSwitch == 'Probability of occurrence') {
      this.spiderData = this.probabilitySpiderData;
    } else if (this.selectedSwitch == 'Schadenshöhe' || this.selectedSwitch == 'Damage level') {
      this.spiderData = this.damageSpiderData;
    } else {
      this.spiderData = this.overAllSpiderData;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
