import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Module, RiskAssesmentMeasureType, RiskAssesmentMeasure, RiskIssue } from '@xmt-models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode,
  PersistObjectModel, PersistObjectsModel, Query, QueryColumn, QueryObjectsModel,
  TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { RiskAssessmentService, riskMeasureToDisplay } from '../risk-assessment.service';
import { DxPopupComponent } from 'devextreme-angular';
@Component({
  selector: 'cmt-risk-assesment-detail',
  templateUrl: './risk-assesment-detail.component.html',
  styleUrls: ['./risk-assesment-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RiskAssesmentDetailComponent implements OnInit, OnDestroy {
  @ViewChild('saveBeforeNavigate') saveBeforeNavigate: DxPopupComponent;
  private subscriptions: Subscription[] = [];
  public modules: Module[] = [];
  public issueId = this.activatedRoute.snapshot.params.id;

  constructor(
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    public appService: AppService,
    public riskAssessmentService: RiskAssessmentService,
    public dataService: DataService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.subscriptions.push(this.activatedRoute.params.pipe(switchMap((params) => {
      this.issueId = params.id;
      if (this.riskAssessmentService.riskIds.length === 0) {
        this.getIssueIds(this.issueId);
      } else {
        this.riskAssessmentService.currentIssueIndex =
          this.riskAssessmentService.riskIds.findIndex((Id) => Id === this.issueId);
      }
      return this.prepareIssueMeasuresData(this.issueId)
    })).subscribe((modules_assessementMeasure) => {
      this.riskAssessmentService.assignedMeasures = [];
      this.riskAssessmentService.proposedMeasures = [];
      this.modules = modules_assessementMeasure[0];
      const riskAssessementMeasures: RiskAssesmentMeasure[] = modules_assessementMeasure[1];
      if (Array.isArray(riskAssessementMeasures) && riskAssessementMeasures.length > 0) {
        riskAssessementMeasures?.forEach((ram, index) => {
          let riskMeasureToAdd: riskMeasureToDisplay = {
            Id: ram.Id,
            Type: ram.Type,
            Measure: ram.Measure[0].Designation,
            MeasureId: ram.Measure[0].Id,
            RiskReduction: ram.Value ? ram.Value.toString() : "0"
          }
          if (ram.Type === RiskAssesmentMeasureType.ASSIGNED) {
            this.riskAssessmentService.assignedMeasures.push(riskMeasureToAdd);
          } else {
            this.riskAssessmentService.proposedMeasures.push(riskMeasureToAdd);
          }

          if (index === riskAssessementMeasures.length - 1) {
            this.cdr.markForCheck();
          }
        });
      } else {
        this.cdr.markForCheck();
      }
    }, (error) => {
      console.error('error ==> ', error);
    }, () => {
      this.cdr.markForCheck();
    }));

    this.subscriptions.push(this.riskAssessmentService.saveBeforeClosePopup$.subscribe((poPupShown) => {
      if (poPupShown) this.saveBeforeNavigate.instance.show();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
  public backToRiskList(): void {
    this.router.navigate(['risks']);
  }

  public getIssueIds(issueId): void {
    const opath = `ParentId='${sessionStorage.getItem('currentCompany')}'`;
    const riskColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('subModule.Module.Designation', 'module'),
      this.coreDataService.createQueryColumn('subModule.Designation', 'IdRefsubModule')
    ]
    this.dataService.readObjects<RiskIssue[]>(ObjectKeys.RISKISSUE, riskColumns, opath)
      .subscribe((issueIds) => {
        //sort risksIds in same order as they are shown in risk grid , grouped by module and submodule
        this.riskAssessmentService.riskIds = issueIds.sort((riskA, riskB) => {
          if (riskA['module'] === riskB['module']) {
            return riskA.IdRefsubModule < riskB.IdRefsubModule ? -1 : 1;
          } else {
            return riskA['module'] < riskB['module'] ? -1 : 1;
          }
        }).map((issue) => issue.Id);

        this.riskAssessmentService.currentIssueIndex =
          this.riskAssessmentService.riskIds.findIndex((Id) => Id === issueId);
      })
  }

  public prepareIssueMeasuresData(issueId: string): Observable<any> {
    return forkJoin([
      this.getCurrentIssue(issueId).pipe(switchMap((riskIssue) => {

        this.riskAssessmentService.risk = riskIssue[0];

        this.riskAssessmentService.riskToUpdate = { ...riskIssue[0] };

        //TODO this.riskAssessmentService.risk undefined
        this.riskAssessmentService.riskIssuesCountryIds =
          this.riskAssessmentService.risk.Countries ?
            this.riskAssessmentService.risk.Countries?.map(country => country.IdRefCountry) : [];

        this.riskAssessmentService.riskIssuesCountryIdsToUpdate =
          Array.isArray(this.riskAssessmentService.riskIssuesCountryIds) && this.riskAssessmentService.riskIssuesCountryIds.length > 0 ?
            [...this.riskAssessmentService.riskIssuesCountryIds] : [];

        this.riskAssessmentService.risk.DamageKinds?.forEach((damage) => {
          this.riskAssessmentService.riskIssuesDamageKinds.set(damage.IdRefdamageKindLibrary, damage.Designation);
        })
        this.riskAssessmentService.risk.Standards?.forEach((standard) => {
          this.riskAssessmentService.riskIssuesStandards.set(standard.IdRefstandard, standard.Designation);
        });
        return this.getModuleswithSubModules()
      })),
      this.getriskAssessementMeasures(issueId)
    ]);
  }
  public getModuleswithSubModules(): Observable<Module[]> {
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ]
    const moduleQuery: Query = new Query();
    moduleQuery.ObjectType = ObjectKeys.MODULE;
    moduleQuery.Columns = queryColumns;
    const subModuleQuery: Query = new Query();
    subModuleQuery.Name = 'SubModules';
    subModuleQuery.OPath = 'SubModules';
    subModuleQuery.Columns = queryColumns;
    moduleQuery.ObjectQueries = [subModuleQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [moduleQuery];
    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }
  private getCurrentIssue(issueId: string): Observable<RiskIssue> {
    const issueQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ActionNeeded', 'ActionNeeded'),
      this.coreDataService.createQueryColumn('AmountOfDamage', 'AmountOfDamage'),
      this.coreDataService.createQueryColumn('ClarificationRequired', 'ClarificationRequired'),
      this.coreDataService.createQueryColumn('Description', 'Description'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('ExistenceThreatening', 'ExistenceThreatening'),
      this.coreDataService.createQueryColumn('NeedsClarification', 'NeedsClarification'),
      this.coreDataService.createQueryColumn('NotApplicable', 'NotApplicable'),
      this.coreDataService.createQueryColumn('Probability', 'Probability'),
      this.coreDataService.createQueryColumn('Question', 'Question'),
      this.coreDataService.createQueryColumn('Relevant', 'Relevant'),
      this.coreDataService.createQueryColumn('Status', 'Status'),
      this.coreDataService.createQueryColumn('TypeOfDamage', 'TypeOfDamage'),
      this.coreDataService.createQueryColumn('SysDateUpdate', 'SysDateUpdate'),
      this.coreDataService.createQueryColumn('SysTimeUpdate', 'SysTimeUpdate'),
      this.coreDataService.createQueryColumn('IdRefsubModule', 'IdRefsubModule'),
      this.coreDataService.createQueryColumn('IdRefPersonResponsible', 'IdRefPersonResponsible')
    ]
    const issueQuery: Query = new Query();
    issueQuery.ObjectType = ObjectKeys.RISKISSUE;
    issueQuery.Columns = issueQueryColumns;
    issueQuery.OPath = "Id='" + issueId + "'";

    const countriesQuery: Query = new Query();
    countriesQuery.Name = 'Countries';
    countriesQuery.OPath = 'countries';
    countriesQuery.Columns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefCountry', 'IdRefCountry')
    ];

    const damageQuery: Query = new Query();
    damageQuery.Name = 'DamageKinds';
    damageQuery.OPath = 'DamageKinds';
    damageQuery.Columns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefdamageKindLibrary', 'IdRefdamageKindLibrary'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ];

    const standardsQuery: Query = new Query();
    standardsQuery.Name = 'Standards';
    standardsQuery.OPath = 'Standards';
    standardsQuery.Columns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefstandard', 'IdRefstandard'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ];

    issueQuery.ObjectQueries = [countriesQuery, damageQuery, standardsQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [issueQuery];
    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }
  private getriskAssessementMeasures(issueId: string): Observable<RiskAssesmentMeasure[]> {
    const riskAssessementColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Type', 'Type'),
      this.coreDataService.createQueryColumn('Value', 'Value')
    ]
    const riskAssessementMeasureQuery: Query = new Query();
    riskAssessementMeasureQuery.ObjectType = ObjectKeys.RISKASSESMENTMEASURE;
    riskAssessementMeasureQuery.Columns = riskAssessementColumns;
    riskAssessementMeasureQuery.OPath = "ParentId='" + issueId + "'";

    const measureQuery: Query = new Query();
    measureQuery.Name = 'Measure';
    measureQuery.OPath = 'measure';
    measureQuery.Columns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation'),
      this.coreDataService.createQueryColumn('Summary', 'Summary')
    ];

    riskAssessementMeasureQuery.ObjectQueries = [measureQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [riskAssessementMeasureQuery];
    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }
  public persistChanges(navigate = false): void {
    this.subscriptions.push(
      this.persistRiskIssue().pipe(switchMap((riskIssue) =>
        this.deleteDamageStandardsCountry().pipe(
          switchMap((deletedIds) => this.persistDamageStandardsCountry(riskIssue.Id)
          )
        )
      ))
        .subscribe((data) => {
        }, () => {
          this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
        }, () => {
          this.saveBeforeNavigate.instance.hide();
          if (navigate) {
            this.router.navigate([localStorage.getItem("path")]);
            this.riskAssessmentService.clear();
            localStorage.removeItem("path");
          } else {
            this.router.navigate(["risks"]);
          }
          this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
        }))
  }
  public persistRiskIssue(): Observable<any> {
    const RiskPersistQuery: TargetObjectData = new TargetObjectData();
    RiskPersistQuery.ObjectKey = new ObjectKey();
    RiskPersistQuery.ObjectKey.ObjectType = ObjectKeys.RISKISSUE;
    RiskPersistQuery.ObjectKey.Id = this.riskAssessmentService.risk.Id;
    RiskPersistQuery.Mode = PersistMode.Update;

    const riskColumns: TargetColumnValue[] = [
      { Name: 'Designation', Value: this.riskAssessmentService.risk.Designation },
      { Name: 'IdRefPersonResponsible', Value: this.riskAssessmentService.risk.IdRefPersonResponsible },
      { Name: 'IdRefsubModule', Value: this.riskAssessmentService.risk.IdRefsubModule },
      { Name: 'Question', Value: this.riskAssessmentService.risk.Question },
      { Name: 'Description', Value: this.riskAssessmentService.risk.Description },
      { Name: 'AmountOfDamage', Value: this.riskAssessmentService.risk.AmountOfDamage },
      { Name: 'TypeOfDamage', Value: this.riskAssessmentService.risk.TypeOfDamage },
      { Name: 'Probability', Value: this.riskAssessmentService.risk.Probability },
      {
        Name: 'NeedsClarification', Value:
          typeof this.riskAssessmentService.risk.NeedsClarification === 'boolean' ?
            this.riskAssessmentService.risk.NeedsClarification.toString() : null
      },
      {
        Name: 'ExistenceThreatening', Value:
          typeof this.riskAssessmentService.risk.ExistenceThreatening === 'boolean' ?
            this.riskAssessmentService.risk.ExistenceThreatening.toString() : null
      }

    ];
    RiskPersistQuery.TargetColumns = riskColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = RiskPersistQuery;
    return this.persistenceService
      .executePersistObjectQuery(persistObject);
  }
  public persistDamageStandardsCountry(issueId: string): Observable<any> {
    const query: PersistObjectsModel = new PersistObjectsModel();
    let persistQuery: TargetObjectData[][] = [[]];
    persistQuery[1] = [];
    persistQuery[2] = [];
    this.riskAssessmentService.riskIssuesCountryIds?.forEach((countryId) => {
      let countryObjectData: TargetObjectData = new TargetObjectData();
      countryObjectData.Mode = PersistMode.Insert;
      countryObjectData.ObjectKey = new ObjectKey();
      countryObjectData.ObjectKey.ObjectType =
        ObjectKeys.COUNTRY;
      const countryColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: issueId },
        { Name: 'IdRefCountry', Value: countryId }
      ]

      countryObjectData.TargetColumns = countryColumns;
      persistQuery[0].push(countryObjectData);
    });

    Array.from(this.riskAssessmentService.riskIssuesDamageKinds.keys())?.forEach((damageKindId) => {
      let damageKindObjectData: TargetObjectData = new TargetObjectData();
      damageKindObjectData.Mode = PersistMode.Insert;
      damageKindObjectData.ObjectKey = new ObjectKey();
      damageKindObjectData.ObjectKey.ObjectType = ObjectKeys.RISKDAMAGEKIND;
      const damageKindColumns: TargetColumnValue[] = [
        { Name: 'IdRefdamageKindLibrary', Value: damageKindId },
        { Name: 'ParentId', Value: issueId },
        { Name: 'Designation', Value: this.riskAssessmentService.riskIssuesDamageKinds.get(damageKindId) },
      ]

      damageKindObjectData.TargetColumns = damageKindColumns;
      persistQuery[1].push(damageKindObjectData);
    });

    Array.from(this.riskAssessmentService.riskIssuesStandards.keys())?.forEach((standardId) => {
      const standardObjectData: TargetObjectData = new TargetObjectData();
      standardObjectData.Mode = PersistMode.Insert;
      standardObjectData.ObjectKey = new ObjectKey();
      standardObjectData.ObjectKey.ObjectType =
        ObjectKeys.STANDARD;
      const standardColumns: TargetColumnValue[] = [
        { Name: 'IdRefstandard', Value: standardId },
        { Name: 'ParentId', Value: issueId },
        { Name: 'Designation', Value: this.riskAssessmentService.riskIssuesStandards.get(standardId) }
      ]

      standardObjectData.TargetColumns = standardColumns;
      persistQuery[2].push(standardObjectData);
    });

    query.Objects = persistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }
  public deleteDamageStandardsCountry(): Observable<any> {
    const query: PersistObjectsModel = new PersistObjectsModel();
    let persistQuery: TargetObjectData[][] = [[]];
    persistQuery[1] = [];
    persistQuery[2] = [];

    this.riskAssessmentService.riskToUpdate.Countries?.forEach((country) => {
      let countryObjectData: TargetObjectData = new TargetObjectData();
      countryObjectData.Mode = PersistMode.Delete;
      countryObjectData.ObjectKey = new ObjectKey();
      countryObjectData.ObjectKey.ObjectType =
        ObjectKeys.COUNTRY;
      countryObjectData.ObjectKey.Id = country.Id

      persistQuery[0].push(countryObjectData);
    });

    this.riskAssessmentService.riskToUpdate.DamageKinds?.forEach((damageKind) => {
      let damageKindObjectData: TargetObjectData = new TargetObjectData();
      damageKindObjectData.Mode = PersistMode.Delete;
      damageKindObjectData.ObjectKey = new ObjectKey();
      damageKindObjectData.ObjectKey.ObjectType = ObjectKeys.RISKDAMAGEKIND;
      damageKindObjectData.ObjectKey.Id = damageKind.Id;

      persistQuery[1].push(damageKindObjectData);
    });

    this.riskAssessmentService.riskToUpdate.Standards?.forEach((standard) => {
      const standardObjectData: TargetObjectData = new TargetObjectData();
      standardObjectData.Mode = PersistMode.Delete;
      standardObjectData.ObjectKey = new ObjectKey();
      standardObjectData.ObjectKey.ObjectType =
        ObjectKeys.STANDARD;
      standardObjectData.ObjectKey.Id = standard.Id

      persistQuery[2].push(standardObjectData);
    });

    query.Objects = persistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }

  public navigate(): void {
    this.appService.navigateSubject.next(true);
  }
}
