import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { LabelMode, StylingMode } from '@wissenswerft/ww-library';
import { Measure, MeasureTask, Status } from '@xmt-models';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'cmt-geods-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  @Input() measure: Measure = new Measure(null);
  public measureTasks: MeasureTask[];
  public labelMode = LabelMode.Floating;
  public stylingMode = StylingMode.Outlined;

  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnChanges(): void {
    this.measureTasks = this.measure.MeasureTasks;
  }

  public customizeText(arg: any): string {
    return arg.valueText + " %";
  }

  public average(target): number {
    let sum = 0;
    let tasks = 0;

    this.measure.MeasureTasks?.forEach((task) => {
      sum += task[target];
      tasks++;
    });

    const average = sum / tasks || 0;
    if (average > 0 && average < 100) {
      this.measure.Status = Status.InProgress;
    } else if (average === 100) {
      this.measure.Status = Status.Done;
    } else {
      this.measure.Status = Status.Open;
    };


    return average;
  }
}
