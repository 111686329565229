import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EditingMode, GridComponent } from '@wissenswerft/ww-library';
import { RiskAssesmentMeasureType, RiskAssesmentMeasure } from '@xmt-models';
import { DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { Source } from '../../../../models/home.model';
import { RiskAssessmentService, riskMeasureToDisplay } from '../../risk-assessment.service';
@Component({
  selector: 'measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss']
})
export class MeasuresComponent implements OnInit, OnDestroy {
  @Input() measures: RiskAssesmentMeasure[];
  @ViewChild('RiskAssesmentDetailsGrid') riskAssesmentDetailsGrid: GridComponent;
  @ViewChild('assignedMeasureDelete') assignedMeasureDelete: DxPopupComponent;
  public columnsHeader: Column[] = [];
  public editingMode = EditingMode.Row;
  public showUpdateButton = true;
  private riskReductions = [25, 50, 75, 100];
  private subscriptions: Subscription[] = [];
  private measureRowData: riskMeasureToDisplay;

  constructor(public dataService: DataService, private router: Router,
    public riskAssessmentService: RiskAssessmentService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.columnsHeader.push(
      {
        dataField: 'Type',
        caption: 'Type',
        dataType: 'string'
      }, {
      dataField: 'Measure',
      caption: 'Measure',
      dataType: 'string'
    }, {
      dataField: 'RiskReduction',
      caption: 'Risk reduction',
      dataType: 'string',
      lookup: { dataSource: this.riskReductions }
    },
      {
        type: "buttons",
        caption: '',
        alignment: 'left',
        minWidth: 70,
        buttons: [{
          icon: 'chevronright',
          onClick: (event) => { this.navigateToMeasure(event) }
        }, {
          icon: 'edit',
          visible: (e) => this.isUpdateVisible(e),
          onClick: (event) => { this.startUpdate(event) }
        },
        {
          icon: 'save',
          visible: (e) => !this.isUpdateVisible(e),
          onClick: (event) => { this.updateMeasure(event) }
        }, {
          icon: "trash",
          onClick: (event) => { this.openAssignedMeasureDelete(event); }
        }]
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
  public onEditorPreparing(e: any): void {
    if ((e.dataField == "Type" || e.dataField == "Measure") && e.parentType == "dataRow") {
      e.editorOptions.disabled = !e.row.inserted;
    }
  }
  private isUpdateVisible(e): boolean {
    return this.showUpdateButton;
  }
  private navigateToMeasure(event): void {
    this.dataService.setClickOrigin({ id: this.activatedRoute.snapshot.params.id, source: Source.RiskDetail, value: true });
    this.router.navigate(['measureDetail', event.row.data.MeasureId]);
  }
  private startUpdate(event): void {
    this.showUpdateButton = false;
    this.riskAssesmentDetailsGrid.dxDataGrid.instance.editRow(event.row.rowIndex);
  }
  private updateMeasure(event): void {
    let { Id, RiskReduction } = event.row.data;

    this.showUpdateButton = true;
    this.subscriptions.push(this.riskAssessmentService.updateRiskAssessmentMeasureRiskReduction(Id, false, RiskReduction).subscribe((updatedRam) => {
      this.riskAssesmentDetailsGrid.dxDataGrid.instance.saveEditData();
    }, (error) => {
      console.error(error)
    }));
  }
  public openAssignedMeasureDelete(event): void {
    this.measureRowData = event.row.data;
    this.assignedMeasureDelete.instance.show();
  }
  public closeAssignedMeasureDelete(): void {
    this.assignedMeasureDelete.instance.hide();
  }
  public moveAssignedMeasureToProposed(): void {
    this.subscriptions.push(this.riskAssessmentService.updateRiskAssessmentMeasureRiskReduction(this.measureRowData.Id, true, RiskAssesmentMeasureType.SUGGESTION).subscribe((updatedRam) => {
      this.riskAssessmentService.proposedMeasures.push(this.measureRowData);
      this.riskAssessmentService.assignedMeasures = this.riskAssessmentService.assignedMeasures.filter((measure) => measure.Id != this.measureRowData.Id);
    }, (error) => {
      console.error(error)
    }, () => {
      this.closeAssignedMeasureDelete();
    }));
  }


}
