<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Cmt-App-Module')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Scope-Create-Module')" icon="add"
                    style="width: fit-content;" (onClick)="addRow()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <div fxLayout="row" class="responsive-paddings">
                <ww-grid #modulesGrid [height]="'100%'" class="grid-group-by-style" [id]="'categoryGrid'"
                    [(gridData)]='modules' [columnsHeader]='columnsHeader' [enableExport]="false"
                    [enableColumnChooser]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="false"
                    [allowDeleting]="true" [allowAdding]="false" [allowUpdating]="true" [enableColumnChooser]="false"
                    [editingMode]="editingMode" (onRowUpdating)="onRowUpdated($event)"
                    (onRowInserted)="onRowInserted($event)" (onRowRemoved)="onRowRemoved($event)"
                    [enableStateStorage]="false">
                </ww-grid>
            </div>
        </div>

    </div>
</div>

<cmt-loader *ngIf="showLoader"></cmt-loader>