<dx-accordion class="accrodion-style" [collapsible]="true" [animationDuration]="300" [visible]="true" [multiple]="false"
    [dataSource]="['form']">
    <div *dxTemplate=" let data of 'title'">
        {{dataService.res('Cmt-Details')}}
    </div>
    <div *dxTemplate=" let data of 'item'">
        <dx-text-area labelMode="floating" label="info-text" [(value)]="risk.Description">

        </dx-text-area>
    </div>
</dx-accordion>