<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Cmt-Tag-Tags')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Tag-Create')" icon="add"
                    style="width: fit-content;" (onClick)="addRow()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <ww-grid #tagsGrid class="grid-group-by-style" [gridData]='tagList' [columnsHeader]='columnsHeader'
                [enableStateStorage]="false" [enableColumnChooser]="false" [editingMode]='"row"' [allowAdding]="false"
                [showRowLines]="true" (onSaved)="persistTag($event)">
            </ww-grid>
        </div>
    </div>
</div>
<cmt-loader *ngIf="showLoader"></cmt-loader>