<div class="accrodionStyle" fxLayout="row" fxLayoutGap="5px">
    <dx-accordion class="accordion-style" [collapsible]="true" [animationDuration]="300" [visible]="true"
        [multiple]="false" [dataSource]="['Test']">
        <div *dxTemplate="let company of 'title'" class="accordionStyle">
            {{dataService.res('Cmt-MeasureDetail-Tasks')}}
        </div>
        <div *dxTemplate="let company of 'item'">
            <div fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" style="align-self: flex-end !important;">
                    <dx-button [icon]="'assets/images/plus.svg'" [stylingMode]="'text'" (onClick)="addRow($event)">
                    </dx-button>
                </div>
                <div fxLayout="row">
                    <ww-grid #measureTasksGrid class="grid-group-by-style" style="max-height: 150px;"
                        [id]="'AufgabenGrid'" [height]="'100%'" [groupable]="false" [enableColumnChooser]="false"
                        [gridData]='measureTasks' [enableExport]="false" [columnsHeader]='columnsHeader'
                        [editingMode]='"row"' [showfilterrow]="false" [selectionMode]="'single'" [columnFilter]="false"
                        [showRowLines]="false" [showColumnLines]="false" [allowDeleting]="true" [allowUpdating]="true"
                        [filterPanel]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false"
                        [searchPanel]="false" [allowAdding]="false" (onCellPrepared)="onCellPrepared($event)"
                        (onSaved)="persistTask($event)">
                    </ww-grid>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                    <div fxLayout="column" fxFlex="70">
                    </div>
                </div>
            </div>
        </div>
    </dx-accordion>
</div>