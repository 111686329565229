import { Injectable } from '@angular/core';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class MeasureTaskService {

    constructor(
        private persistenceService: GeoDsPersistenceService
    ) { }

    public persistTask<T>(objectKey: string, persistMode: PersistMode,
        parentId: string,
        Subject: string,
        Efficiency: string,
        DegreeOfImplementation: string,
        id?: string): Observable<T> {
        const taskPersistQuery: TargetObjectData = new TargetObjectData();
        taskPersistQuery.ObjectKey = new ObjectKey();
        taskPersistQuery.ObjectKey.ObjectType = objectKey;

        const objectColumns: TargetColumnValue[] = [
            { Name: 'ParentId', Value: parentId },
            { Name: 'Subject', Value: Subject },
            { Name: 'Efficiency', Value: Efficiency },
            { Name: 'DegreeOfImplementation', Value: DegreeOfImplementation }
        ];
        taskPersistQuery.Mode = persistMode;
        if (persistMode === PersistMode.Update) {
            taskPersistQuery.ObjectKey.Id = id;
        }
        taskPersistQuery.TargetColumns = objectColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = taskPersistQuery;
        return this.persistenceService.executePersistObjectQuery(persistObject);
    }
}
