import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DxDataGridModule } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Tag } from '@xmt-models';
import { PersistMode, } from '@wissenswerft/core/data';
import { Subscription } from 'rxjs';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { AppService } from '../../../services/app.service';
@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, DxDataGridModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent implements OnInit, OnDestroy {
  @ViewChild('tagsGrid') tagsGrid: GridComponent;
  public tagList: Tag[] = [];
  public tag: Tag;
  public showLoader = false;
  public isUpdating = false;
  public columnsHeader: Column[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    public dataService: DataService,
    public appService: AppService,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {

    this.showLoader = true;
    this.subscriptions.push(this.dataService.readObjects<Tag[]>(ObjectKeys.TAGLIBRARY).subscribe(tags => {
      this.columnsHeader.push({
        caption: this.dataService.res('Cmt-Tag-Designation'),
        dataField: 'Designation',
        dataType: 'string',
        visibleIndex: 0
      })
      if (Array.isArray(tags) && tags.length > 0) {
        this.tagList = tags;
      }
      this.cdr.markForCheck();
    }, (error) => {
      console.error(error);
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    },
      () => {
        this.showLoader = false;
      }
    ));
  }

  public addRow(): void {
    this.tagsGrid.addRow();
  }

  public persistTag = (event): void => {
    if (event.changes[0].type === ChangeMode.Insert) {
      this.subscriptions.push(this.dataService.persistObject<Tag>
        (ObjectKeys.TAGLIBRARY, PersistMode.Insert, event.changes[0].key.Designation).subscribe((tag) => {
          if (!this.tagList[this.tagList.length - 1]?.Id) { this.tagList[this.tagList.length - 1].Id = tag.Id; }
        },
          (error) => {
            console.error(error);
            this.appService.callNotification({ message: error, type: ToastType.ERROR });
          }, () => { this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS }); }
        ));
    }
    else if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.TAGLIBRARY, event.changes[0].key.Id).subscribe((tag) => { this.dataService.updateGridData(tag); },
        (error) => {
          console.error(error); this.appService.callNotification({ message: error, type: ToastType.ERROR });
        }, () => { this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS }); }
      ));
    }
    else {
      this.subscriptions.push(this.dataService.persistObject(ObjectKeys.TAGLIBRARY, PersistMode.Update, event.changes[0].key.Designation, event.changes[0].key.Id).subscribe(() =>
        (error) => { console.error(error); this.appService.callNotification({ message: error, type: ToastType.ERROR }); }, () => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
      }));
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => { subscription.unsubscribe(); });
  }
}