import { NgModule } from '@angular/core';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DxButtonModule, DxFormModule, DxLoadPanelModule, DxNumberBoxModule, DxPopupModule, DxSelectBoxModule, DxSpeedDialActionModule, DxTextAreaModule, DxTextBoxModule, DxValidatorModule, DxCircularGaugeModule, DxScrollViewModule, DxAccordionModule, DxDateBoxModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CmtLoaderComponent } from './cmt-loader/cmt-loader.component';
@NgModule({
  declarations: [CmtLoaderComponent],
  imports: [
    FlexLayoutModule,
    WwLibraryModule,
    DxSpeedDialActionModule,
    DxPopupModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxButtonModule,
    DxNumberBoxModule,
    DxLoadPanelModule,
    DxFormModule,
    DxValidatorModule,
    DxCircularGaugeModule,
    DxScrollViewModule,
    MatCardModule,
    MatIconModule,
    DxAccordionModule
  ],
  exports: [
    FlexLayoutModule,
    WwLibraryModule,
    DxSpeedDialActionModule,
    DxPopupModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxButtonModule,
    DxNumberBoxModule,
    DxFormModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxCircularGaugeModule,
    DxDateBoxModule,
    DxScrollViewModule,
    MatCardModule,
    MatIconModule,
    CmtLoaderComponent,
    DxAccordionModule
  ]
})
export class SharedModule { }
