import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { PersistMode } from '@wissenswerft/core/data';
import { AppService } from '../../../services/app.service';
import { DamageKind } from '@xmt-models';
@Component({
  selector: 'damage-kind',
  templateUrl: './damage-kind.component.html',
  styleUrls: ['./damage-kind.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DamageKindComponent implements OnInit, OnDestroy {

  @ViewChild('damageKindGrid') damageKindGrid: GridComponent;
  public damageKindList: DamageKind[] = [];
  public showLoader: boolean;
  public title: string;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.dataService.readObjects<DamageKind[]>(ObjectKeys.DAMAGEKINDLIBRARY).subscribe(damageKinds => {
      this.columnsHeader.push({
        dataField: 'Designation',
        caption: this.dataService.res('Cmt-Designation'),
        dataType: 'string'
      });
      if (Array.isArray(damageKinds) && damageKinds.length > 0) {
        this.damageKindList = damageKinds;
      } else {
        this.damageKindList = [];
      }
      this.cdr.markForCheck();
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }, () => {
      this.showLoader = false;
    })
  }

  public addRow() {
    this.damageKindGrid.addRow();
  }

  public persistDamageKind = (event): void => {
    if (event.changes[0].type === ChangeMode.Insert) {
      this.subscriptions.push(this.dataService.persistObject<DamageKind>(ObjectKeys.DAMAGEKINDLIBRARY, PersistMode.Insert, event.changes[0].key.Designation).subscribe(damageKind => {
        if (!this.damageKindList[this.damageKindList.length - 1]?.Id) {
          this.damageKindList[this.damageKindList.length - 1].Id = damageKind.Id;
        }
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
      }));
    } else if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.DAMAGEKINDLIBRARY, event.changes[0].key.Id).subscribe(damageKinds => {
        this.dataService.updateGridData(damageKinds);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
      }));
    } else {
      this.subscriptions.push(this.dataService.persistObject(ObjectKeys.DAMAGEKINDLIBRARY, PersistMode.Update, event.changes[0].key.Designation, event.changes[0].key.Id).subscribe(damageKinds => {
        this.dataService.updateGridData(damageKinds);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

}


