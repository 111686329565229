<dx-accordion class="accordion-style" [collapsible]="true" [animationDuration]="300" [visible]="true"
    [multiple]="false" [dataSource]="[dataService.res('Cmt-MeasureDetail-Risk-Allocation')]">
    <div *dxTemplate="let company of 'item'">
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row">
                <ww-grid class="grid-group-by-style" style="max-height: 150px;"
                    [id]="'riskAllocationGrid'" [groupable]="false" [pageSize]="5" [columnsHeader]="columnsHeader"
                    [gridData]="assignedRisks" [showfilterrow]="false" [columnFilter]="false" [filterPanel]="false"
                    [enableExport]="false" [enableColumnChooser]="false" [selectionMode]="'single'" [editingMode]="'row'"
                    [allowDeleting]="true" [allowUpdating]="true" [allowAdding]="false" 
                    [showRowLines]="false" [showColumnLines]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false"
                    [searchPanel]="false" (onCellPrepared)="onCellPrepared($event)"
                    (onRowRemoved)="persistDeleteRAM($event)" (onCellDblClick)="goToRiskDetails($event)" 
                    (onRowUpdated)="updateRow($event)">
                </ww-grid>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex="70">
                    <dx-select-box class="dropDownStyle" [stylingMode]="'outlined'"
                        [placeholder]="dataService.res('Cmt-MeasureDetail-Select-Risk')" 
                        [dataSource]="suggestedRisksDataSource" [displayExpr]="'Designation'" [valueExpr]="'Id'"
                        (onSelectionChanged)="selectRisk($event)">
                    </dx-select-box>
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                    <dx-button [icon]="'assets/images/plus.svg'" [stylingMode]="'text'"
                        (onClick)="addRiskAllocation()">
                    </dx-button>
                </div>
            </div>
        </div>
    </div>
</dx-accordion>