<form action="post" (submit)="createRisk($event)">
    <dx-form #form id="form">
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item>
                <dx-select-box [labelMode]="'floating'" [stylingMode]="'outilined'" [dataSource]="modules"
                    valueExpr="Id" displayExpr="Designation" [label]="dataService.res('Cmt-Module')"
                    [(value)]="moduleId" (onItemClick)="modulesSelect($event)"
                    [placeholder]="dataService.res('Cmt-Create-Risk-Placeholder')">
                </dx-select-box>
            </dxi-item>
            <dxi-item>
                <dx-select-box [labelMode]="'floating'" [stylingMode]="'outilined'" valueExpr="Id"
                    [disabled]="subModules.length===0" [(value)]="riskIssue.IdRefsubModule" [dataSource]="subModules"
                    displayExpr="Designation" [label]="dataService.res('Cmt-CreateRisk-Area')"
                    [placeholder]="dataService.res('Cmt-Create-Risk-Placeholder')">
                </dx-select-box>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item>
                <dx-text-box [label]="dataService.res('Cmt-CreateRisk-Short-Name')" [labelMode]="'floating'"
                    [(value)]="riskIssue.Designation">
                </dx-text-box>
                <dxi-validation-rule type="required">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item>
                <dx-select-box [(value)]="riskIssue.IdRefPersonResponsible" [dataSource]="responsibles"
                    [labelMode]="'floating'" [stylingMode]="'outilined'"
                    [displayExpr]='dataService.getResponsibleFullName' valueExpr="Id"
                    [label]="dataService.res('Cmt-CreateRisk-Responsible')"
                    [placeholder]="dataService.res('Cmt-Create-Risk-Placeholder')">
                </dx-select-box>
            </dxi-item>
        </dxi-item>
        <dxi-item>
            <dx-text-area [placeholder]="dataService.res('Cmt-Create-Risk-Question-Placeholder')"
                [labelMode]="'floating'" [(value)]="riskIssue.Question" [stylingMode]="'outilined'"
                [label]="dataService.res('Cmt-Question')">
            </dx-text-area>
        </dxi-item>
        <dxi-item>
            <dx-text-area [labelMode]="'floating'" [stylingMode]="'outilined'" [(value)]="riskIssue.Description"
                [label]="dataService.res('Cmt-CreateRisk-Info-Text')"
                [placeholder]="dataService.res('Cmt-Create-Risk-Description-Placeholder')">
            </dx-text-area>
        </dxi-item>
        <dxi-item>
            <dx-select-box [labelMode]="'floating'" [stylingMode]="'outilined'" [(value)]="riskIssue.TypeOfDamage"
                [dataSource]="appService.typeOfDamage" valueExpr="value"
                [displayExpr]="language==='de'?'titleDe':'title'" [label]="dataService.res('Cmt-App-Type-Of-Damage')"
                [placeholder]="dataService.res('Cmt-Create-Risk-Placeholder')">
            </dx-select-box>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item>
                <dx-select-box [labelMode]="'floating'" [stylingMode]="'outilined'" [(value)]="riskIssue.AmountOfDamage"
                    [dataSource]="appService.amountOfDamage" valueExpr="value"
                    [displayExpr]="language==='de'?'labelDe':'label'"
                    [label]="dataService.res('Cmt-RiskDetail-Damage-Amount')"
                    [placeholder]="dataService.res('Cmt-Create-Risk-Placeholder')">
                </dx-select-box>
            </dxi-item>
            <dxi-item>
                <dx-select-box [labelMode]="'floating'" [stylingMode]="'outilined'" [(value)]="riskIssue.Probability"
                    [dataSource]="appService.amountOfDamage" valueExpr="value"
                    [displayExpr]="language==='de'?'labelDe':'label'"
                    [label]="dataService.res('Cmt-RiskDetail-Probability-Occurrence')"
                    [placeholder]="dataService.res('Cmt-RiskDetail-Probability-Occurrence')">
                </dx-select-box>
            </dxi-item>
        </dxi-item>

        <dxi-item [cssClass]="'div-buttons'" itemType="group">
            <dxi-item editorType="dxCheckBox" [editorOptions]="createMoreOptions">
            </dxi-item>
            <dxi-item [cssClass]="'btn-create btn-create-large'" itemType="button"
                [(buttonOptions)]="createWithDetailsButtonOption">
            </dxi-item>
            <dxi-item [cssClass]="'btn-create'" itemType="button" [(buttonOptions)]="CreateButtonOptions">
            </dxi-item>
            <dxi-item [cssClass]="'btn-cancel'" itemType="button" [(buttonOptions)]="CancelButtonOptions">
            </dxi-item>
        </dxi-item>

    </dx-form>
</form>