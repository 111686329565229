<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="titleStyle">{{ dataService.res('Cmt-TreeList-Milestones-Tasks') }}</h2>
        </div>
        <div fxLayout="column" class="add-button-style">
            <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-MeasureTask-Create')" icon="add"
                style="width: fit-content;" (onClick)="openCreateTaskPopup()">
            </dx-button>
        </div>
    </div>
    <div class="content-block">
        <div class="dx-card responsive-paddings">
            <ww-grid #taskGrid class="grid-group-by-style chechboxColumn customeExportStyle"
                [enableStateStorage]="false" (onCellPrepared)="onCellPrepared($event)" [allowExportSelectedData]="false"
                [hoverStateEnabled]="false" [showRowLines]="false" [columnsHeader]='columnsHeader'
                [gridData]='measureTasks' [allowDeleting]="false" [allowAdding]="false" [allowUpdating]="false"
                [allowExportSelectedData]="true">
            </ww-grid>
        </div>
    </div>
</div>
<dx-popup #taskPopup class="windowTitleBackground" [showTitle]="true" [showCloseButton]="false" [width]="'50%'"
    [height]="'auto'">
    <form action="post" (submit)="persistTask($event)">
        <dx-form #form id="form">
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item>
                    <dx-text-box name="Label" [labelMode]="'floating'"
                        [label]="dataService.res('Cmt-MeasureTask-Subject')" [(value)]="measureTask.Label">
                    </dx-text-box>
                </dxi-item>
                <dxi-item>
                    <dx-select-box [labelMode]="'floating'" [stylingMode]="'outilined'" valueExpr="Id"
                        [disabled]=" this.dataService.cachedResponsiblesResponse.length===0"
                        [dataSource]="this.dataService.cachedResponsiblesResponse"
                        [displayExpr]='this.dataService.getResponsibleFullName'
                        [label]="dataService.res('Cmt-MeasureTask-IdRefResponsible')"
                        [(value)]="measureTask.Responsible">
                    </dx-select-box>
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item>
                    <dx-date-box name="StartDate" [labelMode]="'floating'" (onValueChanged)="onValueChanged($event)"
                        [label]="dataService.res('Cmt-MeasureTask-StartDate')" [(value)]="measureTask.StartDate">
                    </dx-date-box>
                </dxi-item>
                <dxi-item>
                    <dx-date-box name="EndDate" [labelMode]="'floating'" [(min)]="minDate"
                        [label]="dataService.res('Cmt-MeasureTask-EndDate')" [(value)]="measureTask.EndDate">
                    </dx-date-box>
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item>
                    <dx-select-box class="drop-down-style" fxFill displayExpr="value" valueExpr="name"
                        [label]="dataService.res('Cmt-MeasureTask-TargetMaturityLevel')"
                        [(value)]="measureTask.TargetMaturityLevel" [dataSource]="appService.maturityLevel"
                        [labelMode]="'floating'" [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                    </dx-select-box>
                </dxi-item>
                <dxi-item>
                    <dx-select-box class="drop-down-style" fxFill displayExpr="value" valueExpr="name"
                        [label]="dataService.res('Cmt-MeasureTask-DegreeOfImplementation')"
                        [(value)]="measureTask.DegreeOfImplementation" [dataSource]="appService.percentageValue"
                        [labelMode]="'floating'" [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                    </dx-select-box>
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item>
                    <dx-number-box name="TotalTimeRequired" [labelMode]="'floating'"
                        [label]="dataService.res('Cmt-MeasureTask-TotalTimeRequired')"
                        [(value)]="measureTask.TotalTimeRequired">
                    </dx-number-box>
                </dxi-item>
                <dxi-item>
                    <dx-select-box [visible]="modeCreate" [labelMode]="'floating'" [stylingMode]="'outilined'"
                        valueExpr="Id" [dataSource]="measures" displayExpr="Designation"
                        (onItemClick)="onSelectMeasure($event)" [label]="dataService.res('Cmt-Measure')"
                        [(value)]="measureTask.ParentId">
                    </dx-select-box>
                </dxi-item>
            </dxi-item>
            <dxi-item [cssClass]="'div-buttons'" itemType="group">
                <dxi-item [cssClass]="'btn-create'" itemType="button"
                    [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Cmt-Form-'+submitButtonText) }">
                </dxi-item>
                <dxi-item>
                    <dx-button class="cancel-button" text="{{this.dataService.res('Cmt-Cancel')}}" [width]="'auto'"
                        (onClick)="onCloseTaskPopup()">
                    </dx-button>
                </dxi-item>
            </dxi-item>
        </dx-form>
    </form>
</dx-popup>
<dx-popup #deleteTaskPopup [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
    [height]="'auto'" [showCloseButton]="false">
    <div class="delete-popup">
        <div>{{dataService.res('Cmt-Delete-Record-Question')}}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <dx-button class="button-confirm" [text]="dataService.res('Cmt-Yes')" (onClick)="deleteTask()">
            </dx-button>
            <dx-button class="button-cancel" [text]="dataService.res('Cmt-No')" (onClick)="onCloseDeletePopup()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>
<cmt-loader *ngIf="showLoader"></cmt-loader>