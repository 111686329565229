import { Injectable } from '@angular/core';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ScopeService {

  constructor(private persistenceService: GeoDsPersistenceService) { }

  public persistSubModule<T>(objectKey: string, persistMode: PersistMode, parentId: string, value: string, id?: string): Observable<T> {
    const subModulePersistQuery: TargetObjectData = new TargetObjectData();
    subModulePersistQuery.ObjectKey = new ObjectKey();
    subModulePersistQuery.ObjectKey.ObjectType = objectKey;
    const objectColumns: TargetColumnValue[] = [
      { Name: 'Designation', Value: value },
      { Name: 'ParentId', Value: parentId }
    ];
    subModulePersistQuery.Mode = persistMode;
    if (persistMode === PersistMode.Update) {
      subModulePersistQuery.ObjectKey.Id = id;
    }
    subModulePersistQuery.TargetColumns = objectColumns;
    const subModulePersistObject: PersistObjectModel = new PersistObjectModel();
    subModulePersistObject.Object = subModulePersistQuery;
    return this.persistenceService.executePersistObjectQuery(subModulePersistObject);
  }
}
