<div class="content-style-class">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="titleStyle">{{dataService.res('Cmt-Damage-Kind-Title')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Damage-Kind-Create')" icon="add"
                    style="width: fit-content;" (onClick)="addRow()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <ww-grid #damageKindGrid [columnsHeader]='columnsHeader' [gridData]='damageKindList'
                class="grid-group-by-style" [enableStateStorage]="false" (onSaved)="persistDamageKind($event)"
                [showRowLines]="true" [enableColumnChooser]="false" [editingMode]='"row"' [allowAdding]="false">
            </ww-grid>
        </div>
    </div>
</div>