<div class="contentStyleClass">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 class="titleStyle">{{pluralTitle}}</h2>
        </div>
        <div *ngIf="!isCreateButtonFloating" fxLayout="column"> 
            <dx-button fxLayoutAlign="end" text="{{title}}" icon="add" style="width: fit-content;" (onClick)="openTagDialog()"></dx-button>
        </div>
    </div>
    
    <div class="content-block">
        <div class="dx-card responsive-paddings">
            <ww-grid class="gridgroupbyStyle" #namedItemGrid [id]="id" [gridData]="gridData" [allowExportSelectedData]="false"
                [columnsHeader]='columnsHeader' [showRowLines]="false" [enableColumnChooser]="false"
                (onRowRemoved)="removeRow($event)" (onRowUpdating)="updateRow($event)"
                [editingMode]="editingMode" [allowAdding]="false">
            </ww-grid>
            <dx-speed-dial-action *ngIf="isCreateButtonFloating == true" icon="add" [index]="1" (onClick)="openTagDialog()"></dx-speed-dial-action>
        </div>

        <dx-popup class="windowTitleBackground" [showTitle]="true" title="{{title}}" [width]="'50%'" [height]="'auto'"
            #createTagPopup [showCloseButton]="showCloseButton">
            <div style="align-content: center;">
                <ww-window-layout #windowLayout [showIdentWLayout]="showIdent" [windowData]="namedItem" [labelMode]="windowLabelMode" (closePopup)="onClosePopup()"></ww-window-layout>
            </div>
        </dx-popup>
    </div> 
</div>
