import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Measure, RiskAssesmentMeasure, RiskIssue } from '@xmt-models';
import { Subscription } from 'rxjs';
import { Column } from 'devextreme/ui/data_grid';
import { GeoDsCoreDataService, PersistMode, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { RiskAssesmentMeasureService } from './risk-Assesment-Measure.service';

@Component({
  selector: 'risk-Assesment-Measure',
  templateUrl: './risk-Assesment-Measure.component.html',
  styleUrls: ['./risk-Assesment-Measure.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskAssesmentMeasureComponent implements OnInit, OnDestroy {

  @ViewChild('riskAssessmentMeasureGrid') riskAssessmentMeasureGrid: GridComponent;
  @ViewChild('addriskAssessmentMeasurePopup') addriskAssessmentMeasurePopup: DxPopupComponent;
  @ViewChild('deleteRiskAssessmentMeasurePopup') deleteRiskAssessmentMeasurePopup: DxPopupComponent;
  @ViewChild('form') form: DxFormComponent;
  public language = sessionStorage.getItem('fixedCulture');
  public showLoader: boolean;
  public riskAssessmentMeasures: RiskAssesmentMeasure[] = [];
  public riskAssessmentMeasure = new RiskAssesmentMeasure(null);
  public subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  public riskAssessmentMeasureColumns: QueryColumn[] = [];
  public measures = [];
  public risks: RiskIssue[] = [];
  public submitButtonText: string;
  private selectedRisk = undefined;
  private isUpdating = false;
  private riskAssessmentMeasureId: string;
  private readonly RISKASSESMENTMEASURE = ['ParentId', 'IdRefmeasure', 'Type', 'Value'];

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    public appService: AppService,
    private riskAssesmentMeasureService: RiskAssesmentMeasureService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.subscriptions.push(
      this.riskAssesmentMeasureService.prepareRisk().pipe(switchMap(risks => {
        this.risks = risks;
        return this.riskAssesmentMeasureService.prepareMeasure().pipe(switchMap(measures => {
          this.measures = measures;
          return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.RISKASSESMENTMEASURE }).pipe(switchMap(layoutStructure => {
            layoutStructure.Columns.forEach(column => {
              const xpath = column.Name === 'IdRefmeasure' ? 'Measure' : column.Name === "ParentId" ? "Risk" : column.Name
              this.columnsHeader.push({
                dataField: xpath,
                caption: this.dataService.res('Cmt-App-Risk-Assessment-Measure-' + column.Name),
                dataType: this.dataService.getDataType(column.DataType),
                visibleIndex: this.RISKASSESMENTMEASURE.indexOf(xpath),
                sortIndex: this.RISKASSESMENTMEASURE.indexOf(xpath),
                visible: (this.RISKASSESMENTMEASURE.includes(column.Name)) ? true : false,
                showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
                groupIndex: (column.Name === 'ParentId') ? 0 : undefined,
                lookup: this.getLookupColumn(column.Name)
              });
              (column.Name === 'ParentId') ? this.riskAssessmentMeasureColumns.push(this.coreDataService.createQueryColumn('riskIssue.Designation', "Risk", QueryColumnSortOrder.None)) :
                this.riskAssessmentMeasureColumns.push(this.coreDataService.createQueryColumn(column.Name, xpath, QueryColumnSortOrder.None));
            });
            this.columnsHeader.push({
              type: "buttons",
              caption: '',
              alignment: 'left',
              minWidth: 70,
              dataField: 'edit',
              buttons: [{
                icon: 'edit',
                onClick: (event) => { this.openEditeDialog(event) }
              }, {
                icon: "trash",
                onClick: (event) => { this.openDeleteDialog(event) }
              }]
            });

            return this.dataService.readObjects<RiskAssesmentMeasure[]>(ObjectKeys.RISKASSESMENTMEASURE, this.riskAssessmentMeasureColumns).pipe(map(riskAssessmentMeasure => (riskAssessmentMeasure)));
          }
          ))
        }))
      })).subscribe((data) => {
        this.riskAssessmentMeasures = data;
        this.cdr.markForCheck();
      }, error => {
        console.error(error);
      }, () => {
        this.cdr.markForCheck();
        this.showLoader = false;
      }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe(riskAssessmentMeasure => {
      this.riskAssessmentMeasures.push(riskAssessmentMeasure);
    }))
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'Value':
        return { valueExpr: 'name', displayExpr: 'value', dataSource: this.appService.percentageValue };
      case 'IdRefmeasure':
        return { valueExpr: 'Id', displayExpr: 'Designation', dataSource: this.measures };
    }
  }

  public openDialog(): void {
    this.submitButtonText = 'Create';
    this.riskAssessmentMeasure = new RiskAssesmentMeasure(null);
    this.addriskAssessmentMeasurePopup.instance.show();
    this.addriskAssessmentMeasurePopup.title = this.dataService.res('Cmt-ResponsiblePlan-Create-Responsible-Plan');
    this.isUpdating = false;
  }

  public clearWindow = (): void => {
    this.form?.instance.resetValues();
    this.riskAssessmentMeasure = new RiskAssesmentMeasure(null);
    this.addriskAssessmentMeasurePopup.instance.hide();
  }

  public onSelectRisk(event) {
    this.selectedRisk = event.itemData.Designation;
  }

  public persistRiskAssessmentMeasure = (event) => {
    event.preventDefault();
    const mode = this.isUpdating === false ? PersistMode.Insert : PersistMode.Update;
    this.subscriptions.push(this.riskAssesmentMeasureService.persistResponsiblePlan(mode, this.riskAssessmentMeasure).subscribe((data) => {
      const selectedRiskIndex = this.risks.findIndex((risk) => risk.Id === this.riskAssessmentMeasure.Risk.toString());
      this.selectedRisk = this.risks[selectedRiskIndex].Designation;
      this.riskAssessmentMeasure.Risk = this.selectedRisk;
      if (!this.isUpdating) {
        this.riskAssessmentMeasure.Id = data.Id;
        this.dataService.updateGridData(this.riskAssessmentMeasure);
      } else {
        const selectedIndex = this.riskAssessmentMeasures.findIndex((riskAssessmentMeasure) => riskAssessmentMeasure.Id === this.riskAssessmentMeasure.Id);
        const clonedItem = { ...this.riskAssessmentMeasure };
        this.riskAssessmentMeasures[selectedIndex] = clonedItem;
        this.cdr.markForCheck();
      }
      this.addriskAssessmentMeasurePopup.instance.hide();
      this.dataService.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.cdr.markForCheck();
    }));
  }

  public openEditeDialog(event) {
    this.riskAssessmentMeasureId = event.row.data.Id;
    this.riskAssessmentMeasure = new RiskAssesmentMeasure(event.row.data);
    const selectedRiskIndex = this.risks.findIndex((risk) => risk.Designation === this.riskAssessmentMeasure.Risk.toString());
    this.selectedRisk = this.risks[selectedRiskIndex].Id;
    this.riskAssessmentMeasure.Risk = this.selectedRisk;
    this.submitButtonText = 'Update';
    this.isUpdating = true;
    this.addriskAssessmentMeasurePopup.title = this.dataService.res('Ubt-MeasureTask-Update-Milestone-Task');
    this.addriskAssessmentMeasurePopup.instance.show();
  }

  public openDeleteDialog(event): void {
    this.riskAssessmentMeasureId = event.row.data.Id;
    this.deleteRiskAssessmentMeasurePopup.instance.show();
  }

  public deleteRiskAssessmentMeasure(): void {
    this.subscriptions.push(
      this.dataService.deleteObject(ObjectKeys.RISKASSESMENTMEASURE, this.riskAssessmentMeasureId).subscribe((deleteRiskAssessmentMeasure) => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
        const index = this.riskAssessmentMeasures.findIndex(item => item.Id === deleteRiskAssessmentMeasure['Id']);
        this.riskAssessmentMeasures.splice(index, 1);
        this.cdr.markForCheck();
        this.riskAssessmentMeasureGrid.refreshGrid();
      }, (error) => {
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      })
    );
    this.deleteRiskAssessmentMeasurePopup.instance.hide();
  }

  public onCloseDeletePopup(): void {
    this.deleteRiskAssessmentMeasurePopup.instance.hide();
  }

  public abortResponsibleplanDelete(): void {
    this.deleteRiskAssessmentMeasurePopup.instance.hide();
  }

  public onCellPrepared(e): void {
    if (e.rowType == "data" && e.column.name === 'Value') {
      e.cellElement.style.textAlign = "right";
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}