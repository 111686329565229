
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { PersistMode } from '@wissenswerft/core/data';
import { AppService } from '../../../services/app.service';
import { Product } from '@xmt-models';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductComponent implements OnInit, OnDestroy {
  @ViewChild('productGrid') productGrid: GridComponent;
  public productList: Product[] = [];
  public showLoader: boolean;
  public title: string;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService) { }

  ngOnInit(): void {
    this.showLoader = true;

    this.dataService.readObjects<Product[]>(ObjectKeys.PRODUCTLIBRARY).subscribe(products => {
      this.columnsHeader.push({
        dataField: 'Designation',
        caption: this.dataService.res('Cmt-Designation'),
        dataType: 'string'
      });
      if (Array.isArray(products) && products.length > 0) {
        this.productList = products;
      }
      this.cdr.markForCheck();
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }, () => {
      this.showLoader = false;

    })
  }


  public addRow() {
    this.productGrid.addRow();
  }

  public persistProduct = (event): void => {
    if (event.changes[0].type === ChangeMode.Insert) {
      this.subscriptions.push(this.dataService.persistObject<Product>(ObjectKeys.PRODUCTLIBRARY, PersistMode.Insert, event.changes[0].key.Designation).subscribe(product => {
        if (!this.productList[this.productList.length - 1]?.Id) {
          this.productList[this.productList.length - 1].Id = product.Id;
        }
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
      }));
    } else if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.PRODUCTLIBRARY, event.changes[0].key.Id).subscribe(product => {
        this.dataService.updateGridData(product);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
      }));
    } else {
      this.subscriptions.push(this.dataService.persistObject(ObjectKeys.PRODUCTLIBRARY, PersistMode.Update, event.changes[0].key.Designation, event.changes[0].key.Id).subscribe(product => {
        this.dataService.updateGridData(product);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

}
