

import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { PersistMode } from '@wissenswerft/core/data';
import { AppService } from '../../../services/app.service';
import { Country } from '@xmt-models';
@Component({
  selector: 'country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryComponent implements OnInit, OnDestroy {

  @ViewChild('CountrytGrid') CountrytGrid: GridComponent;
  public countryList: Country[] = [];
  public showLoader: boolean;
  public title: string;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService) { }

  ngOnInit(): void {
    this.showLoader = true;
   
    this.dataService.readObjects<Country[]>(ObjectKeys.COUNTRYLIBRARY).subscribe(countries => {
      this.columnsHeader.push({
        dataField: 'Designation',
        caption: this.dataService.res('Cmt-Designation'),
        dataType: 'string'
      });
      if (Array.isArray(countries) && countries.length > 0) {
        this.countryList = countries;
      } else {
        this.countryList = [];
      }
      this.cdr.markForCheck();
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR })
      console.error(error);
    }, () => {
      this.showLoader = false;
    })
  }

  public addRow() {
    this.CountrytGrid.addRow();
  }

  public persistCountrytGrid = (event): void => {
    if (event.changes[0].type === ChangeMode.Insert) {
      this.subscriptions.push(this.dataService.persistObject<Country>(ObjectKeys.COUNTRYLIBRARY, PersistMode.Insert, event.changes[0].key.Designation).subscribe(country => {
        if (!this.countryList[this.countryList.length - 1]?.Id) {
          this.countryList[this.countryList.length - 1].Id = country.Id;
        }
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    } else if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.COUNTRYLIBRARY, event.changes[0].key.Id).subscribe(countries => {
        this.dataService.updateGridData(countries);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    } else {
      this.subscriptions.push(this.dataService.persistObject(ObjectKeys.COUNTRYLIBRARY, PersistMode.Update, event.changes[0].key.Designation, event.changes[0].key.Id).subscribe(countries => {
        this.dataService.updateGridData(countries);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

}


