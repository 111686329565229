import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Measure, MeasureBenefit } from '@xmt-models';
import { Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../../../services/data.service';

@Component({
  selector: 'cmt-geods-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @Input() measure: Measure = new Measure(null);
  @Output() assignChangesToMeasure: EventEmitter<[string, string]> = new EventEmitter();
  public assignedBenefitsIds: string[] = [];
  public benefitsDataSource: MeasureBenefit[] = [];
  private subscriptions: Subscription[] = [];

  constructor(public dataService: DataService) {}

  public ngOnInit(): void {
    this.subscriptions.push(this.dataService.readObjects<MeasureBenefit[]>(ObjectKeys.MEASUREBENEFITLIBRARY).subscribe(benefits => {
      if (Array.isArray(benefits) && benefits.length > 0) {
        this.benefitsDataSource = benefits;
      } else {
        this.benefitsDataSource = [];
      }

      if (!this.measure.MeasureBenefits) {
        this.measure.MeasureBenefits = [];
      } else {
        this.measure.MeasureBenefits?.forEach(benefit => {   
          const benefitItem = this.benefitsDataSource.find(item => item.Id === benefit['IdRefmeasureBenefit']);
          this.assignedBenefitsIds.push(benefitItem.Id);
        });
      }
    }));
  }

  public synchronizeChanges(event, columnName) {
    this.assignChangesToMeasure.emit([columnName, event.value]);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}