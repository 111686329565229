import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Person } from '@geods/base';
import { GeoDsCoreDataService, PersistMode, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Measure, ResponsiblePlan } from '@xmt-models';
import { DxFormComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { SharedModule } from '../../../shared/shared.module';
import { ResponsiblePlanService } from './responsible-Plan.service';

@Component({
  selector: 'responsible-Plan',
  templateUrl: './responsible-Plan.component.html',
  styleUrls: ['./responsible-Plan.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiblePlanComponent implements OnInit, OnDestroy {
  @ViewChild('responsiblePlanGrid') responsiblePlanGrid: GridComponent;
  @ViewChild('addResponsiblePlanPopup') addResponsiblePlanPopup: DxPopupComponent;
  @ViewChild('measureList') measureList: DxSelectBoxComponent;
  @ViewChild('responsibleList') responsibleList: DxSelectBoxComponent;
  @ViewChild('confirmResponsibleplanPopup') confirmResponsibleplanPopup: DxPopupComponent;
  @ViewChild('form') form: DxFormComponent;

  public responsiblePlan = new ResponsiblePlan(null);
  private readonly VISIBLE_RESPONSIBLEPLAN_COLUMNS = ['ParentId', 'IdRefPersonResponsible', 'PlannedDays', 'RemainingDays'];
  public submitButtonText: string;
  public measures: Measure[] = [];
  public responsibles: Person[] = [];
  public showLoader: boolean;
  public responsiblePlanData: ResponsiblePlan[] = [];
  public columns: QueryColumn[] = [];
  public responsiblePlanColumns: QueryColumn[] = [];
  public columnsHeader: Column[] = [];
  private subscriptions: Subscription[] = [];
  private responsibleplanId: string;
  private isUpdating = false;

  constructor(
    public dataService: DataService,
    private appService: AppService,
    public responsiblePlanService: ResponsiblePlanService,
    private cdr: ChangeDetectorRef,
    private coreDataService: GeoDsCoreDataService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.subscriptions.push(this.dataService.prepareStaffMembers().pipe(switchMap(staffMembers => {
      this.responsibles = staffMembers;      
      return this.responsiblePlanService.prepareMeasure().pipe(switchMap(measures => {
        this.measures = measures;
        return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.RESPONSIBLEPLAN }).pipe(switchMap(layoutStructure => {
          layoutStructure.Columns.forEach(column => {
            const xpath = column.Name === 'IdRefPersonResponsible' ? 'Responsible' : column.Name === "ParentId" ? "Measure" : column.Name
            this.columnsHeader.push({
              dataField: xpath,
              caption: this.dataService.res('Cmt-Responsible-Plan-' + column.Name),
              dataType: this.dataService.getDataType(column.DataType),
              visibleIndex: this.VISIBLE_RESPONSIBLEPLAN_COLUMNS.indexOf(xpath),
              visible: (this.VISIBLE_RESPONSIBLEPLAN_COLUMNS.includes(column.Name)) ? true : false,
              showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
              groupIndex: (column.Name === 'ParentId') ? 1 : undefined,
              lookup: this.getLookupColumn(xpath)
            });
            this.responsiblePlanColumns.push(this.coreDataService.createQueryColumn(column.Name, xpath, QueryColumnSortOrder.None));
          });
          this.columnsHeader.push({
            type: "buttons",
            caption: '',
            alignment: 'left',
            minWidth: 70,
            dataField: 'edit',
            buttons: [{
              icon: 'edit',
              onClick: (event) => { this.openEditResponsiblePlan(event); }
            }, {
              icon: "trash",
              onClick: (event) => { this.openDeleteResponsiblePlanDialog(event); }
            }]
          });
          return this.dataService.readObjects<ResponsiblePlan[]>(ObjectKeys.RESPONSIBLEPLAN, this.responsiblePlanColumns).pipe(map(responsiblePlans => (responsiblePlans)));
        }))
      }))
    })).subscribe((responsiblePlans) => {
      this.responsiblePlanData = responsiblePlans;
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
    }, () => {
      this.cdr.markForCheck();
      this.showLoader = false;
    }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe(responsible => {
      this.responsiblePlanData.push(responsible);
    }))
  }

  public openDialog = (): void => {
    this.submitButtonText = 'Create';
    this.addResponsiblePlanPopup.title = this.dataService.res('Cmt-ResponsiblePlan-Create-Responsible-Plan');
    this.isUpdating = false;
    this.responsiblePlan = new ResponsiblePlan(null);
    this.addResponsiblePlanPopup.instance.show();
  }

  public persistResponsiblePlan = (event) => {
    event.preventDefault();
    const mode = this.isUpdating === false ? PersistMode.Insert : PersistMode.Update;
    this.subscriptions.push(this.responsiblePlanService.persistResponsiblePlan(mode, this.responsiblePlan).subscribe((responsiblePlan) => {
      if (!this.isUpdating) {
        this.responsiblePlan.Id = responsiblePlan.Id;
        this.dataService.updateGridData(this.responsiblePlan);
      } else {
        const selectedIndex = this.responsiblePlanData.findIndex((responsible) => responsible.Id === responsiblePlan.Id);
        const clonedItem = { ...this.responsiblePlan };
        this.responsiblePlanData[selectedIndex] = clonedItem;
        this.cdr.markForCheck();
      }
      this.addResponsiblePlanPopup.instance.hide();
      this.dataService.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
    }));
  }

  public openEditResponsiblePlan = (event) => {
    this.responsibleplanId = event.row.data.Id;
    this.responsiblePlan = new ResponsiblePlan(event.row.data);
    this.submitButtonText = 'Update';
    this.isUpdating = true;
    this.addResponsiblePlanPopup.title = this.dataService.res('Ubt-MeasureTask-Update-Milestone-Task');
    this.addResponsiblePlanPopup.instance.show();
  }

  public openDeleteResponsiblePlanDialog(event): void {
    this.responsibleplanId = event.row?.data.Id;
    this.confirmResponsibleplanPopup.instance.show();
  }

  public deleteResponsibleplan(): void {
    this.subscriptions.push(
      this.dataService.deleteObject(ObjectKeys.RESPONSIBLEPLAN, this.responsibleplanId).subscribe((deletedResponsiblePlan) => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
        const index = this.responsiblePlanData.findIndex(item => item.Id === deletedResponsiblePlan['Id']);
        this.responsiblePlanData.splice(index, 1);
        this.cdr.markForCheck();
        this.responsiblePlanGrid.refreshGrid();
      }, (error) => {
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      })
    );
    this.confirmResponsibleplanPopup.instance.hide();
  }

  public abortResponsibleplanDelete(): void {
    this.confirmResponsibleplanPopup.instance.hide();
  }

  public clearWindow =(): void =>{
    this.form?.instance.resetValues();
    this.responsiblePlan = new ResponsiblePlan(null);
    this.addResponsiblePlanPopup.instance.hide();
  }

  private getLookupColumn(key: string) {
    if (key === 'Responsible') {
      return { valueExpr: 'Id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.responsibles };
    } else if (key === "Measure") {
      return { valueExpr: 'Id', displayExpr: 'Designation', dataSource: this.measures };
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}