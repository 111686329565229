<div class="content-style-class">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: 9px;">
            <dx-button class="sideBySide returnButton" stylingMode="text" icon="back" type="default"
                [hoverStateEnabled]="false" (onClick)="backToRiskList()">
            </dx-button>
            <h2 class="title-style">{{riskAssessmentService.risk?.Designation}}</h2>
        </div>
        <div class="responsive-paddings" fxLayout="row wrap" fxLayoutAlign="stretch none" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="60" fxFlex.lt-sm="100" fxLayoutGap="20px" style="margin-right: 20px;"
                ngClass.lt-sm="details-bloc">
                <cmt-details [risk]="riskAssessmentService.risk"></cmt-details>
                <rating [risk]="riskAssessmentService.risk"></rating>
                <measures [measures]="riskAssessmentService.assignedMeasures"></measures>
                <proposed-measures [measures]="riskAssessmentService.proposedMeasures"></proposed-measures>
            </div>
            <div fxFlex="calc(100% - 70%)" fxFlex.lt-sm="100" ngClass.lt-sm="status-bloc">
                <status [risk]="riskAssessmentService.risk" [modules]="modules"></status>
                <comments flexFill [geoDs]="true" [itemId]="this.issueId"></comments>
            </div>
        </div>
    </div>
    <dx-speed-dial-action [icon]="'save'" (onClick)="persistChanges()"></dx-speed-dial-action>
</div>

<dx-popup #saveBeforeNavigate [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
    [height]="'auto'" [showCloseButton]="false">
    <div class="delete-popup">
        <div>{{dataService.res('Cmt-RiskDetail-Save-Before-Close')}}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <dx-button class="button-confirm" [text]="dataService.res('Cmt-Yes')" (onClick)="persistChanges(true)">
            </dx-button>
            <dx-button class="button-cancel" [text]="dataService.res('Cmt-No')" (onClick)="navigate()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>