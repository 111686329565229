import { GeoDsMeta, Person } from "@geods/base";
import { Module } from "./module.model";
import { RiskIssueDamageKind } from "./risk-issue-damage-kind.model";
import { RiskIssueStandard } from "./risk-issue-standard.model";
import { RiskIssueCountry } from "./risque-issue-country.model";
import { SubModule } from "./sub-module.model";

export class RiskIssue extends GeoDsMeta {
    ActionNeeded: boolean;
    AmountOfDamage: number;
    ClarificationRequired: boolean;
    Description: string;
    Designation: string;
    ExistenceThreatening: boolean;
    NeedsClarification: boolean;
    NotApplicable: boolean;
    Probability: number;
    Question: string;
    Relevant: boolean;
    Status: string;
    TypeOfDamage: string;
    ParentId: string;
    SubModules: SubModule;
    Responsible: Person;
    IdRefPersonResponsible: string;
    IdRefsubModule: string;
    Countries: RiskIssueCountry[];
    Standards: RiskIssueStandard[];
    DamageKinds: RiskIssueDamageKind[];


    constructor(data) {
        super();
        Object.assign(this, data);
    }
}