<dx-scroll-view>
    <div fxFlexFill fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" class="content-class"
        [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
        <!-- Title -->
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="70px">
                <div fxLayoutAlign="start center">
                    <dx-button stylingMode="text" icon="back" type="default" [hoverStateEnabled]="false"
                        (onClick)="backToMeasureList()">
                    </dx-button>
                    <div class="title ellipsis">{{measure.Title}}</div>
                </div>
                <div fxLayoutAlign="end center" fxLayout="column">
                    <div fxLayout="row">
                        <div>
                            <dx-button stylingMode="text" [icon]="'assets/images/navigate_left.svg'" type="default"
                                [hoverStateEnabled]="false" [hint]="dataService.res('Ubt-Navigation-Previous')"
                                (onClick)="navigateToMeasure(-1)">
                            </dx-button>
                        </div>
                        <div>
                            <dx-button stylingMode="text" [icon]="'assets/images/navigate_right.svg'" type="default"
                                [hoverStateEnabled]="false" [hint]="dataService.res('Ubt-Navigation-Next')"
                                (onClick)="navigateToMeasure(1)">
                            </dx-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxFlexFill fxLayout="row">
            <!-- Accordions Section -->
            <div fxLayout="column" fxFlex="65" class="header">
                <div class="accordion-style" fxLayout="column" fxLayoutGap="10px">
                    <div class="accordion-style">
                        <cmt-geods-details #detailAccordion [measure]="measure"
                            (assignChangesToMeasure)="synchronizeChanges($event)"></cmt-geods-details>
                    </div>

                    <div class="accordion-style">
                        <cmt-geods-measure-tasks (measureTasksData)="measureTasksDataOutput($event)">
                        </cmt-geods-measure-tasks>
                    </div>

                    <div class="accordion-style">
                        <cmt-geods-risk-allocation [measureId]="measureId"></cmt-geods-risk-allocation>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxFlex="1"></div>

            <!-- Chart | Details | Comments Section -->
            <div fxLayout="column" fxFlex="35" fxFlexGap="10px">
                <div class="dx-card responsive-paddings chart-section">
                    <div>
                        <cmt-geods-chart [measure]="measure"></cmt-geods-chart>
                    </div>
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <p class="label" style="margin-top: 25px;">
                                {{dataService.res('Cmt-MeasureDetail-Last-Edited')}} :
                            </p>
                        </div>
                        <div fxLayout="column">
                            <p style="padding-left: 30px; margin-bottom: 9px; margin-top: 25px; ">
                                {{getLastUpdatedDateTime()}}
                            </p>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlexOffset="10px" fxLayoutGap="10px" class="dx-card responsive-paddings">
                    <div fxLayout="row">
                        <dx-text-box #measureTitle [width]="'100%'" [(value)]="measure.Title" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-Title')" [labelMode]="'floating'"
                            (onValueChanged)="verifyChanges($event)">
                        </dx-text-box>
                    </div>
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [stylingMode]="'outlined'" [(value)]="measure.Status"
                            [label]="dataService.res('Cmt-Status')" [dataSource]="measureService.status"
                            [width]="'100%'" valueExpr='id' displayExpr='value'>
                        </dx-select-box>
                    </div>
                    <div fxLayout="row">
                        <dx-date-box class="date-box-style" [(value)]="measure.StartDate" [width]="'100%'"
                            [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-MeasureDetail-Start-Date')">
                        </dx-date-box>
                    </div>
                    <div fxLayout="row">
                        <dx-date-box class="date-box-style" [(min)]="measure.StartDate" [(value)]="measure.EndDate"
                            [width]="'100%'" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-MeasureDetail-End-Date')">
                        </dx-date-box>
                    </div>
                    <div fxLayout="row">
                        <dx-select-box fxFill class="drop-down-style" [width]="'100%'" [labelMode]="'floating'"
                            [stylingMode]="'outlined'" [labelMode]="'floating'"
                            [label]="dataService.res('Cmt-MeasureDetail-Responsible')" [(value)]="measure.Responsible"
                            valueExpr="Id" [displayExpr]="dataService.getResponsibleFullName"
                            [dataSource]="dataService.cachedResponsiblesResponse">
                        </dx-select-box>
                    </div>
                    <div fxLayout="row">
                        <dx-date-box class="date-box-style" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [(value)]="measure.ReleaseDate" [label]="dataService.res('Cmt-MeasureDetail-Next-Review')"
                            [height]="'100%'" [width]="'100%'">
                        </dx-date-box>
                    </div>
                    <div fxLayout="row">
                        <dx-tag-box [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-MeasureDetail-Definition')" [(value)]="assignedDefinitionsIds"
                            [width]="'100%'" [dataSource]="definitionsDataSource" [displayExpr]="'Designation'"
                            [valueExpr]="'Id'">
                        </dx-tag-box>
                    </div>
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [stylingMode]="'outlined'" [(value)]='measure.OrgaTech'
                            [label]="this.dataService.res('Cmt-CreateMeasure-structure')"
                            [dataSource]="measureService.orgaTech" [width]="'100%'" valueExpr='label'
                            displayExpr='value'>
                        </dx-select-box>
                    </div>
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [stylingMode]="'outlined'" [(value)]='measure.PrevDect'
                            [label]="this.dataService.res('Cmt-CreateMeasure-controls')"
                            [dataSource]="measureService.prevDect" [width]="'100%'" valueExpr='id' displayExpr="value">
                        </dx-select-box>
                    </div>

                    <div fxLayout="column">
                        <dx-speed-dial-action [icon]="'save'" (onClick)="updateMeasure()"></dx-speed-dial-action>
                    </div>
                </div>
            </div>
        </div>
    </div>
</dx-scroll-view>

<cmt-loader *ngIf="showLoader"></cmt-loader>