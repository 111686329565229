import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PersistMode } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { MeasureType } from '@xmt-models';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { AppService } from '../../../services/app.service';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'measure-type',
  templateUrl: './measure-type.component.html',
  styleUrls: ['./measure-type.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureTypeComponent implements OnInit, OnDestroy {
  @ViewChild('measureTypesGrid') measureTypesGrid: GridComponent;
  public measureTypes: MeasureType[] = [];
  public showLoader: boolean;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService
  ) { }

  public ngOnInit(): void {
    this.showLoader = true;

    this.dataService.readObjects<MeasureType[]>(ObjectKeys.MEASURETYPE).subscribe(types => {
      if (Array.isArray(types) && types.length > 0) {
        this.measureTypes = types;
      } else {
        this.measureTypes = [];
      }
      this.columnsHeader.push({
        dataField: 'Designation',
        caption: this.dataService.res('Cmt-Designation'),
        dataType: 'string'
      });
      this.cdr.markForCheck();
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }, () => {
      this.showLoader = false;
    })
  }

  public addRow() {
    this.measureTypesGrid.addRow();
  }

  public onSavingMeasureType = (event): void => {
    if (this.measureTypes.find(type => { return event.changes[0].data.Designation === type.Designation })) {
      event.cancel = true;
      this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error-Unique-value'), type: ToastType.ERROR });
    }
  }
  
  public persistMeasureType = (event): void => {
    if (event.changes[0].type !== ChangeMode.Remove && (!event.changes[0].key.Designation || event.changes[0].key.Designation === '')) {
      event.cancel = true;
      this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Empty'), type: ToastType.INFO });
    } else
      if (event.changes[0].type === ChangeMode.Insert) {
        this.subscriptions.push(this.dataService.persistObject<MeasureType>(ObjectKeys.MEASURETYPE, PersistMode.Insert, event.changes[0].key.Designation).subscribe(type => {
          if (!this.measureTypes[this.measureTypes.length - 1]?.Id) {
            this.measureTypes[this.measureTypes.length - 1].Id = type.Id;
          }
        }, error => {
          console.error(error);
          this.appService.callNotification({ message: error, type: ToastType.ERROR })
        }, () => {
          this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
        }))
      } else if (event.changes[0].type === ChangeMode.Remove) {
        this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.MEASURETYPE, event.changes[0].key.Id).subscribe(type => {
          this.dataService.updateGridData(type);
        }, error => {
          console.error(error);
          this.appService.callNotification({ message: error, type: ToastType.ERROR })
        }, () => {
          this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
        }))
      } else {
        this.subscriptions.push(this.dataService.persistObject(ObjectKeys.MEASURETYPE, PersistMode.Update, event.changes[0].key.Designation, event.changes[0].key.Id).subscribe(type => {
          this.dataService.updateGridData(type);
        }, error => {
          console.error(error);
          this.appService.callNotification({ message: error, type: ToastType.ERROR })
        }, () => {
          this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
        }))
      }
  }

  public ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
}
