/* eslint-disable no-case-declarations */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppService } from './app.service';
import { DocumentKind, GeoDsDocument, Person } from '@geods/base';
import { ResourceManager } from '@wissenswerft/core/resources';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel,
  Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, ReadListDataSourceModel,
  TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { Measure } from '@xmt-models';
import { ToastType } from '@wissenswerft/ww-library';
import { ClickOrigin } from '../models/home.model';

export enum ObjectKeys {
  ADDRESS = 'Address',
  COLLABORATOR = 'xmtMeasureTaskCollaborator',
  DOCUMENT = 'Document',
  EVENT = 'xmtSubProjectEvent',
  MEASURE = 'xmtMeasure',
  MEASUREPROGRESS = 'xmtMeasureProgress',
  PERSON = 'Person',
  PICKLISTITEM = 'PicklistItem',
  RESOURCE = 'xmtMeasureResource',
  RESPONSIBLEPLAN = 'xmtResponsiblePlan',
  SUBPROJECT = 'xmtSubProject',
  PROJECT = 'xmtProject',
  STAFFMEMBER = 'xmtStaffMember',
  TAG = 'xmtMeasureTag',
  RISKISSUETAG = 'xmtMeasureTag',
  TASK = 'xmtMeasureTask',
  RISKISSUE = 'xmtRiskIssue',
  RISK = 'xmtRiskIssueTag',
  RISKASSESMENTMEASURE = 'xmtRiskAssessmentMeasure',
  MEASUREBENEFIT = 'xmtMeasureBenefit',
  MEASUREDEFINITION = 'xmtMeasureDefinition',
  MEASURETYPE = 'xmtMeasureType',
  MODULE = 'xmtModule',
  SUBMODULE = 'xmtSubModule',
  PRODUCT = 'xmtRiskIssueProduct',
  COUNTRY = 'xmtRiskIssueCountry',
  RISKDAMAGEKIND = 'xmtRiskIssueDamageKind',
  DAMAGEKIND = 'xmtDamageKindLibrary',
  LEGALBASIS = 'xmtRiskIssuelegalBasis',
  STANDARD = 'xmtRiskIssueStandard',
  PRODUCTLIBRARY = 'xmtProductLibrary',
  COUNTRYLIBRARY = 'CountryLibrary',
  DAMAGEKINDLIBRARY = 'xmtDamageKindLibrary',
  LEGALBASISLIBRARY = 'xmtLegalBasisLibrary',
  STANDARDLIBRARY = 'xmtStandardLibrary',
  MEASUREBENEFITLIBRARY = 'xmtMeasureBenefitLibrary',
  MEASUREDEFINITIONLIBRARY = 'xmtMeasureDefinitionLibrary',
  TAGLIBRARY = 'xmtTagLibrary'


}
@Injectable()
export class DataService {
  private measureDetailSubject = new BehaviorSubject<Measure>(null);
  private readonly objectSpaceName = 'ABC.Applications.KAOS.Data';
  private gridDataSubject = new Subject<any>();
  private uploadData: Subject<GeoDsDocument> = new Subject();
  public updateGridData$ = this.gridDataSubject.asObservable();
  public uploadData$: Observable<GeoDsDocument> = this.uploadData.asObservable();
  public cachedResponsiblesResponse: Person[] = [];
  public cachedProfileAvatar: string[] = [];
  public cachedStatus = [];
  public cachedCompaniesTitles = [];
  public clickOrigin: ClickOrigin;
  public res = ResourceManager.getResources("cmt-geods");
  public readonly DEFAULT_COLUMNS: QueryColumn[] = [
    { Name: 'Id', OPath: 'Id' },
    { Name: 'Designation', OPath: 'Designation' }
  ];
  private companyName = new Subject();
  public companyName$ = this.companyName.asObservable();

  constructor(
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public readObjects<T>(objectType: string, columns: QueryColumn[] = this.DEFAULT_COLUMNS, OPath?: string): Observable<T> {
    const readObjectQuery: QueryObjectsModel = new QueryObjectsModel();
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = objectType;
    mainQuery.Columns = columns;
    mainQuery.OPath = OPath;
    if (OPath) {
      mainQuery.OPath = OPath;
    }
    readObjectQuery.ObjectQueries = [mainQuery];
    return this.coreDataService.executeReadObjectsQuery(readObjectQuery);
  }

  public persistObject<T>(objectKey: string, persistMode: PersistMode, value: string, id?: string): Observable<T> {
    const objectPersistQuery: TargetObjectData = new TargetObjectData();
    objectPersistQuery.ObjectKey = new ObjectKey();
    objectPersistQuery.ObjectKey.ObjectType = objectKey;
    const objectColumns: TargetColumnValue[] = [
      { Name: 'Designation', Value: value }
    ];
    objectPersistQuery.Mode = persistMode;
    if (persistMode === PersistMode.Update) {
      objectPersistQuery.ObjectKey.Id = id;
    }
    objectPersistQuery.TargetColumns = objectColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = objectPersistQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

  public deleteObject<T>(objectKey: ObjectKeys, id: string): Observable<T> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = PersistMode.Delete;
    targetObject.ObjectKey = new ObjectKey();
    targetObject.ObjectKey.Id = id;
    targetObject.ObjectKey.ObjectType = objectKey;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }

  public updateGridData(data: any) {
    this.gridDataSubject.next(data);
  }

  public prepareMeasureList(): Observable<Measure[]> {
    const measureOPath = '!.!.ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'";
    const measureColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
    ];
    return this.readObjects<Measure[]>(ObjectKeys.MEASURE, measureColumns, measureOPath);
  }

  public getMeasureDetail(): Observable<Measure> {
    return this.measureDetailSubject.asObservable();
  }

  public sendMeasureDetail(measureAssessment: Measure) {
    this.measureDetailSubject.next(measureAssessment);
  }

  public checkGridGroupByColumns(key: string, groupByColumnIndex) {
    if (localStorage.getItem(key)) {
      const gridState = JSON.parse(localStorage.getItem(key));
      gridState.columns.map(item => {
        if (item.groupIndex) {
          groupByColumnIndex[item.dataField] = item.groupIndex;
          return groupByColumnIndex;
        }
      })
    }
  }

  public prepareStaffMembers(): Observable<Person[]> {
    const columns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('FirstName', 'FirstName', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('LastName', 'LastName', QueryColumnSortOrder.None)
    ];
    const staffMemberOpath = 'ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'" + " && Not(LastName = 'Admin')";
    return this.readObjects<Person[]>(ObjectKeys.PERSON, columns, staffMemberOpath);
  }

  public getDataType(dataType) {
    switch (dataType) {
      case 'String':
        return 'string';
      case 'Integer32':
        return 'number';
      case 'Date':
        return 'date';
      default:
        return 'string';
    }
  }

  public getClickOrigin(): ClickOrigin {
    return this.clickOrigin;
  }

  public setClickOrigin(source: ClickOrigin): void {
    this.clickOrigin = source;
  }

  public getResponsibleFullName(account: Person): string {
    return account ? account?.LastName + ', ' + account?.FirstName : '';
  }

  public customizePercentageText(event) {
    return event ? event + '%' : 0 + '%';
  }

  public loadListDataSource(objectType: string, columnName: string): Observable<any> {
    const readListDataSourceQuery = new ReadListDataSourceModel();
    const objectKey = new ObjectKey();
    objectKey.ObjectType = objectType;
    readListDataSourceQuery.ObjectKey = objectKey;
    readListDataSourceQuery.OutputMode = 0;
    readListDataSourceQuery.ColumnName = columnName;
    return this.coreDataService.executeReadListDataSource(
      readListDataSourceQuery
    );
  }

  public persistUploadDocument(document: GeoDsDocument): void {
    const documentPersistQuery: TargetObjectData = new TargetObjectData();
    documentPersistQuery.ObjectKey = new ObjectKey();
    documentPersistQuery.ObjectKey.ObjectType = ObjectKeys.DOCUMENT;
    const documentColumns: TargetColumnValue[] = [
      { Name: 'Object', Value: this.getBinaryStringFromUrl(document.Object) },
      { Name: 'Designation', Value: document.Designation },
      { Name: 'FileName', Value: document.FileName },
      { Name: 'ParentId', Value: document.ParentId },
      { Name: 'Kind', Value: document.Kind },
      { Name: 'ParentObjectType', Value: document.ParentObjectType }
    ];
    if (document.Id !== undefined) {
      documentPersistQuery.ObjectKey.Id = document.Id;
      documentPersistQuery.Mode = PersistMode.Update;
    } else {
      documentPersistQuery.Mode = PersistMode.Insert;
    }
    documentPersistQuery.TargetColumns = documentColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = documentPersistQuery;
    this.persistenceService.executePersistObjectQuery(persistObject).subscribe((data) => {
      this.updateGridData(data);
      this.appService.callNotification({ message: this.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  private getBinaryStringFromUrl(url: string): string {
    return url.split(',')[1];
  }

  public onFileChanged(event, document: GeoDsDocument): void {
    let documentSource = "";
    let fileName = "";
    if (event?.value !== null) {
      const file = event?.value[0];
      if (file?.name !== undefined) {
        fileName = file.name;
      }
      const reader = new FileReader();
      reader.readAsDataURL(new Blob([file]));
      reader.onloadend = () => {
        documentSource = reader.result as string;
        document.Object = documentSource;
        document.FileName = fileName;
        this.uploadData.next(document);
      };
    }
  }

  public downloadFile(doc: GeoDsDocument) {
    const a = document.createElement('a');
    a.href = `data:${this.getMimeType(doc)};base64,${doc.Object}`;
    a.download = doc.FileName;
    a.click();
  }

  public prepareTitleCompany(scope: string): void {
    this.companyName.next(scope);
  }

  private getMimeType(doc: GeoDsDocument, ext?: string) {
    const _extension = doc.FileName.split('.').pop();
    const extension = (ext || _extension).toLowerCase();
    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'JPG':
        return 'image/jpeg';
      case 'pdf' || 'zip':
        return 'application/' + extension;
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'txt':
        return 'text/plain';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'csv':
        return 'text/csv';
      default:
        const fileNameExt = doc.FileName?.split('.').pop();
        if (fileNameExt && doc.FileName?.indexOf('.') > -1) {
          return this.getMimeType(doc, fileNameExt);
        } else if (doc.FileName.indexOf('.') === -1) {
          if (doc.Kind) {
            switch (doc.Kind) {
              case DocumentKind.Bild:
                return 'image/jpeg';
              default:
                return 'application/octet-stream';
            }
          }
        } else {
          return 'application/octet-stream';
        }
    }
  }

  public getLanguage() {
    if (sessionStorage.getItem('fixedCulture') == 'de') {
      return 'de'
    } else {
      return 'en'
    }
  }
}

export enum ChangeMode {
  Insert = "insert",
  Remove = "remove"
}
