import { Component } from '@angular/core';

@Component({
    selector: 'cmt-loader',
    template: `<div class="fond">
    <div class="contener_general" style="margin-inline: auto;">
    <div class="loader">
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
   </div>
   </div>
   </div>`,
    styleUrls: ['./cmt-loader.component.css']
})
export class CmtLoaderComponent {

}