import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel,
  Query, QueryColumn, QueryObjectsModel, TargetColumnValue, TargetObjectData
} from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { Measure, MeasureBenefit, MeasureDefinition, MeasureTask } from '@xmt-models';
import { DxTextBoxComponent } from 'devextreme-angular';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Source } from '../../../models/home.model';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MeasureService } from '../measure.service';
import { DetailsComponent } from './details/details.component';

@Component({
  selector: 'cmt-geods-measure-detail',
  templateUrl: './measure-detail.component.html',
  styleUrls: ['./measure-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureDetailComponent implements OnInit, OnDestroy {
  @ViewChild('measureTitle') measureTitle: DxTextBoxComponent;
  @ViewChild('detailAccordion') detailAccordion: DetailsComponent;

  public measure: Measure = new Measure(null);
  public showLoader: boolean;
  public measureId: string;
  public measureColumns: QueryColumn[] = [];
  public definitionsDataSource: MeasureDefinition[] = [];
  public assignedDefinitionsIds: string[] = [];
  private returnPath: string;
  private subscriptions: Subscription[] = [];
  private measureDefinitionsColumns: QueryColumn[] = [];
  private measureBenefitsColumns: QueryColumn[] = [];

  constructor(
    public dataService: DataService,
    public measureService: MeasureService,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.measureId = this.activatedRoute.snapshot.params.id;
    localStorage.setItem('measureId', this.measureId);
    this.returnPath = this.activatedRoute.snapshot.params.from;
  }

  public ngOnInit(): void {
    this.showLoader = true;

    this.prepareCurrentMeasure();
  }

  private prepareCurrentMeasure() {
    this.subscriptions.push(forkJoin([this.dataService.prepareStaffMembers(),
    this.coreDataService.executeReadObjectsQuery(this.prepareMeasureDetailColumns(this.measureId)),
    this.dataService.readObjects<MeasureDefinition[]>(ObjectKeys.MEASUREDEFINITIONLIBRARY)
    ]).pipe(first()).subscribe(data => {
      this.dataService.cachedResponsiblesResponse = data[0];
      this.measure = data[1][0];

      if (Array.isArray(data[2]) && data[2].length > 0) {
        this.definitionsDataSource = data[2];
      } else {
        this.definitionsDataSource = [];
      }

      if (!this.measure.MeasureDefinition) {
        this.measure.MeasureDefinition = [];
      } else {
        this.measure.MeasureDefinition?.forEach(definition => {
          const definitionItem = this.definitionsDataSource.find(item => item.Id === definition['IdRefmeasureDefinition']);
          this.assignedDefinitionsIds.push(definitionItem.Id);
        });
      }

    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      console.error(error);
    }, () => {
      this.showLoader = false;
      this.cdr.markForCheck();
    }));
  }

  private prepareMeasureDetailColumns(measureId: string): QueryObjectsModel {
    const measure: Query = new Query();
    this.measureColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Title'),
      this.coreDataService.createQueryColumn('StartDate', 'StartDate'),
      this.coreDataService.createQueryColumn('EndDate', 'EndDate'),
      this.coreDataService.createQueryColumn('ReleaseDate', 'ReleaseDate'),
      this.coreDataService.createQueryColumn('SysDateUpdate', 'SysDateUpdate'),
      this.coreDataService.createQueryColumn('Status', 'Status'),
      this.coreDataService.createQueryColumn('IdRefPersonResponsible', 'Responsible'),
      this.coreDataService.createQueryColumn('Goal', 'Goal'),
      this.coreDataService.createQueryColumn('OrgaTech', 'OrgaTech'),
      this.coreDataService.createQueryColumn('PrevDect', 'PrevDect'),
      this.coreDataService.createQueryColumn('CurrentState', 'CurrentState')
    ];
    measure.ObjectType = ObjectKeys.MEASURE;
    measure.Columns = this.measureColumns;
    measure.OPath = 'Id=' + "'" + measureId + "'";

    const measureDefinitions: Query = new Query();
    measureDefinitions.Name = "MeasureDefinition";
    measureDefinitions.OPath = "measureDefinitions";
    this.measureDefinitionsColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefmeasureDefinition', 'IdRefmeasureDefinition'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ];
    measureDefinitions.Columns = this.measureDefinitionsColumns;

    const measureBenefits: Query = new Query();
    measureBenefits.Name = "MeasureBenefits";
    measureBenefits.OPath = "measureBenefits";
    this.measureBenefitsColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('IdRefmeasureBenefit', 'IdRefmeasureBenefit'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ];
    measureBenefits.Columns = this.measureBenefitsColumns;

    measure.ObjectQueries = [measureDefinitions, measureBenefits];
    const measureDetailColumns = new QueryObjectsModel();
    measureDetailColumns.ObjectQueries = [measure];
    return measureDetailColumns;
  }

  public backToMeasureList(): void {
    if (this.dataService.getClickOrigin()?.source == Source.RiskDetail) {
      this.router.navigate(['riskAssessmentDetail', 'measure', this.dataService.getClickOrigin().id]);
      this.dataService.appService.showMeasureIcon = false;
      this.dataService.appService.showRiskIcon = true;
      this.dataService.appService.selectTreeItem(2);
      this.dataService.setClickOrigin({ id: this.measure.Id, source: Source.MeasureDetail, value: true });
    }
    else {
      this.dataService.appService.showMeasureIcon = false;
      this.router.navigateByUrl('measures');
    }
  }
  public measureTasksDataOutput(measureTasksDataOutput: MeasureTask[]): void {
    this.measure.MeasureTasks = measureTasksDataOutput;
    this.cdr.markForCheck();
  }

  public navigateToMeasure(index: number): void {
    this.dataService.appService.callNotification({ message: 'Not Implemented Yet', type: ToastType.INFO });
  }

  public getLastUpdatedDateTime() {
    const updated = new Date(this.measure.SysDateUpdate);
    return updated.toLocaleDateString() + ' ' + updated.toLocaleTimeString();
  }

  public synchronizeChanges(event) {
    if (event[0] !== 'MeasureBenefits') {
      this.measure[event[0]] = event[1];
    }
  }

  public verifyChanges(event): void {
    if (event.value === '') {
      this.measureTitle.value = event.previousValue;
    }
  }

  public updateMeasure(): void {
    const measurePersistQuery: TargetObjectData = new TargetObjectData();
    measurePersistQuery.ObjectKey = new ObjectKey();
    measurePersistQuery.ObjectKey.ObjectType = ObjectKeys.MEASURE;
    const measureColumns: TargetColumnValue[] = [
      { Name: 'Designation', Value: this.measure.Title },
      { Name: 'Goal', Value: this.measure.Goal },
      { Name: 'CurrentState', Value: this.measure.CurrentState },
      { Name: 'StartDate', Value: this.measure.StartDate },
      { Name: 'EndDate', Value: this.measure.EndDate },
      { Name: 'ReleaseDate', Value: this.measure.ReleaseDate },
      { Name: 'SysDateUpdate', Value: this.measure.SysDateUpdate },
      { Name: 'IdRefPersonResponsible', Value: this.measure.Responsible?.toString() },
      { Name: 'OrgaTech', Value: this.measure.OrgaTech },
      { Name: 'PrevDect', Value: this.measure.PrevDect },
      { Name: 'Status', Value: this.measure.Status }
    ];
    measurePersistQuery.Mode = PersistMode.Update;
    measurePersistQuery.ObjectKey.Id = this.measure.Id;
    measurePersistQuery.TargetColumns = measureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measurePersistQuery;
    this.persistenceService.executePersistObjectQuery(persistObject).subscribe(() => {
      this.updateMeasureDefinitions();
      this.updateMeasureBenefits();
    });
  }

  private updateMeasureDefinitions() {
    this.persistDeleteObjects(this.measure.MeasureDefinition, ObjectKeys.MEASUREDEFINITION).subscribe(() => {
      this.measure.MeasureDefinition = [];

      if (this.assignedDefinitionsIds.length !== 0) {
        this.subscriptions.push(
          this.persistInsert(ObjectKeys.MEASUREDEFINITION, this.assignedDefinitionsIds, 'IdRefmeasureDefinition', this.definitionsDataSource).pipe(switchMap(() =>
            this.dataService.readObjects<MeasureDefinition[]>(ObjectKeys.MEASUREDEFINITION, this.measureDefinitionsColumns)
          )).subscribe((definitions) => {
            this.measure.MeasureDefinition = definitions;
          })
        );
      }
    })
  }

  private persistInsert(key: ObjectKeys, addedItems: string[], columnName: string, dataSource: MeasureBenefit[] | MeasureDefinition[]) {
    const query: PersistObjectsModel = new PersistObjectsModel();
    let insertPersistQuery: TargetObjectData[][] = [[]];
    addedItems?.forEach((id) => {
      const designation = dataSource.find(item => item.Id === id).Designation;
      let insertObject: TargetObjectData = new TargetObjectData();
      insertObject.Mode = PersistMode.Insert;
      insertObject.ObjectKey = new ObjectKey();
      insertObject.ObjectKey.ObjectType = key;
      const insertColumns: TargetColumnValue[] = [
        { Name: 'ParentId', Value: this.measure.Id },
        { Name: columnName, Value: id },
        { Name: 'Designation', Value: designation }
      ];
      insertObject.TargetColumns = insertColumns;
      insertPersistQuery[0].push(insertObject);
    });
    query.Objects = insertPersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }

  private persistDeleteObjects(objectsArray: MeasureBenefit[] | MeasureDefinition[], key: ObjectKeys): Observable<any> {
    const query: PersistObjectsModel = new PersistObjectsModel();
    const measurePersistQuery: TargetObjectData[][] = [[]];
    objectsArray?.forEach((result) => {
      const measureObject: TargetObjectData = new TargetObjectData();
      measureObject.Mode = PersistMode.Delete;
      measureObject.ObjectKey = new ObjectKey();
      measureObject.ObjectKey.ObjectType = key;
      measureObject.ObjectKey.Id = result.Id;
      measurePersistQuery[0].push(measureObject);
    })
    query.Objects = measurePersistQuery;
    return this.persistenceService.executePersistObjectsQuery(query);
  }

  private updateMeasureBenefits() {
    this.persistDeleteObjects(this.measure.MeasureBenefits, ObjectKeys.MEASUREBENEFIT).subscribe(() => {
      this.measure.MeasureBenefits = [];

      if (this.detailAccordion.assignedBenefitsIds.length !== 0) {
        this.subscriptions.push(
          this.persistInsert(ObjectKeys.MEASUREBENEFIT, this.detailAccordion.assignedBenefitsIds, 'IdRefmeasureBenefit', this.detailAccordion.benefitsDataSource).pipe(switchMap(() =>
            this.dataService.readObjects<MeasureBenefit[]>(ObjectKeys.MEASUREBENEFIT, this.measureBenefitsColumns)
          )).subscribe((benefits) => {
            this.measure.MeasureBenefits = benefits;
          })
        );
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
