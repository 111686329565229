import { GeoDsMeta } from "@geods/base";

export class LegalBasisLibrary extends GeoDsMeta {
    Designation: string;
    Description: string;
    Link: string;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}