import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MeasureListComponent } from './core/customer-space/measure/measure-list/measure-list.component';
import { RiskAssesmentComponent } from './core/customer-space/risk-assesment/risk-assesment.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { HomeComponent } from './core/home/home.component';
import { AuthenticationGuard } from '@wissenswerft/core/authentication';
import { LoginComponent } from '@wissenswerft/core/authentication';
import { AppComponent } from './app.component';
import { RiskAssesmentDetailComponent } from './core/customer-space/risk-assesment/risk-assesment-detail/risk-assesment-detail.component';
import { MeasureDetailComponent } from './core/customer-space/measure/measure-detail/measure-detail.component';
import { ScopeComponent } from './core/customer-space/librariesComponents/scope/scope.component';
import { ModulesComponent } from './core/customer-space/modules/modules.component';
import { TagComponent } from './core/customer-space/librariesComponents/tag/tag.component';
import { CountryComponent } from './core/customer-space/librariesComponents/country/country.component';
import { StandardComponent } from './core/customer-space/librariesComponents/standard/standard.component';
import { ProductComponent } from './core/customer-space/librariesComponents/product/product.component';
import { MeasureDefinitionComponent } from './core/customer-space/librariesComponents/measure-definition/measure-definition.component';
import { MeasureBenefitComponent } from './core/customer-space/librariesComponents/measure-benefit/measure-benefit.component';
import { DamageKindComponent } from './core/customer-space/librariesComponents/damage-kind/damage-kind.component';
import { MeasureTypeComponent } from './core/customer-space/librariesComponents/measure-type/measure-type.component';
import { LegalBasisComponent } from './core/customer-space/librariesComponents/legal-basis/legal-basis.component';
import { RiskAssesmentMeasureComponent } from './core/customer-space/librariesComponents/Risk-Assesment-Measure/risk-Assesment-Measure.component';
import { MeasureTaskComponent } from './core/customer-space/librariesComponents/measure-task/measure-task.component';
import { ResponsiblePlanComponent } from './core/customer-space/librariesComponents/responsible-plan/responsible-Plan.component';


const routes: Routes = [
  {
    path: 'app',
    component: AppComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'measures',
    component: MeasureListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure/measure.module')
      .then(module => module.MeasureModule)
  },
  {
    path: 'risks',
    component: RiskAssesmentComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/risk-assesment/risk-assesment.module')
      .then(module => module.RiskAssessmentModule)
  },
  {
    path: 'dashboard',
    component: DashboardComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/dashboard/dashboard.module')
      .then(module => module.DashboardModule)
  },
  {
    path: 'home',
    component: HomeComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/home/home.module')
      .then(module => module.HomeModule)
  },
  {
    path: 'measureDetail/:id',
    component: MeasureDetailComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure/measure-detail/measure-detail.module')
    .then(module => module.MeasureDetailModule)
  },
  {
    path: 'riskAssessmentDetail/:from/:id',
    component: RiskAssesmentDetailComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'riskAssessmentMeasure',
    component: RiskAssesmentMeasureComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'modules',
    component: ModulesComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'scopes',
    component: ScopeComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'tags',
    component: TagComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'countries',
    component: CountryComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'standards',
    component: StandardComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'products',
    component: ProductComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'measureDefinitions',
    component: MeasureDefinitionComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'benefits',
    component: MeasureBenefitComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'damageskind',
    component: DamageKindComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'measureTypes',
    component: MeasureTypeComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'legalbasis',
    component: LegalBasisComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'Responsibleplans',
    component: ResponsiblePlanComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'RiskAssesmentMeasures',
    component: RiskAssesmentMeasureComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'measureTasks',
    component: MeasureTaskComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class AppRoutingModule {

}
