import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoDsCoreDataService, PersistMode, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { LegalBasisLibrary } from '@xmt-models';
import { DxFormComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { SharedModule } from '../../../shared/shared.module';
import { LegalBasisService } from './legal-basis.service';

@Component({
  selector: 'legal-basis',
  templateUrl: './legal-basis.component.html',
  styleUrls: ['./legal-basis.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalBasisComponent implements OnInit, OnDestroy {
  @ViewChild('legalBasisGrid') legalBasisGrid: GridComponent;
  @ViewChild('addLegalBasisPopup') addLegalBasisPopup: DxPopupComponent;
  @ViewChild('confirmLegalBasisPopup') confirmLegalBasisPopup: DxPopupComponent;
  @ViewChild('legalBasisList') legalBasisList: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;
  public showLoader: boolean;
  public legalBasisData: LegalBasisLibrary[] = [];
  public legalBasisColumns: QueryColumn[] = [];
  public subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  public submitButtonText: string;
  public legalBasis = new LegalBasisLibrary(null);
  private isUpdating = false;
  private legalBasisId: string;
  private readonly VISIBLE_LEGALBASIS_COLUMNS = ['Designation', 'Description', 'Link'];

  constructor(
    public dataService: DataService,
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService,
    private cdr: ChangeDetectorRef,
    private legalBasisService: LegalBasisService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;

    this.subscriptions.push(this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.LEGALBASISLIBRARY }).pipe(switchMap(layoutStructure => {
      layoutStructure.Columns.forEach(column => {
        this.columnsHeader.push({
          dataField: column.Name,
          caption: this.dataService.res('Cmt-Legal-Basis-' + column.Name),
          dataType: this.dataService.getDataType(column.DataType),
          visibleIndex: this.VISIBLE_LEGALBASIS_COLUMNS.indexOf(column.Name),
          visible: (this.VISIBLE_LEGALBASIS_COLUMNS.includes(column.Name)) ? true : false,
          showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
        });
        this.legalBasisColumns.push(this.coreDataService.createQueryColumn(column.Name, column.Name, QueryColumnSortOrder.None));
      });
      this.columnsHeader.push({
        type: "buttons",
        caption: '',
        alignment: 'left',
        minWidth: 70,
        dataField: 'edit',
        buttons: [{
          icon: 'edit',
          onClick: (event) => { this.openEditLegalBasis(event) }
        }, {
          icon: "trash",
          onClick: (event) => { this.openDeleteLegalBasis(event) }
        }]
      });
      return this.dataService.readObjects<LegalBasisLibrary[]>(ObjectKeys.LEGALBASISLIBRARY, this.legalBasisColumns).pipe(map(legalBasisLibrary => (legalBasisLibrary)));
    })).subscribe((legalBasisLibrary) => {
      this.legalBasisData = legalBasisLibrary;
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
    }, () => {
      this.cdr.markForCheck();
      this.showLoader = false;
    }));

    this.subscriptions.push(this.dataService.updateGridData$.subscribe(legalBasisLibrary => {
      this.legalBasisData.push(legalBasisLibrary);
    }));

  }

  public onCellPrepared = (e): void => {    
    if (e.column.dataField == 'Link' && e.rowType === 'data') {
      e.cellElement.style.cursor = 'pointer';      
      e.cellElement.style.textDecoration = 'underline';
      e.cellElement.style.color = '#337ab7';
    }
  }

  public openDialog = (): void => {
    this.submitButtonText = 'Create';
    this.addLegalBasisPopup.title = this.dataService.res('Cmt-ResponsiblePlan-Create-Responsible-Plan');
    this.isUpdating = false;
    this.legalBasis = new LegalBasisLibrary(null);
    this.addLegalBasisPopup.instance.show();
  }

  public openEditLegalBasis = (event) => {
    this.legalBasisId = event.row.data.Id;
    this.legalBasis = new LegalBasisLibrary(event.row.data);
    this.submitButtonText = 'Update';
    this.isUpdating = true;
    this.addLegalBasisPopup.title = this.dataService.res('Ubt-MeasureTask-Update-Milestone-Task');
    this.addLegalBasisPopup.instance.show();
  }

  public openDeleteLegalBasis(event): void {
    this.legalBasisId = event.row?.data.Id;
    this.confirmLegalBasisPopup.instance.show();
  }

  public deleteLegalBasis(): void {
    this.subscriptions.push(
      this.dataService.deleteObject(ObjectKeys.LEGALBASISLIBRARY, this.legalBasisId).subscribe((deletedlegalBasisLibrary) => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
        const index = this.legalBasisData.findIndex(item => item.Id === deletedlegalBasisLibrary['Id']);
        this.legalBasisData.splice(index, 1);
        this.cdr.markForCheck();
        this.legalBasisGrid.refreshGrid();
      }, (error) => {
        this.appService.callNotification({ message: error, type: ToastType.ERROR });
      })
    );
    this.confirmLegalBasisPopup.instance.hide();
  }

  public abortLegalBasisDelete(): void {
    this.confirmLegalBasisPopup.instance.hide();
  }

  public persistLegalBasis = (event) => {
    event.preventDefault();
    const mode = this.isUpdating === false ? PersistMode.Insert : PersistMode.Update;
    this.subscriptions.push(this.legalBasisService.persistlegalBasis(mode, this.legalBasis).subscribe((legalBasisLibrary) => {
      if (!this.isUpdating) {
        this.legalBasis.Id = legalBasisLibrary.Id;

        this.dataService.updateGridData(this.legalBasis);
      } else {
        const selectedIndex = this.legalBasisData.findIndex((legalBasis) => {
          return legalBasisLibrary.Id === legalBasis.Id
        });
        const clonedItem = { ...this.legalBasis };
        this.legalBasisData[selectedIndex] = clonedItem;
        this.cdr.markForCheck();
      }
      this.addLegalBasisPopup.instance.hide();
      this.dataService.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
    }));
  }

  public clearWindow = (): void => {
    this.form?.instance.resetValues();
    this.legalBasis = new LegalBasisLibrary(null);
    this.addLegalBasisPopup.instance.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}


