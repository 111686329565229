<div class="content-style-class">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="titleStyle">{{dataService.res('Cmt-App-Measure-Catalog')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-MeasureList-Create-Measure')" icon="add"
                    style="width: fit-content;" (onClick)="openCreationDialog()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <div fxLayout="row" class="responsive-paddings">
                <ww-grid #measureGrid class="grid-group-by-style"  [id]="'measureGrid'" [gridData]='measures'
                    [columnsHeader]='columnsHeader' [hoverStateEnabled]="false" [showRowLines]="true"
                    [enableHeaderFilter]="true" [allowAdding]="false" [allowColumnReordering]="false"
                    [rowAlternationEnabled]="false" [enableStateStorage]="false" [enableExport]="false">
                </ww-grid>

                <dx-popup #createMeasurePopup class="windowTitleBackground" [showTitle]="true" [showCloseButton]="false"
                    [title]="dataService.res('Cmt-MeasureList-Create-Measure')" [width]="'50%'" [height]="'auto'">
                    <div style="align-content: center;">
                        <measure-create #creationForm (closePopup)="closeCreationDialog()"></measure-create>
                    </div>
                </dx-popup>

                <dx-popup #confirmDeletePopup [showTitle]="true" [title]="dataService.res('Cmt-Confirm-Delete')"
                    [position]="'center'" [maxHeight]="195" [maxWidth]="320" [deferRendering]="'false'" [showCloseButton]="false">
                    {{dataService.res('Cmt-Delete-Record-Question')}}<br>
                    <div style="padding: 20px;">
                        <dx-button style="width: 40%;" [text]="dataService.res('Cmt-Yes')" (onClick)="removeMeasure()">
                        </dx-button>
                        <dx-button style="margin-left: 30px; width: 40%;" [text]="dataService.res('Cmt-No')"
                            (onClick)="abortDelete()"></dx-button>
                    </div>
                </dx-popup>
            </div>
        </div>
    </div>
</div>