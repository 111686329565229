import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '@wissenswerft/organizational-structure';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'home-cards',
  templateUrl: './home-cards.component.html',
  styleUrls: ['./home-cards.component.scss']
})
export class HomeCardsComponent implements OnInit {
  @Input() companies: Company[];
  @Input() filterActive = false;
  @Input() clonedCompanies: Company[];
  @Input() comapnyNumber = 0;

  constructor(public dataService: DataService, private router: Router) { }

  ngOnInit(): void {

  }
  public itemClick(id: string, companyName: string): void {
    sessionStorage.setItem('currentCompany', id);
    this.dataService.prepareTitleCompany(companyName);
    this.router.navigateByUrl('dashboard');
  }

  public updateContent(e): void {
    if (!this.filterActive) {
      this.companies = this.clonedCompanies.slice(0, this.comapnyNumber + 100);
      this.comapnyNumber += 100;
    }
    e.component.release();
  }

  public validationFunction(company: Company): boolean {
    if (company.houseNumber || company.street || company.zip || company.city || company.country) {
      return true;
    }
    return false;
  }
}
