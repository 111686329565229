<dx-accordion class="accordion-style" [collapsible]="true" [animationDuration]="300" [visible]="true"
    [multiple]="false" [dataSource]="['Test']">
    <div *dxTemplate="let company of 'title'" class="accordionStyle">
        {{dataService.res('Cmt-Details')}}
    </div>
    <div *dxTemplate="let company of 'item'">
        <div>
            <span style=" font-weight: bold;" class="labelHtmlEditor">
                {{dataService.res('Cmt-MeasureDetail-Objective')}}
            </span>
            <dx-html-editor style="margin-top: 10px; margin-bottom: 10px;" [(value)]='measure.Goal'
                (onValueChanged)="synchronizeChanges($event, 'Goal')">
            </dx-html-editor>
        </div>
        <div>
            <span style=" font-weight: bold;" class="labelHtmlEditor">
                {{dataService.res('Cmt-MeasureDetail-Actual-State')}}
            </span>
            <dx-html-editor style="margin-top: 10px; margin-bottom: 10px;" [(value)]='measure.CurrentState'
                (onValueChanged)="synchronizeChanges($event, 'CurrentState')">
            </dx-html-editor>
        </div>
        <div class="rowMargin">
            <span style=" font-weight: bold;" class="labelHtmlEditor">
                {{dataService.res('Cmt-MeasureDetail-Benefit')}}
            </span>
            <dx-tag-box [stylingMode]="'outlined'" style="margin-top: 10px; margin-bottom: 10px;"
                [dataSource]="benefitsDataSource" [stylingMode]="stylingMode"
                [(value)]="assignedBenefitsIds" [displayExpr]="'Designation'" [valueExpr]="'Id'"
                (onValueChanged)="synchronizeChanges($event, 'MeasureBenefits')">
            </dx-tag-box>
        </div>
    </div>
</dx-accordion>