import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Measure, RiskAssesmentMeasure, RiskIssue } from '@xmt-models';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RiskAssessmentService } from '../../customer-space/risk-assesment/risk-assessment.service';
import { DashBoardMeasure } from '../../models/dashboard.model';
import { DataService } from '../../services/data.service';
import { AmountOfRiskAssesmentMeasureService } from './amount-of-risk-assesment-measure.service';

@Component({
  selector: 'amount-of-risk-assesment-measure',
  templateUrl: './amount-of-risk-assesment-measure.component.html',
  styleUrls: ['./amount-of-risk-assesment-measure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AmountOfRiskAssesmentMeasureComponent implements OnInit, OnChanges {
  @Input() risks: RiskIssue[];
  public dashBoardMeasure: DashBoardMeasure[] = [];
  public measures: Measure[] = [];
  private subscriptions: Subscription[] = [];
  private ram: RiskAssesmentMeasure[] = [];
  public riskAssesmentMeasureColumns: Column[] = [
    {
      dataField: 'title',
      caption: (this.dataService.getLanguage() === 'de') ? 'Maßnahme' : 'Measure',
      dataType: 'string'
    },
    {
      dataField: 'riskReduction',
      caption: (this.dataService.getLanguage() === 'de') ? 'Risikoreduktion' : 'Risk reduction',
      dataType: 'string',
    },
    {
      dataField: 'deadLine',
      caption: (this.dataService.getLanguage() === 'de') ? 'Enddatum' : 'Final date',
      dataType: 'date'
    }
  ];

  constructor(
    public dataService: DataService,
    public amountRAMService: AmountOfRiskAssesmentMeasureService,
    private riskAssessmentService: RiskAssessmentService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.amountRAMService.prepareMeasure().pipe(switchMap(data => {
        this.measures = data;
        return this.amountRAMService.prepareRiskAssesmentMeasure();
      })).subscribe(data => {
        this.ram = data
        this.measures.forEach(element => {
          const dashboardMeasure: DashBoardMeasure = new DashBoardMeasure(element['Id'], element['Designation'], element['EndDate'], [], 0);
          this.amountRAMService.dashBoardMeasure.push(dashboardMeasure);
        });
        this.ram.map((riskAssessmentMeasure => {
          this.amountRAMService.dashBoardMeasure.find(element => {
            if (element.id === riskAssessmentMeasure['measureId']) {
              element.ram.push(riskAssessmentMeasure.Value)
            };
          });
          this.amountRAMService.dashBoardMeasure.map(measure => {
            let total: number = 0;
            measure.ram.map(element => {
              total += element;
            });
            if (measure.ram.length !== 0) {
              measure.riskReduction = ((total) / (measure.ram.length)).toFixed(2) + " %";
            }
          })
        }));
        this.cdr.markForCheck();
      }))
  }

  public ngOnChanges(): void { }

  public calculateCellValue(arg: any) {
    return arg.riskReduction + " %";
  }
  public onCellPrepared(event): void { 
    if (event.rowType == "data" && event.column.name === "riskReduction") {
      event.cellElement.style.textAlign = "right";
      event.column.alignment = "right";
    }
  }
}
