import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RiskAssesmentMeasureType, RiskAssesmentMeasure } from '@xmt-models';
import { DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { RiskAssessmentService, riskMeasureToDisplay } from '../../risk-assessment.service';

@Component({
  selector: 'proposed-measures',
  templateUrl: './proposed-measures.component.html',
  styleUrls: ['./proposed-measures.component.scss']
})
export class ProposedMeasuresComponent implements OnInit {
  @Input() measures: RiskAssesmentMeasure[];
  @ViewChild('proposedMeasurePopup') proposedMeasurePopup: DxPopupComponent;
  private measureRowData: riskMeasureToDisplay;
  public measureSuggestionsColumns: Column[] = [
    {
      dataField: 'Measure', caption: 'Measure'
    },
    {
      type: "buttons",
      caption: '',
      alignment: 'left',
      minWidth: 70,
      buttons: [{
        icon: 'plus',
        onClick: (event) => { this.showProposedMeasurePopup(event) }
      }]
    }
  ];
  private subscriptions: Subscription[] = [];
  constructor(public dataService: DataService,
    private riskAssessmentService: RiskAssessmentService) { }


  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

  public showProposedMeasurePopup(event): void {
    this.measureRowData = event.row.data;
    this.proposedMeasurePopup.instance.show();
  }

  public closeProposedMeasurePopup(): void {
    this.proposedMeasurePopup.instance.hide();
  }

  public moveProposedMeasureToAssigned(): void {
    this.subscriptions.push(this.riskAssessmentService.updateRiskAssessmentMeasureRiskReduction(this.measureRowData.Id, true, RiskAssesmentMeasureType.ASSIGNED).subscribe((updatedRam) => {
      this.measureRowData.Type = RiskAssesmentMeasureType.ASSIGNED;
      this.riskAssessmentService.assignedMeasures.push(this.measureRowData);
      this.riskAssessmentService.proposedMeasures = this.riskAssessmentService.proposedMeasures.filter((measure) => measure.Id != this.measureRowData.Id);
    }, (error) => {
      console.error(error)
    }, () => {
      this.closeProposedMeasurePopup();
    }));
  }

}
