import { Injectable } from '@angular/core';
import { GeoDsCoreDataService, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { Module } from '@xmt-models';
import { Observable } from 'rxjs';
import { DashBoardRisk } from '../models/dashboard.model';
import { DataService, ObjectKeys } from '../services/data.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    public dashboardRisk: DashBoardRisk[] = [];

    constructor(
        private coreDataService: GeoDsCoreDataService,
        public dataService: DataService
    ) { }

    public prepareModule(): Observable<Module[]> {
        const columnsModule = [
            this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
            this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
        ];

        return this.dataService.readObjects<Module[]>(ObjectKeys.MODULE, columnsModule);
    }
}