import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Person } from '@geods/base';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn, QueryObjectsModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { RiskIssue } from '@xmt-models';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { Module } from 'libs/xmt-structure/src/lib/models/module.model';
import { SubModule } from 'libs/xmt-structure/src/lib/models/sub-module.model';
import { AppService } from '../../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { Router } from '@angular/router';
import { RiskAssessmentService } from '../risk-assessment.service';

@Component({
  selector: 'cmt-risk-assessment-create',
  templateUrl: './risk-assessment-create.component.html',
  styleUrls: ['./risk-assessment-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskAssessmentCreateComponent implements OnInit, OnDestroy {
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  public language = sessionStorage.getItem('fixedCulture');
  public riskIssue = new RiskIssue(null);
  private subscriptions: Subscription[] = [];
  public columns: QueryColumn[] = [];
  public responsibles: Person[] = [];
  public modules: Module[] = [];
  public subModules: SubModule[] = [];
  public showLoader: false;
  public moduleId = "";
  public companyId = sessionStorage.getItem('currentCompany');

  public isCreateMoreActive = false;
  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    private router: Router,
    private riskAssessmentService: RiskAssessmentService
  ) { }

  public CreateButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Create'),
    useSubmitBehavior: true
  };

  public createWithDetailsButtonOption = {
    text: this.dataService.res('Cmt-CreateRisk-Save-Detail'),
    onClick: () => this.createRiskWithDetails()
  }

  public CancelButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Cancel'),
    onClick: () => this.onClosePopup()
  };

  public createMoreOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Other'),
    rtlEnabled: true,
    value: this.isCreateMoreActive,
    onValueChanged: (event) => { this.changeCreateMore(event) }
  };


  ngOnInit(): void {
    this.columns.push(
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('FirstName', 'FirstName'),
      this.coreDataService.createQueryColumn('LastName', 'LastName')
    )
    const responsiblesOpath = `ParentId='${this.companyId}'`;
    const modulesQuery = this.getModules();
    this.subscriptions.push(forkJoin([
      this.coreDataService.executeReadObjectsQuery(modulesQuery),
      this.dataService.readObjects<Person[]>(ObjectKeys.PERSON, this.columns, responsiblesOpath)
    ]).subscribe(list => {
      this.modules = list[0];
      this.responsibles = list[1];
      this.cdr.markForCheck();
      this.showLoader = false;

    }, error => {
      console.error(error);
      this.showLoader = false;
    }))
  }
  private returnQuery(): Array<QueryColumn> {
    const mainQueryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ]
    return mainQueryColumns;
  }

  public getModules(): QueryObjectsModel {
    const mainQueryColumns = this.returnQuery();

    const subQueryColumns = this.returnQuery();

    const mainQuery: Query = new Query();
    mainQuery.ObjectType = ObjectKeys.MODULE;
    mainQuery.Columns = mainQueryColumns;
    const subQuery: Query = new Query();
    subQuery.Name = 'SubModules';
    subQuery.OPath = 'SubModules';
    subQuery.Columns = subQueryColumns;
    mainQuery.ObjectQueries = [subQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [mainQuery];
    return queryDocument;
  }

  public modulesSelect(event): void {
    const designation = event.itemData.Designation;
    if (event.itemData.SubModules) {
      this.subModules = event.itemData.SubModules;
      this.cdr.markForCheck();
    } else {
      this.appService.callNotification({
        message: this.dataService.res('Cmt-Create-Risk-Sub-Module-Warning-Mssg') + ' ' + designation + ' ' + this.dataService.res('Cmt-Create-Risk-Sub-Module-Warning-Mssg-Category'),
        type: ToastType.WARNING
      });
      this.subModules = [];
    }
  }

  public createRisk(event): void {
    event.preventDefault();
    this.subscriptions.push(this.persistRisk()
      .subscribe((data) => {
        this.riskIssue.Id = data.Id;
        this.dataService.updateGridData(this.riskIssue);
        this.riskAssessmentService.riskIds.push(data.Id);
      }, () => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Success'),
          type: ToastType.SUCCESS
        });
        if (this.isCreateMoreActive) {
          this.clearForm();
          this.cdr.markForCheck();
        } else {
          this.onClosePopup();
        }
      }))
  }

  private createRiskWithDetails(): void {
    this.subscriptions.push(this.persistRisk()
      .subscribe((data) => {
        this.riskAssessmentService.riskIds.push(data.Id);
        this.router.navigate(['riskAssessmentDetail', 'risks', data.Id]);
      }, (error) => {
        console.error(error);
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
      }))
  }

  public persistRisk(): Observable<any> {
    const RiskPersistQuery: TargetObjectData = new TargetObjectData();
    RiskPersistQuery.ObjectKey = new ObjectKey();
    RiskPersistQuery.ObjectKey.ObjectType = ObjectKeys.RISKISSUE;
    RiskPersistQuery.Mode = PersistMode.Insert;
    const riskColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.companyId },
      { Name: 'Designation', Value: this.riskIssue.Designation },
      { Name: 'IdRefPersonResponsible', Value: this.riskIssue.IdRefPersonResponsible },
      { Name: 'IdRefsubModule', Value: this.riskIssue.IdRefsubModule },
      { Name: 'Question', Value: this.riskIssue.Question },
      { Name: 'Description', Value: this.riskIssue.Description },
      { Name: 'AmountOfDamage', Value: this.riskIssue.AmountOfDamage },
      { Name: 'Probability', Value: this.riskIssue.Probability },
      { Name: 'TypeOfDamage', Value: this.riskIssue.TypeOfDamage }
    ];
    RiskPersistQuery.TargetColumns = riskColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = RiskPersistQuery;
    return this.persistenceService
      .executePersistObjectQuery(persistObject)
  }


  private clearForm(): void {
    this.riskIssue = new RiskIssue(null);
    this.moduleId = "";
  }

  public onClosePopup(): void {
    this.closePopup.emit(true);
    this.clearForm();
  }

  public changeCreateMore(event): void {
    this.isCreateMoreActive = event.value;
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

}
