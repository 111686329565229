<div class="content-style-class">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="titleStyle">{{dataService.res('Cmt-ResponsiblePlan')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-ResponsiblePlan-Create-Plan')" icon="add"
                    style="width: fit-content;" (onClick)="openDialog()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <div fxLayout="row" class="responsive-paddings">
                <ww-grid #responsiblePlanGrid class="grid-group-by-style" [id]="'responsiblePlanGridId'"
                    [gridData]="responsiblePlanData" [columnsHeader]='columnsHeader' [showRowLines]="true"
                    [enableColumnChooser]="false" [allowAdding]="false" [enableStateStorage]="false">
                </ww-grid>
                <dx-popup #addResponsiblePlanPopup class="popup-window-style" title="Kategorie" [width]="'30%'"
                    [height]="'auto'" [showCloseButton]="false" titleTemplate="title">
                    <div *dxTemplate="let data of 'title'">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <p>{{dataService.res('Cmt-ResponsiblePlan')}}</p>
                        </div>
                    </div>
                    <form action="post" (submit)="persistResponsiblePlan($event)">
                        <dx-form #form id="form" [(formData)]="responsiblePlan" [colCount]="1">
                            <dxi-item editorType="dxSelectBox">
                                <dx-select-box #responsibleList class="drop-down-style" fxFill
                                    [label]="dataService.res('Cmt-MeasureDetail-Responsible')"
                                    [(value)]="responsiblePlan.Responsible"
                                    [displayExpr]="dataService.getResponsibleFullName" valueExpr="Id"
                                    [dataSource]="responsibles" [labelMode]="'floating'" [acceptCustomValue]="true"
                                    (keydown)="$event.preventDefault()">
                                </dx-select-box>
                            </dxi-item>
                            <dxi-item editorType="dxSelectBox">
                                <dx-select-box #measureList class="drop-down-style" fxFill valueExpr="Id"
                                    [label]="dataService.res('Cmt-RiskDetail-Measure')"
                                    [(value)]="responsiblePlan.Measure" [displayExpr]="'Designation'"
                                    [dataSource]="measures" [labelMode]="'floating'" [acceptCustomValue]="true"
                                    (keydown)="$event.preventDefault()">
                                </dx-select-box>
                            </dxi-item>
                            <dxi-item>
                                <dx-number-box name="PlannedDays" [labelMode]="'floating'"
                                    [label]="dataService.res('Cmt-ResponsiblePlan-Planned-Days')"
                                    [(value)]="responsiblePlan.PlannedDays">
                                </dx-number-box>
                                <dxi-validation-rule type="required">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item>
                                <dx-number-box name="RemainingDays" [labelMode]="'floating'"
                                    [label]="dataService.res('Cmt-ResponsiblePlan-Remaining-Days')"
                                    [(value)]="responsiblePlan.RemainingDays">
                                </dx-number-box>
                                <dxi-validation-rule type="required">
                                </dxi-validation-rule>
                            </dxi-item>

                            <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                                <dxi-item itemType="button" [cssClass]="'submit-button'"
                                    [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Cmt-Form-'+submitButtonText) }">
                                </dxi-item>
                                <dxi-item itemType="button" [cssClass]="'cancel-button'"
                                    [buttonOptions]="{ text: dataService.res('Cmt-Home-Cancel'), onClick:clearWindow }">
                                </dxi-item>
                            </dxi-item>

                        </dx-form>
                    </form>
                </dx-popup>
                <dx-popup #confirmResponsibleplanPopup [showTitle]="true" [showCloseButton]="false"
                    [title]="dataService.res('Cmt-Confirm-Delete')" [position]="'center'" [maxHeight]="200"
                    [maxWidth]="350" [deferRendering]="'false'">
                    {{dataService.res('Cmt-Delete-Record-Question')}} <br>
                    <div style="padding: 20px;">
                        <dx-button style="width: 40%;" [text]="dataService.res('Cmt-Yes')"
                            (onClick)="deleteResponsibleplan()">
                        </dx-button>
                        <dx-button style="margin-left: 30px; width: 40%;" [text]="dataService.res('Cmt-No')"
                            (onClick)="abortResponsibleplanDelete()">
                        </dx-button>
                    </div>
                </dx-popup>
            </div>
        </div>

    </div>
</div>