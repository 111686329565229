<div>
    <dx-accordion class="accrodion-style" [collapsible]="true" [animationDuration]="300" [visible]="true"
        [multiple]="'false'" [dataSource]="['form']">
        <div *dxTemplate=" let data of 'title'">
            {{dataService.res('Cmt-RiskDetail-Rating')}}
        </div>
        <div *dxTemplate=" let data of 'item'">
            <div fxLayout="row" fxLayoutGap="10px" fxLayout.sm="column" fxLayout.xs="column">
                <div fxLayout="column" fxFlex="50" fxLayoutGap="10px">
                    <div class="label"> {{dataService.res('Cmt-RiskDetail-Damage-Amount')}} :</div>
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [dataSource]="appService.amountOfDamage"
                            [(value)]="risk.AmountOfDamage" [hoverStateEnabled]="false" class="label"
                            [id]="'amountOfDamage'" [width]="'100%'" [placeholder]="'hosh'" [labelMode]="labelMode"
                            [stylingMode]="stylingMode" displayExpr='label' valueExpr='value'>
                        </dx-select-box>
                    </div>
                    <div class="label"> {{dataService.res('Cmt-RiskDetail-Probability-Occurrence')}} :
                    </div>
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [dataSource]="appService.amountOfDamage"
                            [(value)]="risk.Probability" [hoverStateEnabled]="false" class="label" [id]="'occurrence'"
                            [width]="'100%'" [placeholder]="'gering'" displayExpr='label' [labelMode]="labelMode"
                            [stylingMode]="stylingMode" valueExpr='value'>
                        </dx-select-box>
                    </div>
                    <div fxLayout="row">
                        <dx-check-box [width]="'100%'" [text]="dataService.res('Cmt-RiskDetail-Existence-Threatening')"
                            [(value)]="risk.ExistenceThreatening">
                        </dx-check-box>
                    </div>
                </div>
                <div style="align-items: center;" fxLayout="column" fxFlex="50" fxLayoutGap="5px">
                    <dx-circular-gauge [value]="setProbabilityAverage(risk.AmountOfDamage,risk.Probability)">
                        <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="10">
                            <dxo-label [useRangeColors]="true" [customizeText]="customizeText"></dxo-label>
                        </dxo-scale>
                        <dxo-size [height]="150" [width]="300">
                        </dxo-size>
                        <dxo-geometry [startAngle]="180" [endAngle]="0">
                        </dxo-geometry>
                        <dxo-range-container palette="pastel">
                            <dxi-range color="#5CE160" [startValue]="0" [endValue]="18"></dxi-range>
                            <dxi-range color="#FFDD33" [startValue]="18" [endValue]="42"></dxi-range>
                            <dxi-range color="#FFA233" [startValue]="42" [endValue]="71"></dxi-range>
                            <dxi-range color="#FF5733" [startValue]="71" [endValue]="100"></dxi-range>
                        </dxo-range-container>
                    </dx-circular-gauge>
                </div>
            </div>
        </div>
    </dx-accordion>
</div>