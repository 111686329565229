import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { IToast } from '@wissenswerft/ww-library';
import { ResourceManager } from '@wissenswerft/core/resources';
import { QueryObjectsModel } from '@wissenswerft/core/data';
import { RiskAssesmentMeasureType, SubProject } from '@xmt-models';
import { DataDefinitionOptions } from '../models/customer-space.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  @Output() selectItem = new EventEmitter();
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$ = this.notificationSubject.asObservable();
  public navigateSubject: Subject<boolean> = new Subject();
  public navigateSubject$ = this.navigateSubject.asObservable();
  public reportIndex: number | undefined;
  public currentMeasureIndex: number | undefined;
  public measureIds = [];
  public showMeasureIcon = false;
  public showRiskIcon: boolean = false;
  public readonly CONFIG_PROPERTIES = ['Id', 'ParentId', 'SysTimeInsert', 'SysTimeUpdate', 'ObjectType', 'SysUserNameInsert', 'SysDateUpdate', 'SysUserNameUpdate', 'SeqNr'];
  public amountOfDamage: EnumValues[] = [
    { label: 'Low', labelDe: 'Niedrig', value: 1 },
    { label: 'Medium', labelDe: 'Mittel', value: 3 },
    { label: 'High', labelDe: 'Hoch', value: 5 },
    { label: 'Very High', labelDe: 'Sehr hoch', value: 7 }
  ];
  public riskAssesmentMeasureType = [
    { title: 'Suggestion', titleDe: 'Vorschläge', value: RiskAssesmentMeasureType.SUGGESTION },
    { title: 'Assigned', titleDe: 'Zugeordnet', value: RiskAssesmentMeasureType.ASSIGNED },
  ];
  public typeOfDamage = [
    { title: 'Immaterial', titleDe: 'Unwesentlich', value: TypeOfDamage.IMMATERIAL },
    { title: 'Material', titleDe: 'Material', value: TypeOfDamage.MATERIAL },
  ];
  public status = [
    { title: 'Open', titleDe: 'Öffnen Sie', value: Status.open },
    { title: 'Rated', titleDe: 'Bewertet', value: Status.bewertet },
    { title: 'Planned', titleDe: 'Geplant', value: Status.planned },
    { title: 'measuresDefined', titleDe: 'Definierte Maßnahmen', value: Status.measuresDefined }
  ];
  public res = ResourceManager.getResources("cmt-geods");
  public subProjectRequestColumns = new QueryObjectsModel();
  public subProjects: SubProject[] = [];
  public currentSubProjectIndex: number | undefined;
  public currentMeasureProgressIndex: number | undefined;
  public subProjectIds: string[] = [];
  public maturityLevel: DataDefinitionOptions[] = [
    { name: 'HG1', value: 'HG1' },
    { name: 'HG2', value: 'HG2' },
    { name: 'HG3', value: 'HG3' },
    { name: 'HG4', value: 'HG4' },
    { name: 'HG5', value: 'HG5' }
  ];

  public percentageValue= [
    { name: 25, value: "25%"},
    { name: 50, value: "50%" },
    { name: 75, value: "75%" },
    { name: 100, value: "100%" }
  ];

  constructor() { }

  public callNotification(notification: IToast): void {
    this.notificationSubject.next(notification);
  }

  public selectTreeItem = (selectedNode: number) => {
    this.selectItem.emit(selectedNode);
  }

  public shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }
}

export enum TypeOfDamage {
  MATERIAL = 'MATERIAL',
  IMMATERIAL = 'IMMATERIAL'
}

export enum Status {
  open = 'open',
  bewertet = 'assessed',
  planned = 'measurePlanned',
  measuresDefined = 'measuresDefined'
}

export interface EnumValues {
  label: string;
  labelDe: string;
  value: number;
}


