<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{ dataService.res('Cmt-App-Risk-Assessment-Measure') }}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-App-Risk-Assessment-Measure-Create')"
                    icon="add" style="width: fit-content;" (onClick)="openDialog()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <ww-grid #riskAssessmentMeasureGrid class="grid-group-by-style chechboxColumn customeExportStyle"
                (onCellPrepared)="onCellPrepared($event)" [columnsHeader]='columnsHeader'
                [gridData]='riskAssessmentMeasures' [enableStateStorage]="false" [showRowLines]="true"
                [enableColumnChooser]="false" [allowAdding]="false">
            </ww-grid>
            <dx-popup #addriskAssessmentMeasurePopup class="popup-window-style" title="Kategorie" [width]="'30%'"
                [height]="'auto'" [showCloseButton]="false" titleTemplate="title">
                <div *dxTemplate="let data of 'title'">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <p>{{dataService.res('Cmt-App-Risk-Assessment-Measure')}}</p>
                    </div>
                </div>
                <form action="post" (submit)="persistRiskAssessmentMeasure($event)">
                    <dx-form #form id="form" [(formData)]="riskAssessmentMeasure" [colCount]="1">
                        <dxi-item editorType="dxSelectBox">
                            <dx-select-box #riskList class="drop-down-style" fxFill
                                [label]="dataService.res('Cmt-RiskAssesmentMeasure-Risk-Issue')"
                                [(value)]="riskAssessmentMeasure.Risk" [displayExpr]="'Designation'" valueExpr="Id"
                                [dataSource]="risks" [labelMode]="'floating'" [acceptCustomValue]="true"
                                (keydown)="$event.preventDefault()">
                            </dx-select-box>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item>
                            <dx-select-box #typeList class="drop-down-style" fxFill
                                [displayExpr]="language==='de'?'titleDe':'title'" valueExpr='value'
                                [label]="dataService.res('Cmt-RiskAssesmentMeasure-Type')"
                                [(value)]="riskAssessmentMeasure.Type"
                                [dataSource]="appService.riskAssesmentMeasureType" [labelMode]="'floating'"
                                [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                            </dx-select-box>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item>
                            <dx-select-box #typeList class="drop-down-style" fxFill displayExpr="value" valueExpr="name"
                                [label]="dataService.res('Cmt-RiskAssesmentMeasure-Value')"
                                [(value)]="riskAssessmentMeasure.Value" [dataSource]="appService.percentageValue"
                                [labelMode]="'floating'" [acceptCustomValue]="true" (keydown)="$event.preventDefault()">
                            </dx-select-box>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item editorType="dxSelectBox">
                            <dx-select-box #measureList class="drop-down-style" fxFill valueExpr="Id"
                                [label]="dataService.res('Cmt-RiskAssesmentMeasure-Measure')"
                                [(value)]="riskAssessmentMeasure.Measure" [displayExpr]="'Designation'"
                                [dataSource]="measures" [labelMode]="'floating'" [acceptCustomValue]="true"
                                (keydown)="$event.preventDefault()">
                            </dx-select-box>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                            <dxi-item itemType="button" [cssClass]="'submit-button'"
                                [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Cmt-Form-'+submitButtonText) }">
                            </dxi-item>
                            <dxi-item itemType="button" [cssClass]="'cancel-button'"
                                [buttonOptions]="{ text: dataService.res('Cmt-Home-Cancel'), onClick:clearWindow }">
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                </form>
            </dx-popup>
            <dx-popup #deleteRiskAssessmentMeasurePopup [showTitle]="true" [showCloseButton]="false"
                [title]="dataService.res('Cmt-Confirm-Delete')" [position]="'center'" [maxHeight]="200" [maxWidth]="350"
                [deferRendering]="'false'">
                {{dataService.res('Cmt-Delete-Record-Question')}} <br>
                <div style="padding: 20px;">
                    <dx-button style="width: 40%;" [text]="dataService.res('Cmt-Yes')"
                        (onClick)="deleteRiskAssessmentMeasure()">
                    </dx-button>
                    <dx-button style="margin-left: 30px; width: 40%;" [text]="dataService.res('Cmt-No')"
                        (onClick)="abortResponsibleplanDelete()">
                    </dx-button>
                </div>
            </dx-popup>
        </div>
    </div>
</div>
<cmt-loader *ngIf="showLoader"></cmt-loader>