<div class="dx-card">
    <div class="status-container" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
            <p fxFlex="10" class="label">Status:</p>
            <dx-button [text]="'Measures planned'" class="status-btn" fxFlex="30" #statutButton [disabled]="false">
            </dx-button>
            <div class="checkBoxStyle" fxFlex="50">
                <dx-check-box [text]="dataService.res('Cmt-RiskDetail-Voting-Required')"
                    [(value)]="risk.NeedsClarification">
                </dx-check-box>
            </div>
            <dx-button fxFlex="5" stylingMode="text" type="default" [hoverStateEnabled]="false"
                [icon]="'assets/images/navigate_left.svg'" [disabled]="riskAssessmentService.currentIssueIndex === 0"
                (onClick)="moveIssue(false)">
            </dx-button>
            <dx-button fxFlex="5" stylingMode="text" type="default" [hoverStateEnabled]="false"
                [icon]="'assets/images/navigate_right.svg'" (onClick)="moveIssue(true)"
                [disabled]="riskAssessmentService.currentIssueIndex === riskAssessmentService.riskIds.length - 1">
            </dx-button>
        </div>
        <div>
            <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-text-box [width]="'100%'" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [(value)]="risk.Designation" [label]="this.dataService.res('Cmt-CreateRisk-Area')"
                            [placeholder]="'Titel'"></dx-text-box>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-text-area [width]="'100%'" [height]="'100%'" [(value)]="risk.Question"
                            [stylingMode]="'outlined'" [label]="this.dataService.res('Cmt-Question')"
                            [placeholder]="'Frage'"></dx-text-area>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-Module')" [width]="'100%'" [dataSource]="modules"
                            valueExpr='Id' displayExpr="Designation" placeholder="Bitte wählen" [(value)]="moduleId"
                            [showClearButton]="false" (onSelectionChanged)="modulesSelect($event)">
                        </dx-select-box>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [dataSource]="subModules" [label]="dataService.res('Cmt-RiskDetail-Area')" valueExpr="Id"
                            displayExpr="Designation" [width]="'100%'" placeholder="Bitte wählen"
                            [showClearButton]="false" [(value)]="risk.IdRefsubModule"
                            [disabled]="subModules.length === 0">
                        </dx-select-box>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [labelMode]="'floating'"
                            [displayExpr]="dataService.getResponsibleFullName" valueExpr="Id"
                            [dataSource]="responsibles | async" [stylingMode]="'outlined'"
                            [(value)]="risk.IdRefPersonResponsible"
                            [label]="dataService.res('Cmt-RiskDetail-Responsible')" [width]="'100%'">
                        </dx-select-box>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <div fxLayout="column">
                        <p class="label">{{dataService.res('Cmt-RiskDetail-Last-Edited')}} :</p>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px">
                        <p>{{ risk.SysDateUpdate | date: 'dd.MM.yyyy' }} :</p>
                        <p>{{ risk.SysTimeUpdate | date: 'hh.mm' }} </p>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-tag-box *ngIf="damageKinds | async" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [(value)]="riskAssessmentService.riskIssuesDamageKindIds" [dataSource]="damageKinds | async"
                            valueExpr='Id' displayExpr="Designation" [label]="dataService.res('Cmt-RiskDetail-Damage')"
                            [acceptCustomValue]="false" [width]="'100%'"
                            (onSelectionChanged)="selectionChanged($event,'riskIssuesDamageKinds')">
                        </dx-tag-box>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-tag-box *ngIf="standards | async" [(value)]="riskAssessmentService.riskIssuesStandardIds"
                            [dataSource]="standards | async" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-RiskDetail-Standard')" [acceptCustomValue]="true"
                            valueExpr='Id' displayExpr="Designation" [width]="'100%'"
                            (onSelectionChanged)="selectionChanged($event,'riskIssuesStandards')">
                        </dx-tag-box>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-tag-box [(value)]="riskAssessmentService.riskIssuesCountryIds"
                            [dataSource]="countries | async" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-RiskDetail-Land')" [acceptCustomValue]="true" [width]="'100%'"
                            valueExpr='Id' displayExpr="Designation">
                        </dx-tag-box>
                    </div>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <dx-select-box class="drop-down-style" [labelMode]="'floating'" [stylingMode]="'outlined'"
                            [label]="dataService.res('Cmt-RiskDetail-Damage-Type')" [width]="'100%'"
                            [displayExpr]="language==='de'?'titleDe':'title'" valueExpr='value'
                            [dataSource]="appService.typeOfDamage" [(value)]="risk.TypeOfDamage">
                        </dx-select-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>