
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PersistMode } from '@wissenswerft/core/data';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Column } from 'devextreme/ui/data_grid';
import { Module } from 'libs/xmt-structure/src/lib/models/module.model';
import { Subscription } from 'rxjs';
import { PersistModel } from '../../models/general.model';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { SharedModule } from '../../shared/shared.module';
import { ModulesService } from './modules.service';


@Component({
  selector: 'modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModulesComponent implements OnInit, OnDestroy {
  @ViewChild("modulesGrid") modulesGrid: GridComponent;
  public showLoader = true;
  public subscriptions: Subscription[] = [];
  public modules = [];
  public addresses = [];
  public editingMode = EditingMode.Row;
  public columnsHeader: Column[] = [
    {
      caption: sessionStorage.getItem('fixedCulture') === "en" ? "Designation" : "Bezeichnung",
      dataField: 'Designation',
      dataType: 'string',
      visibleIndex: 1,
    }
  ];
  constructor(
    public dataService: DataService,
    private appService: AppService,
    private modulesService: ModulesService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dataService.readObjects<Module[]>(ObjectKeys.MODULE).subscribe((data) => {
      this.modules = data;
      this.showLoader = false;
    }, (error) => {
      this.appService.callNotification({ message: this.dataService.res('Cmt-Success'), type: ToastType.ERROR });
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
      this.cdr.markForCheck();
    });
  }
  public onRowUpdated(event): void {
    this.subscriptions.push(
      this.modulesService.persistModule<PersistModel>(ObjectKeys.MODULE, PersistMode.Update, event.newData.Designation, event.oldData.Id)
        .subscribe((persistedModule) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Success'),
            type: ToastType.SUCCESS
          });
        }, (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
  }
  public onRowInserted(event): void {
    this.subscriptions.push(
      this.modulesService.persistModule<PersistModel>(ObjectKeys.MODULE, PersistMode.Insert, event.data.Designation)
        .subscribe((persistedModule) => {
          this.modules[this.modules.length - 1] = { Id: persistedModule.Id, ...this.modules[this.modules.length - 1] }
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Success'),
            type: ToastType.SUCCESS
          });
        }, (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.ERROR
          });
        })
    );
  }
  public onRowRemoved(event): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject<PersistModel>(ObjectKeys.MODULE, event.data.Id).subscribe((deletedModule) => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Delete'),
            type: ToastType.INFO
          });
        }, error => {
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.ERROR
          });
        }
        ))

  }
  public addRow = (): void => {
    this.modulesGrid.addRow();
    this.modulesGrid.dxDataGrid.instance.deselectAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
