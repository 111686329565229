import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RiskIssue } from '@xmt-models';
import { AppService } from '../../../../services/app.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnInit {
  @Input() risk: RiskIssue;
  constructor(public dataService: DataService, public appService: AppService) { }

  ngOnInit(): void { }

  public setProbabilityAverage(amountOfDamage: number, probability: number): number {
    const gaugeValue = ((amountOfDamage * probability) * 100) / 49;
    return gaugeValue;
  }
}
