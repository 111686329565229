import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn, QueryObjectsModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { RiskAssesmentMeasureType, RiskAssesmentMeasure, RiskIssue } from '@xmt-models';
import { DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClickOrigin, Source } from '../../../../models/home.model';
import { AppService } from '../../../../services/app.service';
import { DataService, ObjectKeys } from '../../../../services/data.service';
import { RiskAssesmentMeasureService } from '../../../librariesComponents/Risk-Assesment-Measure/risk-Assesment-Measure.service';

@Component({
  selector: 'cmt-geods-risk-allocation',
  templateUrl: './risk-allocation.component.html',
  styleUrls: ['./risk-allocation.component.scss']
})
export class RiskAllocationComponent implements OnInit, OnDestroy {
  @ViewChild('confirmDeletePopup') confirmDeletePopup: DxPopupComponent;
  @Input() measureId: string;
  public columnsHeader: Column[] = [];
  public riskColumns: QueryColumn[] = [];
  public allRisks: RiskIssue[] = [];
  public assignedRisksDataSource: RiskIssue[] = [];
  public suggestedRisksDataSource: RiskIssue[] = [];
  private readonly RISKASSESMENTMEASURE = ['ParentId', 'IdRefmeasure', 'Type', 'Value'];
  private readonly VISIBLE_RAM_COLUMNS = ['ParentId', 'Value'];
  private subscriptions: Subscription[] = [];
  public assignedRisks: RiskAssesmentMeasure[] = [];
  riskAssessmentMeasures
  private suggestedRisks: RiskAssesmentMeasure[] = [];
  private selectedRisk: RiskIssue;

  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    private riskAssesmentMeasureService: RiskAssesmentMeasureService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public dataService: DataService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.riskAssesmentMeasureService.prepareRisk().pipe(switchMap((risks: RiskIssue[]) => {
      this.allRisks = risks;
      return this.coreDataService.executeReadObjectsQuery(this.prepareRiskColumns()).pipe(switchMap(ram => {
        if (Array.isArray(ram) && ram.length > 0) {
          this.assignedRisks = ram.filter(item => item.Type === RiskAssesmentMeasureType.ASSIGNED);
          this.suggestedRisks = ram.filter(item => item.Type === RiskAssesmentMeasureType.SUGGESTION);
        }
        return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.RISKASSESMENTMEASURE }).pipe(map(layoutStructure => {
          layoutStructure.Columns.forEach(column => {
            const xpath = column.Name === "ParentId" ? "Risk" : column.Name;
            this.columnsHeader.push({
              dataField: xpath,
              caption: this.dataService.res('Cmt-App-Risk-Assessment-Measure-' + column.Name),
              dataType: this.dataService.getDataType(column.DataType),
              sortIndex: this.RISKASSESMENTMEASURE.indexOf(xpath),
              visible: (this.VISIBLE_RAM_COLUMNS.includes(column.Name)) ? true : false,
              showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
              lookup: this.getLookupColumn(column.Name)
            });
          });
        }));
      }));
    })).subscribe(() => {
      this.assignedRisks.forEach(assigned => {
        this.assignedRisksDataSource.push(this.allRisks.find(item => item.Id === assigned.Risk?.toString()));
      });
      
      this.suggestedRisks.forEach(suggested => {
        this.suggestedRisksDataSource.push(this.allRisks.find(item => item.Id === suggested.Risk?.toString()));
      });
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
    }));
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'ParentId':
        return { valueExpr: 'Id', displayExpr: 'Designation', dataSource: this.assignedRisksDataSource }
      case 'Value':
        return { valueExpr: 'name', displayExpr: 'value', dataSource: this.appService.percentageValue };
    }
  }

  private prepareRiskColumns(): QueryObjectsModel {
    const risk: Query = new Query();
    this.riskColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('ParentId', 'Risk'),
      this.coreDataService.createQueryColumn('IdRefmeasure', 'Measure'),
      this.coreDataService.createQueryColumn('Type', 'Type'),
      this.coreDataService.createQueryColumn('Value', 'Value')
    ];
    risk.ObjectType = ObjectKeys.RISKASSESMENTMEASURE;
    risk.Columns = this.riskColumns;
    risk.OPath = 'IdRefmeasure=' + "'" + this.measureId + "'";

    const measureDetailColumns = new QueryObjectsModel();
    measureDetailColumns.ObjectQueries = [risk];
    return measureDetailColumns;
  }
  
  public onCellPrepared(event) {
    if (event.column.dataType == 'number') {
      event.cellElement.style.textAlignLast = 'center';
    }
  }

  public goToRiskDetails(event) {
    if (event.rowType === 'data' && event.column.dataField === "Risk") {
      const riskSource: ClickOrigin = new ClickOrigin({ id: this.measureId, source: Source.MeasureDetail, value: true });
      this.dataService.setClickOrigin(riskSource);
      this.dataService.appService.showMeasureIcon = false;
      this.dataService.appService.showRiskIcon = true;
      this.dataService.appService.selectTreeItem(2);
      this.router.navigate(['riskAssessmentDetail', 'measureDetail', event.data.Risk]);
    }
  }

  public selectRisk(event) {
    this.selectedRisk = event.selectedItem;
  }

  public addRiskAllocation() {
    if (this.selectedRisk) {
      const riskAssesment = this.suggestedRisks.find(item => item.Risk.toString() === this.selectedRisk.Id);
      
      this.subscriptions.push(this.persistUpdateRAM(riskAssesment, RiskAssesmentMeasureType.ASSIGNED).subscribe(() => {
        this.assignedRisks.push(riskAssesment);
        const item = this.allRisks.find(item => item.Id === riskAssesment.Risk?.toString());
        this.assignedRisksDataSource.push(item);
        
        const index = this.suggestedRisksDataSource.findIndex(item => item.Id === this.selectedRisk.Id);
        this.suggestedRisksDataSource.splice(index, 1);
        this.suggestedRisks.splice(index, 1);
        this.selectedRisk = null;
        this.cdr.markForCheck();
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      }));
    }
  }

  public updateRow(event) {
    this.persistUpdateRAM(event.data).subscribe();
  }

  private persistUpdateRAM(ram, type?: RiskAssesmentMeasureType) {
    const riskAssessmentMeasureQuery: TargetObjectData = new TargetObjectData();
    riskAssessmentMeasureQuery.ObjectKey = new ObjectKey();
    riskAssessmentMeasureQuery.ObjectKey.ObjectType = ObjectKeys.RISKASSESMENTMEASURE;
    riskAssessmentMeasureQuery.Mode = PersistMode.Update;
    riskAssessmentMeasureQuery.ObjectKey.Id = ram.Id;
    let riskAssessmentMeasureColumns: TargetColumnValue[]; 
    if (type) {
      riskAssessmentMeasureColumns = [{ Name: 'Type', Value: type }];
    } else {
      riskAssessmentMeasureColumns = [
        { Name: 'Value', Value: ram.Value },
        { Name: 'ParentId', Value: ram.Risk }
      ];
    }
    riskAssessmentMeasureQuery.TargetColumns = riskAssessmentMeasureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = riskAssessmentMeasureQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

  public persistDeleteRAM(event) {
    const riskId = event.data.Id;
    this.subscriptions.push(
      this.dataService.deleteObject(ObjectKeys.RISKASSESMENTMEASURE, riskId).subscribe(() => {
        this.dataService.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
        const index = this.assignedRisks.findIndex(item => item.Id === riskId);
        this.assignedRisks.find(item => { item.Id === riskId });
        this.assignedRisksDataSource.splice(index, 1);
      }, (error) => {
        event.cancel = true;
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
