export class RiskDistribution {
    probability: string;
    damage: string;
    sum: number;
    constructor(probability, damage, sum) {
        this.probability = probability;
        this.damage = damage;
        this.sum = sum;
    }
}

export enum MatrixViewKeyDe {
    Niedrig = 1,
    Mittel = 3,
    Hoch = 5,
    SehrHoch = 7
}

export enum MatrixViewKeyEn {
    Low = 1,
    Medium = 3,
    High = 5,
    VeryHigh = 7
}

export enum Damage {
    LOW = 'Niedrig',
    MEDIUM = 'Mittel',
    HIGH = 'Hoch',
    VERYHIGH = 'Sehr Hoch'
}
export class DashBoardMeasure {
    id: number;
    title: string;
    deadLine: Date;
    ram: number[];
    riskReduction: string;
    constructor(id, title, deadLine, ram, riskReduction) {
        this.id = id;
        this.title = title;
        this.deadLine = deadLine
        this.ram = ram;
        this.riskReduction = riskReduction;
    }
}

export class DashBoardRisk {
    id: number | string;
    module: string;
    risk: string;
    damage: number[];
    constructor(id, module, damage, risk) {
        this.id = id;
        this.module = module;
        this.damage = damage;
        this.risk = risk;
    }
}

export interface SpiderChartAxis {
    name: Damage | string
    key: string
    valueField: string
    color: string
}


export interface SpiderDataModel {
    arg: string | number;
    Niedrig?: string;
    Mittel?: string;
    Hoch?: string;
    SehrHoch?: string;
    countLow?: number;
    countMedium?: number;
    countHigh?: number;
    countVeryHigh?: number;
}
