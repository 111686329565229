import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CoreAuthenticationModule } from '@wissenswerft/core/authentication';
import {
  BACKEND_SYSTEM,
  CoreConfigService,
  CoreConfigurationModule
} from '@wissenswerft/core/configuration';
import {
  ScreenService,
  SharedDataService
} from '@wissenswerft/organizational-structure';
import { DataService } from '../app/core/services/data.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import {
  DevExtremeModule,
  DxSwitchModule,
  DxTemplateModule
} from 'devextreme-angular';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    WwLibraryModule,
    CoreAuthenticationModule,
    CoreConfigurationModule,
    MatIconModule,
    DxTemplateModule,
    DevExtremeModule,
    DxSwitchModule,
    MatTooltipModule
  ],
  providers: [
    DatePipe,
    ScreenService,
    CoreConfigService,
    SharedDataService,
    DataService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig
        .loadConfiguration(environment.API_URL, BACKEND_SYSTEM.GEODS)
        .then((config) => {
          resolve(config);
        });
    });
  };
}