<div *ngIf="!showLoader" class="content-style-class">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Cmt-Product-Title')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Product-Create')" icon="add"
                    style="width: fit-content;" (onClick)="addRow()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <ww-grid #productGrid [columnsHeader]='columnsHeader' [gridData]='productList' class="grid-group-by-style"
                [enableStateStorage]="false" (onSaved)="persistProduct($event)" [showRowLines]="true"
                [enableColumnChooser]="false" [editingMode]='"row"' [allowAdding]="false">
            </ww-grid>
        </div>
    </div>
</div>

<cmt-loader *ngIf="showLoader"></cmt-loader>