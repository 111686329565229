/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumnSortOrder, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Measure, RiskIssue } from '@xmt-models';
import { Observable } from 'rxjs';
import { DataService, ObjectKeys } from '../../../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class RiskAssesmentMeasureService {
 
  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    private dataService: DataService
  ) { }
  public prepareMeasure(): Observable<Measure[]> {
    const columnsMeasure = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
    ];
    return this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, columnsMeasure);
  }

  public prepareRisk(): Observable<RiskIssue[]> {
    const columnsRiskIssue = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
    ];
    return this.dataService.readObjects<RiskIssue[]>(ObjectKeys.RISKISSUE, columnsRiskIssue);
  }

  public persistResponsiblePlan(mode: PersistMode, riskAssessmentMeasure): Observable<any> {
    const riskAssessmentMeasureQuery: TargetObjectData = new TargetObjectData();
    riskAssessmentMeasureQuery.ObjectKey = new ObjectKey();
    riskAssessmentMeasureQuery.ObjectKey.ObjectType = ObjectKeys.RISKASSESMENTMEASURE;

    const riskAssessmentMeasureColumns: TargetColumnValue[] = [
      { Name: 'Type', Value: riskAssessmentMeasure.Type },
      { Name: 'Value', Value: riskAssessmentMeasure.Value },
      { Name: 'IdRefmeasure', Value: riskAssessmentMeasure.Measure },
      { Name: 'ParentId', Value: riskAssessmentMeasure.Risk }
    ];

    riskAssessmentMeasureQuery.Mode = mode;
    if (mode === PersistMode.Update) riskAssessmentMeasureQuery.ObjectKey.Id = riskAssessmentMeasure.Id;
    riskAssessmentMeasureQuery.TargetColumns = riskAssessmentMeasureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = riskAssessmentMeasureQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

}
