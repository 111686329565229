import { Injectable } from '@angular/core';
import { PrevDect, Status } from '@xmt-models';
import { OrgaTech } from '../../models/measure.model';
import { DataService } from '../../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  public orgaTech = [
    { label: OrgaTech.ORGANIZATIONAL, value: OrgaTech.ORGANIZATIONAL },
    { label: OrgaTech.TECHNICAL, value: OrgaTech.TECHNICAL }
  ];
  public prevDect = [
    {id: PrevDect.DETECTIVE, value: this.dataService.res('Cmt-App-Measure-' + PrevDect.DETECTIVE) },
    {id: PrevDect.PREVENTIVE, value: this.dataService.res('Cmt-App-Measure-' + PrevDect.PREVENTIVE) }
  ];
  public status = [
    { id: Status.Open, value: this.dataService.res('Cmt-Status-' + Status.Open) },
    { id: Status.InProgress, value: this.dataService.res('Cmt-Status-' + Status.InProgress) },
    { id: Status.Done, value: this.dataService.res('Cmt-Status-' + Status.Done) }
  ];
  public degree = [
    { id: 0, value: "0%" },
    { id: 25, value: "25%" },
    { id: 50, value: "50%" },
    { id: 75, value: "75%" },
    { id: 100, value: "100%" }
  ];

  public measureStatus = [
    { name: 'Offen', value: 'Offen' },
    { name: 'In Arbeit', value: 'In Arbeit' },
    { name: 'Erledigt', value: 'Erledigt' }
  ];

  public maturityLevel = [
    { name: 'HG1', value: 'HG1' },
    { name: 'HG2', value: 'HG2' },
    { name: 'HG3', value: 'HG3' },
    { name: 'HG4', value: 'HG4' },
    { name: 'HG5', value: 'HG5' }
  ];

  constructor(private dataService: DataService) { }

}
