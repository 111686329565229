import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { QueryColumn } from '@wissenswerft/core/data';
import { RiskIssue } from '@xmt-models';
import { Column } from 'devextreme/ui/data_grid';
import { DashBoardRisk } from '../../models/dashboard.model';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'amount-of-damage-per-module',
  templateUrl: './amount-of-damage-per-module.component.html',
  styleUrls: ['./amount-of-damage-per-module.component.scss']
})
export class AmountOfDamagePerModuleComponent implements OnInit, OnChanges {
  @Input() risks: RiskIssue[];
  @Input() dashboardRisk: DashBoardRisk[];
  public riskIssueColumns: QueryColumn[] = [];
  public riskColumns: Column[] = [
    {
      caption: this.dataService.res('Cmt-Module'),
      dataField: 'module',
      visibleIndex: 0,
      dataType: 'string'
    },
    {
      caption: this.dataService.res('Cmt-DashBoard-Realistic-Damage-level'),
      dataField: 'risk',
      visibleIndex: 0,
      dataType: 'string'
    }
  ];

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {}

  public ngOnChanges(): void {}

}
