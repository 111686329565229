import { ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Person } from '@geods/base';
import { ConvertHelper, GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumnSortOrder, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { LabelMode, StylingMode, ToastType } from '@wissenswerft/ww-library';
import { Measure, PrevDect, Project, Status, SubProject } from '@xmt-models';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { Observable, Subscription } from 'rxjs';
import { OrgaTech } from '../../../models/measure.model';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MeasureService } from '../measure.service';

@Component({
  selector: 'measure-create',
  templateUrl: './measure-create.component.html',
  styleUrls: ['./measure-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureCreateComponent implements OnInit, OnDestroy {
  @ViewChild('currentStatus') currentStatus: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  public labelMode = LabelMode.Floating;
  public stylingMode = StylingMode.Outlined;
  public measure: Measure = new Measure(null);
  public responsibles: Observable<Person[]>;
  public subProjects: SubProject[] = [];
  public projects: Project[] = [];
  public subProjectId: string;
  public orgaTech = ConvertHelper.ToArray(OrgaTech);
  public prevDect = ConvertHelper.ToArray(PrevDect);
  public status = ConvertHelper.ToArray(Status);

  public createButtonOptions = {
    text: this.dataService.res('Cmt-CreateMesure-Create'),
    useSubmitBehavior: true
  };
  public cancelButtonOption = {
    text: this.dataService.res('Cmt-CreateMesure-Cancel'),
    onClick: () => this.cancel()
  };
  private subscriptions: Subscription[] = [];

  constructor(
    public appService: AppService,
    public dataService: DataService,
    private measureService: MeasureService,
    private coreDataService: GeoDsCoreDataService,
    private cdr: ChangeDetectorRef,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public ngOnInit(): void {
    const projectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
    ];
    const projectOPath = 'ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'";
    this.subscriptions.push(this.dataService.readObjects<Project[]>(ObjectKeys.PROJECT, projectColumns, projectOPath).subscribe(projects => {
      this.projects = projects;
    }));
  }

  public createMeasure(event: Event): void {
    event.preventDefault();
    this.measure.SubProject = new SubProject(null);
    const measurePersistQuery: TargetObjectData = new TargetObjectData();
    measurePersistQuery.ObjectKey = new ObjectKey();
    measurePersistQuery.ObjectKey.ObjectType = ObjectKeys.MEASURE;
    this.measure.SubProject.Id = this.subProjectId;
    const measureColumns: TargetColumnValue[] = [
      { Name: 'ParentId', Value: this.measure.SubProject.Id },
      { Name: 'Designation', Value: this.measure.Title },
      { Name: 'Goal', Value: this.measure.Goal },
      { Name: 'CurrentState', Value: this.measure.CurrentState },
      { Name: 'InitialSituation', Value: this.measure.InitialSituation },
      { Name: 'IdRefPersonAreaManager', Value: this.measure.AreaManager.Id },
      { Name: 'StartDate', Value: this.measure.StartDate.toDateString() },
      { Name: 'EndDate', Value: this.measure.EndDate.toDateString() },
      { Name: 'IdRefPersonResponsible', Value: this.measure.Responsible.Id },
      { Name: 'OrgaTech', Value: this.measure.OrgaTech },
      { Name: 'CurrentGrade', Value: this.measureService.maturityLevel[0].name },
      { Name: 'PrevDect', Value: this.measure.PrevDect },
      { Name: 'Status', Value: Status.Open }
    ];
    measurePersistQuery.Mode = PersistMode.Insert;
    measurePersistQuery.TargetColumns = measureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = measurePersistQuery;
    this.subscriptions.push(this.persistenceService.executePersistObjectQuery(persistObject).subscribe((measure) => {
      this.measure.Id = measure.Id;
      this.dataService.updateGridData(this.measure);
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      this.cancel();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.measure = new Measure(null);
    }));
  }

  public cancel(): void {
    this.closePopup.emit();
    this.measure = new Measure(null);
    this.form.instance.resetValues();
  }

  public loadSubProjects(event): void {
    const projectId = event.value;
    const subProjectColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None)
    ];
    const subProjectOPath = 'ParentId=' + "'" + projectId + "'";
    this.subscriptions.push(this.dataService.readObjects<SubProject[]>(ObjectKeys.SUBPROJECT, subProjectColumns, subProjectOPath).subscribe(subProjects => {
      this.subProjects = subProjects;
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}