import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeoDsCoreDataService, PersistMode, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { MeasureTask } from '@xmt-models';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../../../services/app.service';
import { DataService, ObjectKeys, ChangeMode } from '../../../../services/data.service';
import { MeasureService } from '../../measure.service';
import { MeasureTaskService } from './measure-tasks.service';

@Component({
  selector: 'cmt-geods-measure-tasks',
  templateUrl: './measure-tasks.component.html',
  styleUrls: ['./measure-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureTasksComponent implements OnInit {
  @ViewChild('measureTasksGrid') measureTasksGrid: GridComponent;
  @Output() measureTasksData: EventEmitter<MeasureTask[]> = new EventEmitter();
  public measureTasks: MeasureTask[] = [];
  public columnsHeader: Column[] = [];
  public subscriptions: Subscription[] = [];
  public taskColumns: QueryColumn[] = [];
  public showLoader: boolean;
  private measureId = this.route.snapshot.paramMap.get('id');
  private readonly VISIBLE_RESPONSIBLEPLAN_COLUMNS = ['Subject', 'Efficiency', 'DegreeOfImplementation'];

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private measureService: MeasureService,
    private measureTaskService: MeasureTaskService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.TASK }).pipe(switchMap(layoutStructure => {
      layoutStructure.Columns.forEach(column => {
        this.columnsHeader.push({
          dataField: column.Name,
          caption: this.dataService.res('Cmt-MeasureTask-' + column.Name),
          dataType: this.dataService.getDataType(column.DataType),
          visibleIndex: this.VISIBLE_RESPONSIBLEPLAN_COLUMNS.indexOf(column.Name),
          visible: (this.VISIBLE_RESPONSIBLEPLAN_COLUMNS.includes(column.Name)) ? true : false,
          showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
          lookup: this.getLookupColumn(column.Name)
        });
        this.taskColumns.push(this.coreDataService.createQueryColumn(column.Name, column.Name, QueryColumnSortOrder.None));
      });
      return this.dataService.readObjects<MeasureTask[]>(ObjectKeys.TASK, this.taskColumns).pipe(map(tasks => (tasks)));
    })).subscribe((data) => {
      this.measureTasks = data;
      this.measureTasksData.emit(this.measureTasks);
      this.cdr.markForCheck();
    }, error => {
      console.error(error);
    }, () => {
      this.cdr.markForCheck();
      this.showLoader = false;
    }));

  }

  public addRow(): void {
    this.measureTasksGrid.addRow();
  }

  public onCellPrepared = (event): void => {
    if (event.column.dataType == 'number') {
      event.cellElement.style.textAlignLast = 'center';
    }
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'DegreeOfImplementation':
      case 'Efficiency':
        return { valueExpr: 'id', displayExpr: 'value', dataSource: this.measureService.degree };
    }
  }

  public persistTask(event): void {
    if (event.changes[0].type === ChangeMode.Insert) {
      this.subscriptions.push(
        this.measureTaskService.persistTask<MeasureTask>(ObjectKeys.TASK, PersistMode.Insert, this.measureId,
          event.changes[0].data.Subject,
          event.changes[0].data.Efficiency,
          event.changes[0].data.DegreeOfImplementation,
          event.changes[0].data.Id)
          .subscribe((task) => {
            event.changes[0].data.Id = task.Id;
            this.cdr.markForCheck();
            this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
          }, (error) => {
            console.error(error);
            this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
          })
      );
    } else if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.TASK, event.changes[0].key.Id).subscribe(
        (task) => {
          this.dataService.updateGridData(task);
          this.cdr.markForCheck();
        },
        (error) => {
          console.error(error); this.appService.callNotification({ message: error, type: ToastType.ERROR });
        }, () => { this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS }); }
      ));
    } else {
      this.subscriptions.push(this.measureTaskService.persistTask<MeasureTask>(ObjectKeys.TASK, PersistMode.Update, this.measureId,
        event.changes[0].data.Subject,
        event.changes[0].data.Efficiency,
        event.changes[0].data.DegreeOfImplementation,
        event.changes[0].data.Id
      )
        .subscribe((task) => {
          this.dataService.updateGridData(task);
          this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Success'), type: ToastType.SUCCESS });
        }, (error) => {
          console.error(error);
          this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.map((subscription) => { subscription.unsubscribe(); });
  }
}
