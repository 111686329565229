<div class="content-style-class">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="titleStyle">{{dataService.res('Cmt-LegalBasis')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-LegalBasis-Create-Legal-Basis')" icon="add"
                    style="width: fit-content;" (onClick)="openDialog()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <div fxLayout="row" class="responsive-paddings">
                <ww-grid #legalBasisGrid class="grid-group-by-style" [id]="'LegalBasisGridId'" [gridData]="legalBasisData"
                    [columnsHeader]='columnsHeader' [showRowLines]="true" [enableColumnChooser]="false"
                    [allowAdding]="false" [enableStateStorage]="false" (onCellPrepared)="onCellPrepared($event)">
                </ww-grid>
                <dx-popup #addLegalBasisPopup class="popup-window-style" title="Kategorie" [width]="'30%'"
                    [height]="'auto'" [showCloseButton]="false"titleTemplate="title">
                    <div *dxTemplate="let data of 'title'">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <p>{{dataService.res('Cmt-LegalBasis')}}</p>
                        </div>
                    </div>
                    <form action="post" (submit)="persistLegalBasis($event)">
                        <dx-form #form id="form" [(formData)]="legalBasis" [colCount]="1">
                            <dxi-item editorType="dxSelectBox">
                                <dx-text-box name="Designation" [labelMode]="'floating'"
                                    [label]="dataService.res('Cmt-Designation')" [(value)]="legalBasis.Designation">
                                </dx-text-box>
                            </dxi-item>
                            <dxi-item editorType="dxSelectBox">
                                <dx-text-box name="Description" [labelMode]="'floating'"
                                    [label]="dataService.res('Cmt-Description')" [(value)]="legalBasis.Description">
                                </dx-text-box>
                            </dxi-item>
                            <dxi-item>
                                <dx-text-box name="Link" [labelMode]="'floating'"
                                    [label]="dataService.res('Cmt-LegalBasis-Link')" [(value)]="legalBasis.Link">
                                </dx-text-box>
                                <dxi-validation-rule type="required">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
                                <dxi-item itemType="button" [cssClass]="'submit-button'"
                                    [buttonOptions]="{ useSubmitBehavior: true, text: dataService.res('Cmt-Form-'+submitButtonText) }">
                                </dxi-item>
                                <dxi-item itemType="button" [cssClass]="'cancel-button'"
                                    [buttonOptions]="{ text: dataService.res('Cmt-Home-Cancel'), onClick:clearWindow }">
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </form>
                </dx-popup>

                <dx-popup #confirmLegalBasisPopup [showTitle]="true" [title]="dataService.res('Cmt-Confirm-Delete')"
                    [position]="'center'" [maxHeight]="200" [maxWidth]="350" [deferRendering]="'false'" [showCloseButton]="false">
                    {{dataService.res('Cmt-Delete-Record-Question')}} <br>
                    <div style="padding: 20px;">
                        <dx-button style="width: 40%;" [text]="dataService.res('Cmt-Yes')"
                            (onClick)="deleteLegalBasis()">
                        </dx-button>
                        <dx-button style="margin-left: 30px; width: 40%;" [text]="dataService.res('Cmt-No')"
                            (onClick)="abortLegalBasisDelete()">
                        </dx-button>
                    </div>
                </dx-popup>
            </div>
        </div>
    </div>
</div>