import { Injectable } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Measure, ResponsiblePlan } from '@xmt-models';
import { Observable } from 'rxjs';
import { DataService, ObjectKeys } from '../../../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsiblePlanService {

  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService,
    public dataService: DataService
  ) { }

  public prepareMeasure(): Observable<Measure[]> {
    const columns = [
      this.coreDataService.createQueryColumn('Id', 'Id', QueryColumnSortOrder.None),
      this.coreDataService.createQueryColumn('Designation', 'Designation', QueryColumnSortOrder.None),
    ];
    return this.dataService.readObjects<Measure[]>(ObjectKeys.MEASURE, columns);
  }

  public persistResponsiblePlan(mode: PersistMode, responsiblePlan): Observable<any> {
    const responsiblePlanPersistQuery: TargetObjectData = new TargetObjectData();
    responsiblePlanPersistQuery.ObjectKey = new ObjectKey();
    responsiblePlanPersistQuery.ObjectKey.ObjectType = ObjectKeys.RESPONSIBLEPLAN;

    const responsiblePlanColumns: TargetColumnValue[] = [
      { Name: 'IdRefPersonResponsible', Value: responsiblePlan.Responsible },
      { Name: 'ParentId', Value: responsiblePlan.Measure },
      { Name: 'plannedDays', Value: responsiblePlan.PlannedDays },
      { Name: 'remainingDays', Value: responsiblePlan.RemainingDays }
    ];

    responsiblePlanPersistQuery.Mode = mode;
    if (mode === PersistMode.Update) responsiblePlanPersistQuery.ObjectKey.Id = responsiblePlan.Id;
    responsiblePlanPersistQuery.TargetColumns = responsiblePlanColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = responsiblePlanPersistQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

}