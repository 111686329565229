<div class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
  <div class="content-block">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" [ngClass]="{'title-style-bloc': !displayGrid}">
        <h2 class="title-style" [ngClass]="{'title-style-padding': !displayGrid}">
          {{dataService.res('Cmt-Home-Home')}}</h2>
      </div>
      <div fxLayout="column" *ngIf="displayGrid" class="add-button-style">
        <dx-button fxLayoutAlign="end" [text]="buttonTitle" icon="add" style="width: fit-content"
          (onClick)="openCompanyDialog(false)"></dx-button>
      </div>
    </div>
    <div class="dx-card responsive-paddings" [ngClass]="{'container-card': !displayGrid}">
      <div fxLayout="row wrap" fxLayoutAlign="end" class="action-bloc" style="height: 5%;">
        <div class="switch-container" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start">
          <div class="card-button" [ngClass]="{'icon-container': true, 'icon-container-active': !displayGrid}"
            (click)="changedisplay(false)">
            <mat-icon aria-hidden="false" aria-label="List icon" [ngClass]="{'white': !displayGrid}"
              [matTooltip]="dataService.res('Cmt-Home-Cards')">
              grid_on
            </mat-icon>
          </div>
          <div [ngClass]="{'icon-container': true, 'icon-container-active': displayGrid}" (click)="changedisplay(true)">
            <mat-icon aria-hidden="false" aria-label="Grid icon" [ngClass]="{'white': displayGrid}"
              [matTooltip]="dataService.res('Cmt-Home-Grid')">
              list </mat-icon>
          </div>
          <div class="search-box" [ngClass]="filterActive ? 'serch-box-hover-class' : ''" *ngIf="!displayGrid">
            <input (input)="searchTerm.next($event.target.value)" class="text" type="text"
              placeholder="{{dataService.res('Cmt-Home-Search')}} ... " />
            <button>
              <mat-icon style="color: #443161">search</mat-icon>
            </button>
          </div>
        </div>

      </div>
      <div fxLayout="row" class="responsive-paddings" *ngIf="displayGrid">
        <ww-grid [height]="'90%'" class="grid-group-by-style" #companyGrid [id]="'companyGrid'"
          [gridData]='clonedCompanies' [enableExport]="true" [enableColumnChooser]="true" (onRowRemoved)="false"
          [rowAlternationEnabled]="false" [columnsHeader]='columnsHeader' [allowColumnReordering]="false"
          [allowDeleting]="false" [allowAdding]="false" [pageable]="false" [allowExportSelectedData]="false"
          [enableStateStorage]="false">
        </ww-grid>
      </div>
      <div class="empty-box" *ngIf="emptyCompany" fxLayout="row wrap" fxLayoutAlign="center center">
        {{ dataService.res('Cmt-Home-No-Data')}}

      </div>
      <home-cards [companies]="companies" [clonedCompanies]="clonedCompanies" *ngIf="!displayGrid"
        [filterActive]="filterActive" [comapnyNumber]="comapnyNumber">
      </home-cards>
    </div>
  </div>
</div>
<dx-popup #addCompanyPopup class="popup-window-style" title="Unternehmen" [width]="'50%'" [height]="'auto'"
  [showCloseButton]="false" [showTitle]="true" [title]="dataService.res('Cmt-Home-Company-Title')">
  <dx-scroll-view [showScrollbar]="'onScroll'" class="popup-scroll">
    <form action="post" (submit)="onCreateCompany($event)">
      <dx-form id="form" [(formData)]="company">
        <dxi-item class="floatingLabel">
          <dx-text-box dataField="name" name="name" labelMode="floating"
            [label]="dataService.res('Cmt-Home-Company-Name')+'*'" [(value)]="company.name">
          </dx-text-box>
          <dxi-validation-rule type="required" [message]="dataService.res('Cmt-Home-Company-Name-Error-Msg')">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item class="floatingLabel">
            <dx-text-box dataField="matchCode" name="matchCode" labelMode="floating"
              [label]="dataService.res('Cmt-Home-Company-Match-Code')+'*'" [(value)]="company.matchCode">
            </dx-text-box>
            <dxi-validation-rule type="required" [message]="dataService.res('Cmt-Home-Company-Match-Code-Error-Msg')">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item editorType="dxSelectBox">
            <dx-select-box class="drop-down-style" [(value)]="company.status" displayExpr='Description'
              [dataSource]="this.dataService.cachedStatus" valueExpr='Value'
              [label]="dataService.res('Cmt-Home-Company-Status')+'*'" [labelMode]="'floating'">
            </dx-select-box>
            <dxi-validation-rule type="required" [message]="dataService.res('Cmt-Home-Company-Status-Error-Msg')">
            </dxi-validation-rule>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item editorType="dxSelectBox">
            <dx-select-box class="drop-down-style" [(value)]="company.companyTitle" displayExpr='Description'
              [dataSource]="this.dataService.cachedCompaniesTitles" valueExpr='Value'
              [label]="dataService.res('Cmt-Home-Company-Organizations')+'*'" [labelMode]="'floating'">
            </dx-select-box>
          </dxi-item>
          <dxi-item class="floatingLabel">
            <dx-text-box mode="number" [(value)]="company.customerNumber"
              [label]="dataService.res('Cmt-Home-Company-Customer-Number')" name="customerNumber" labelMode="floating">
            </dx-text-box>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item [cssClass]="returnDxItemCssClass('title-bloc')">
            <p>{{dataService.res('Cmt-Home-Company-Address')}}</p>
          </dxi-item>
          <dxi-item [cssClass]="returnDxItemCssClass('title-bloc')">
            <p>{{dataService.res('Cmt-Home-Company-Contact')}}</p>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group">
          <dxi-item itemType="group" [colCount]="2">
            <dxi-item class="floatingLabel">
              <dx-text-box mode="number" [(value)]="company.houseNumber"
                [label]="dataService.res('Cmt-Home-Company-House-Number')" name="houseNumber" labelMode="floating">
              </dx-text-box>
            </dxi-item>
            <dxi-item class="floatingLabel">
              <dx-text-box #phoneTextBox [(value)]="company.phone" [stylingMode]="'outlined'" [width]="'100%'"
                [label]="dataService.res('Cmt-Home-Company-Phone')" mode="tel" mask="+00 0000-00000-00"
                [labelMode]="'floating'">
              </dx-text-box>
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" [colCount]="2">
            <dxi-item class="floatingLabel">
              <dx-text-box dataField="street" name="street" labelMode="floating"
                [label]="dataService.res('Cmt-Home-Company-Street')" [(value)]="company.street">
              </dx-text-box>
            </dxi-item>
            <dxi-item class="floatingLabel">
              <dx-text-box #faxTextBox [(value)]="company.fax" [stylingMode]="'outlined'" [width]="'100%'"
                [label]="dataService.res('Cmt-Home-Company-Fax')" mode="tel" mask="+00 0000-00000-00"
                [labelMode]="'floating'">
              </dx-text-box>
            </dxi-item>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item class="floatingLabel">
            <dx-text-box dataField="city" name="city" labelMode="floating"
              [label]="dataService.res('Cmt-Home-Company-Town')" [(value)]="company.city">
            </dx-text-box>
          </dxi-item>
          <dxi-item class="floatingLabel">
            <dx-text-box #emailTextBox dataField="eMail" name="eMail" labelMode="floating"
              [label]="dataService.res('Cmt-Home-Company-Email')" [(value)]="company.eMail">
              <dx-validator>
                <dxi-validation-rule type="email" [message]="dataService.res('Cmt-Home-Company-Email-Error-Msg')">
                </dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item class="floatingLabel">
            <dx-text-box dataField="country" name="country" labelMode="floating"
              [label]="dataService.res('Cmt-Home-Company-Land')" [(value)]="company.country">
            </dx-text-box>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item class="floatingLabel">
            <dx-text-box dataField="zip" name="zip" labelMode="floating"
              [label]="dataService.res('Cmt-Home-Company-Zip')" [(value)]="company.zip">
            </dx-text-box>
          </dxi-item>
        </dxi-item>

        <dxi-item [cssClass]="returnDxItemCssClass('div-buttons')" itemType="group">
          <dxi-item [cssClass]="returnDxItemCssClass('btn-create')" itemType="button" *ngIf="buttonAction.length==0"
            [(buttonOptions)]="createButtonOptions">
          </dxi-item>
          <dxi-item [cssClass]="returnDxItemCssClass('btn-create')" itemType="button" *ngIf="buttonAction=='edit'"
            [(buttonOptions)]="editButtonOptions">
          </dxi-item>
          <dxi-item [cssClass]="returnDxItemCssClass('btn-cancel')" itemType="button"
            [buttonOptions]="CancelButtonOptions">
          </dxi-item>
        </dxi-item>
      </dx-form>
    </form>
  </dx-scroll-view>
</dx-popup>
<dx-popup #deleteCompanyPopup [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
  [height]="'auto'" [showCloseButton]="false">
  <div class="delete-popup">
    <div class="delete-popup">{{dataService.res('Cmt-Home-Company-Delete-Msg')}}</div>
    <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
      <dx-button class="btn-create btn-delete" [text]="dataService.res('Cmt-Home-Delete')" (onClick)="deleteCompany()">
      </dx-button>
      <dx-button class="btn-cancel btn-cancel-delete" [text]="dataService.res('Cmt-Home-Cancel')"
        (onClick)="onClosePopup(false)">
      </dx-button>
    </dxi-item>
  </div>
</dx-popup>
<cmt-loader *ngIf="showLoader"></cmt-loader>