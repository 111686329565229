<div fxFill fxLayout="column" fxFlex="100%">

  <div fxLayout="row" fxFlex="10%">
    <p class="comment-title">{{ res('Comments-Library-Title') }}</p>
  </div>
  <div fxLayout="row" fxFlex="75%">
    <dx-scroll-view [showScrollbar]="'onScroll'">
      <dx-list #commentlist class="comments-list" [dataSource]="comments" [allowItemDeleting]="true"
        [noDataText]="res('Comments-Library-NoComments')" [searchEnabled]="true" searchExpr="text"
        [itemDeleteMode]="deleteMode" (onItemClick)="updateComment($event)" (onItemDeleted)="deleteComment($event)">
        <div *dxTemplate="let item of 'item'">
          <div>
            <img style="float: left;" class="image-container" src="{{item.avatar}}" />
            <div style="padding-top: 5px; float: left;">{{ item.author.firstname}} {{
              item.author.lastname}}</div>
          </div>
          <div class="to-right">
            <span *ngIf="calculateDuration(item.created) >= 24">
              {{ item.created | date: 'dd.MM.yyyy' }}
            </span>
            <span *ngIf="calculateDuration(item.created) < 24">
              {{res('Comments-Library-Date') }} {{ calculateDuration(item.created) }}h
            </span>
          </div>
          <dx-scroll-view [showScrollbar]="'onHover'">
            <div class="comment-body">{{ item.text }}</div>
          </dx-scroll-view>
        </div>
      </dx-list>
    </dx-scroll-view>

  </div>
  <div class="comment-class" fxLayout="row" fxFlex="15%" fxLayoutGap="10px">
    <dx-button width="50px" [icon]="'assets/images/comment.svg'" (click)="flag = !flag"
      class="comment-button sideBySide">
    </dx-button>
    <dx-text-area #commentTextArea [ngStyle]="{ visibility: flag ? 'visible' : 'hidden' }"
      class="comment-textarea sideBySide" [(value)]="commentText" placeholder="{{ res('Comments-Library-Comment') }}"
      [autoResizeEnabled]="true" [width]="'70%'" [minHeight]="45" [maxHeight]="75" [maxLength]="250">
    </dx-text-area>
    <dx-button #saveButton class="sideBySide addCommentButton" [ngStyle]="{ visibility: flag ? 'visible' : 'hidden' }"
      stylingMode="text" [icon]="'assets/images/send-message.svg'" (onClick)="addComment()">
    </dx-button>
  </div>

</div>