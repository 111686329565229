import { Component, Input, OnInit } from '@angular/core';
import { RiskIssue } from '@xmt-models';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'cmt-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @Input() risk: RiskIssue;
  constructor(public dataService: DataService) { }

  ngOnInit(): void { }
}
