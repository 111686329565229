import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder } from '@wissenswerft/core/data';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { DataService, ObjectKeys } from '../../services/data.service';
import { RiskIssue } from 'libs/xmt-structure/src/lib/models/risk-issue.model';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { Router } from '@angular/router';
import { DxPopupComponent } from 'devextreme-angular';
import { ToastType } from '@wissenswerft/ww-library';
import { PersistModel } from '../../models/general.model';
import { RiskAssessmentService } from './risk-assessment.service';
@Component({
  selector: 'cmt-risk-assesment',
  templateUrl: './risk-assesment.component.html',
  styleUrls: ['./risk-assesment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskAssesmentComponent implements OnInit, OnDestroy {
  @ViewChild('riskPopup') riskPopup: DxPopupComponent;
  @ViewChild('deleteRiskPopup') deleteRiskPopup: DxPopupComponent;
  public companyId = sessionStorage.getItem('currentCompany');
  public showLoader = true;
  public subscriptions: Subscription[] = [];
  public risks: Array<RiskIssue> = [];
  public riskIssueColumns: QueryColumn[] = [];
  public selectedRiskId = "";
  private readonly VISIBLE_RISK_COLUMNS = ['Question', 'Designation', 'AmountOfDamage', 'Probability', 'IdRefPersonResponsible', 'IdRefsubModule', 'TypeOfDamage', 'Status'];
  public columnsHeader: Column[] = [];
  public readonly actionNeededIcon = '<svg id="Ebene_1" width="30px" " data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110"><defs><style>.needed{fill:#A7171A;}</style></defs><polygon class="needed" points="44 67.42 27.55 50.97 20.48 58.04 44 81.56 86.52 39.04 79.45 31.97 44 67.42"/><path class="needed" d="M55,0a55,55,0,1,0,55,55A55,55,0,0,0,55,0Zm0,100a45,45,0,1,1,45-45A45.05,45.05,0,0,1,55,100Z"/></svg>';
  public readonly actionUnNeededIcon = '<svg id="Ebene_1" width="30px"  data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110"><defs><style>.unNeeded{fill:#D3D3D3;}</style></defs><path class="unNeeded" d="M55,0a55,55,0,1,0,55,55A55,55,0,0,0,55,0ZM44,81.57,20.48,58,27.55,51,44,67.42,79.45,32,86.52,39Z"/></svg>';
  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    private router: Router,
    private coreDataService: GeoDsCoreDataService,
    private appService: AppService,
    private riskAssessmentService: RiskAssessmentService
  ) { }

  ngOnInit(): void {
    this.riskAssessmentService.clear();

    this.subscriptions.push(this.dataService.prepareStaffMembers().pipe(switchMap(staffMembers => {
      this.dataService.cachedResponsiblesResponse = staffMembers;
      return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.RISKISSUE })
        .pipe(switchMap(layoutStructure => {
          this.columnsHeader.push({
            dataField: 'module',
            caption: this.dataService.res('Cmt-App-Module'),
            dataType: 'string',
            groupIndex: 0,
          },
            {
              dataField: 'RiskAverage',
              caption: '',
              visible: true,
              dataType: 'string',
              width: 50,
              allowFiltering: false
            });
          this.riskIssueColumns.push(this.coreDataService.createQueryColumn('subModule.Module.Designation', 'module', QueryColumnSortOrder.None),
            this.coreDataService.createQueryColumn('(AmountOfDamage * Probability)', 'RiskAverage', QueryColumnSortOrder.None));
          layoutStructure.Columns.forEach(column => {
            this.columnsHeader.push({
              dataField: column.Name,
              caption: (column.Name === 'IdRefsubModule') ? this.dataService.res('Cmt-IdRefsubModule')
                : this.dataService.res('Cmt-' + column.DisplayText),
              dataType: this.dataService.getDataType(column.DataType),
              alignment: (column.Name === 'ActionNeeded') ? 'center' : 'left',
              visibleIndex: this.VISIBLE_RISK_COLUMNS.indexOf(column.Name),
              visible: (this.VISIBLE_RISK_COLUMNS.includes(column.Name)) ? true : false,
              showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name)) ? true : false,
              groupIndex: (column.Name === 'IdRefsubModule') ? 1 : undefined,
              width: this.getColumnWidth(column.Name),
              lookup: (this.getLookupColumn(column.Name))
            });
            (column.Name === 'IdRefsubModule') ? this.riskIssueColumns.push(this.coreDataService.createQueryColumn('subModule.Designation', column.Name, QueryColumnSortOrder.None)) :
              this.riskIssueColumns.push(this.coreDataService.createQueryColumn(column.Name, column.Name, QueryColumnSortOrder.None));
          });
          this.columnsHeader.push({
            type: "buttons",
            caption: '',
            alignment: 'left',
            minWidth: 70,
            dataField: 'edit',
            buttons: [{
              icon: 'edit',
              onClick: (event) => { this.navigateToEditRisk(event); }
            }, {
              icon: "trash",
              onClick: (event) => { this.deletePopUp(event); }
            }]
          });
          const opath = `ParentId='${this.companyId}'`;
          return this.dataService.readObjects<RiskIssue[]>(ObjectKeys.RISKISSUE, this.riskIssueColumns, opath)
            .pipe(map(risks => (risks)));
        }))
    })).subscribe((risks) => {
      if (Array.isArray(risks) && risks.length > 0) {
        this.risks = risks;
        //sort risksIds in same order as they are shown in risk grid , grouped by module and submodule
        this.riskAssessmentService.riskIds =
          this.risks.sort((riskA, riskB) => {
            if (riskA['module'] === riskB['module']) {
              return riskA.IdRefsubModule < riskB.IdRefsubModule ? -1 : 1
            } else {
              return riskA['module'] < riskB['module'] ? -1 : 1
            }
          }).map((risk) => risk.Id)

        this.showLoader = false;
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
      this.cdr.markForCheck();
    }));
    this.dataService.updateGridData$.subscribe(riskIssue => {
      this.risks.push(riskIssue);
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

  public onClosePopup(): void {
    this.riskPopup.instance.hide();

  }
  public navigateToEditRisk(event): void {
    this.router.navigate(['riskAssessmentDetail', 'risks', event.row.data.Id]);
  }

  public navigateToCreateRisk(): void {
    if (this.companyId !== null) {
      this.riskPopup.instance.show();
    } else {
      this.appService.callNotification({
        message: this.dataService.res('Cmt-Create-Risk-Info-Mssg'),
        type: ToastType.INFO
      });
    }
  }
  public cellPrepared(event): void {
    if ((event.column.dataField === "Question" || event.column.dataField === "Designation") && event.rowType === "data") {
      event.cellElement.style.height = 100;
      event.cellElement.style.whiteSpace = "normal";
      event.cellElement.style.overflowWrap = 'break-word';
      if (event.column.dataField === "Question") {
        event.cellElement.style.color = '#337ab7';
        event.cellElement.style.fontWeight = '700';
      }
    } else if (event.column.dataField === "ActionNeeded" && event.rowType === "data") {
      if (event.value === true) {
        event.cellElement.innerHTML = this.actionNeededIcon;
      } else {
        event.cellElement.innerHTML = this.actionUnNeededIcon;
      }
    }
  }

  public deletePopUp(event): void {
    this.selectedRiskId = event.row.data.Id;
    this.deleteRiskPopup.instance.show();
  }
  public deleteRisk(): void {
    this.subscriptions.push(this.dataService.deleteObject<PersistModel>(ObjectKeys.RISKISSUE, this.selectedRiskId).subscribe((deletedRisk) => {
      if (deletedRisk?.Id) {
        this.risks = this.risks.filter(
          (risk) => risk.Id != deletedRisk?.Id
        );
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Success'),
          type: ToastType.SUCCESS
        });
      }
    },
      (error) => {
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Error'),
          type: ToastType.ERROR
        });
      }, () => {
        this.onCloseDeletePopup();
      }))
  }
  public onCloseDeletePopup(): void {
    this.deleteRiskPopup.instance.hide();
  }

  private getColumnWidth(key: string): string {
    if (key === 'Question') {
      return '40%';
    } else if (key === 'Designation') {
      return '20%';
    } else {
      return 'auto'
    }
  }

  private getLookupColumn(key: string) {
    if (key === 'IdRefPersonResponsible') {
      return {
        valueExpr: 'Id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.dataService.cachedResponsiblesResponse
      };
    } else if (key === "Status") {
      return { valueExpr: 'value', displayExpr: (sessionStorage.getItem('fixedCulture') === 'en') ? 'title' : 'titleDe', dataSource: this.appService.status };
    } else if (key === "AmountOfDamage" || key === "Probability") {
      return { valueExpr: 'value', displayExpr: (sessionStorage.getItem('fixedCulture') === 'en') ? 'label' : 'labelDe', dataSource: this.appService.amountOfDamage };
    }
    else if (key === "TypeOfDamage") {
      return { valueExpr: 'value', displayExpr: (sessionStorage.getItem('fixedCulture') === 'en') ? 'title' : 'titleDe', dataSource: this.appService.typeOfDamage };
    }
  }

  public onGridCellPrepared(event) {
    if (event.column?.dataField === 'RiskAverage') {
      const value: number = event?.value || 0;
      event.cellElement.innerHTML = '';
      if ([1, 3, 5, 7].includes(value)) {
        return event.cellElement.bgColor = 'lime'
      } else if ([9, 15, 21].includes(value)) {
        return event.cellElement.bgColor = 'yellow';
      } else if ([25, 35].includes(value)) {
        return event.cellElement.bgColor = 'orange';
      } else if (value === 49) {
        return event.cellElement.bgColor = 'red';
      } else {
        return;
      }
    }
  }

}
