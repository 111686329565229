/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IdeTimerService, UserService, AccessibleScopes } from '@wissenswerft/core/authentication';
import { DxScrollViewComponent, DxTreeViewComponent } from 'devextreme-angular';
import { ToastComponent } from '@wissenswerft/ww-library';
import { loadMessages, locale } from 'devextreme/localization';
import { DataService, DataService as Resource, ObjectKeys } from '../app/core/services/data.service';
import { forkJoin, Subscription } from 'rxjs';
import * as events from 'devextreme/events';
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import { NavigationMenu, ScreenService } from '@wissenswerft/organizational-structure';
import { AppService } from './core/services/app.service';
import { GeoDsLoginInfo } from 'libs/core/authentication/src/lib/models/profile-info.model';
import { GeoDsCoreDataService } from '@wissenswerft/core/data';
import dxDrawer from 'devextreme/ui/drawer';
import { ResourceManagerService } from 'libs/core/resources/src/lib/resource-manager.service';
import { RiskAssessmentService } from './core/customer-space/risk-assesment/risk-assessment.service';
import { RiskIssue } from '@xmt-models';

export enum NavigationSvg {
  HOME = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;fill-rule:evenodd;}</style></defs><path class="cls-1" d="M98.72,71,93,65.31V96H66V75H54V96H27V65.31L21.28,71,17,66.76,60,24l43,42.77ZM33,59.34V90H48V69H72V90H87V59.34L60,32.48Z"/></svg>',
  DASHBOARD = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M57,20H20V63H57ZM51,57H26V26H51Z"/><path class="cls-1" d="M63,20V51h37V20ZM94,45H69V26H94Z"/><path class="cls-1" d="M20,69v31H57V69ZM51,94H26V75H51Z"/><path class="cls-1" d="M63,57v43h37V57ZM94,94H69V63H94Z"/></svg>',
  RISK = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M60,20,20,100h80Zm0,13.42L90.29,94H29.71Z"/><rect class="cls-1" x="57" y="52.02" width="6" height="24"/><rect class="cls-1" x="57" y="82.03" width="6" height="6"/></svg>',
  MEASURE = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M15.76,57.84l46.4,46.4L85.27,81.13l-46.4-46.4Zm46.4,37.92L24.24,57.84,38.87,43.21l4.19,4.19-4.2,4.2,4.24,4.25,4.2-4.21,4.19,4.19L47.29,60l4.24,4.24,4.21-4.2,4.18,4.18-4.2,4.21L60,72.71l4.21-4.2,4.19,4.19-4.21,4.2,4.25,4.24,4.2-4.2,4.19,4.19Z"/><polygon class="cls-1" points="97.88 60.03 93.68 64.24 55.76 26.32 59.97 22.12 55.72 17.88 43.08 30.53 47.32 34.77 51.52 30.57 89.44 68.48 85.23 72.68 89.47 76.92 102.12 64.28 97.88 60.03"/></svg>',
  LIBRARY = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M96.29,64.36a3,3,0,0,0-.54-5.17s-1.31-1-1.31-4.41c0-3.05,1.29-4.32,1.85-4.72h0a3,3,0,0,0,1.37-2.86,2.73,2.73,0,0,0-1.82-2.29c-.29-.22-1.4-1.29-1.4-4.43s1.29-4.32,1.85-4.72h0a3,3,0,0,0-.64-5.22L62.47,17.22a3,3,0,0,0-2.87.35L26.65,41.34h0c-1.3.94-4.34,3.76-4.34,9.58a12.5,12.5,0,0,0,2,7.25,12.52,12.52,0,0,0-2,7,12.56,12.56,0,0,0,2,7.26,12.46,12.46,0,0,0-2,7c0,7.51,4.38,9.67,4.88,9.9l.1,0,33.18,13.32a2.94,2.94,0,0,0,1.12.22,3,3,0,0,0,1.76-.57l33-23.77a3,3,0,0,0-.54-5.17s-1.31-1-1.31-4.41C94.44,66,95.73,64.76,96.29,64.36Zm-67.11-17L56.76,58.39a13,13,0,0,0-1.27,5.86c0,.52,0,1,.06,1.49L29.72,55.37c-.27-.21-1.42-1.26-1.42-4.45A6.74,6.74,0,0,1,29.18,47.31Zm.32,8,.14.06-.12-.05Zm-.33,6.34L56.76,72.69a13.06,13.06,0,0,0-1.27,5.86c0,.52,0,1,.06,1.49L29.72,69.67c-.27-.21-1.42-1.26-1.42-4.45A6.81,6.81,0,0,1,29.17,61.62Zm.35,8h0l.12,0ZM90,61.48,62,81.69a8,8,0,0,1-.52-3.14c0-3,1.29-4.32,1.85-4.72L88.46,55.71A12.59,12.59,0,0,0,90,61.48Zm0-14.3L62,67.39a8.09,8.09,0,0,1-.52-3.14c0-3.06,1.29-4.32,1.85-4.72L88.46,41.4A12.6,12.6,0,0,0,90,47.18ZM61.77,23.4,88.36,34.08,61.17,53.7,34.57,43ZM28.3,79.52a6.81,6.81,0,0,1,.87-3.6L56.76,87a13.06,13.06,0,0,0-1.27,5.86c0,.53,0,1,.06,1.5L29.61,83.93C29.51,83.85,28.3,82.76,28.3,79.52ZM62,96a8,8,0,0,1-.52-3.14c0-3,1.29-4.32,1.85-4.72L88.46,70A12.55,12.55,0,0,0,90,75.78Z"/></svg>',
  RISKAMEASURE = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><rect class="cls-1" x="57" y="76.81" width="6" height="6"/><rect class="cls-1" x="57" y="52.81" width="6" height="18"/><path class="cls-1" d="M60,22.28A43,43,0,0,0,31.81,97.72L46.72,82.81a22,22,0,1,1,26.56,0L88.19,97.72A43,43,0,0,0,60,22.28ZM38.21,82.84l-6.39,6.38a36.79,36.79,0,0,1-8.69-20.94h9A27.91,27.91,0,0,0,38.21,82.84ZM32.16,62.28h-9a36.76,36.76,0,0,1,8.69-20.93l6.39,6.38A27.91,27.91,0,0,0,32.16,62.28ZM57,37.45a27.86,27.86,0,0,0-14.55,6L36.06,37.1A36.85,36.85,0,0,1,57,28.42Zm6,0v-9A36.85,36.85,0,0,1,83.94,37.1l-6.39,6.39A27.86,27.86,0,0,0,63,37.45ZM81.79,47.73l6.39-6.38a36.76,36.76,0,0,1,8.69,20.93h-9A27.91,27.91,0,0,0,81.79,47.73Zm6.39,41.49-6.39-6.38a27.91,27.91,0,0,0,6-14.56h9A36.79,36.79,0,0,1,88.18,89.22Z"/></svg>',
  MEASURETYPE = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M70,20,40,50H52v4.49l-2-2-30,30H32V100H88V50h12ZM62,76.49V94H38V76.49H34.49L50,61,65.52,76.49ZM82,44V94H68V82.49H80l-22-22V44H54.48L70,28.49,85.51,44Z"/></svg>',
  MODULE = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M101,56.24V30.84L79,18.14l-19,11-19-11L19,30.84v25.4l19,11V89.14l22,12.72L82,89.14V67.23ZM38,60.31,25,52.78v-15l13,7.5Zm-10-27.75,13-7.49,13,7.51-13,7.5Zm29,5.22v15l-13,7.5v-15Zm0,55.43L44,85.68v-15l13,7.51Zm-10-27.74L60,58l13,7.48L60,73ZM63,37.78l13,7.49v15l-13-7.5Zm0,55.43v-15l13-7.51v15Zm3-60.63,13-7.51,13,7.49L79,40.08ZM82,45.27l13-7.5v15L82,60.31Z"/></svg>',
  SCOPE = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M60,20a40,40,0,1,0,40,40A40,40,0,0,0,60,20Zm0,74A34,34,0,1,1,94,60,34,34,0,0,1,60,94Z"/><polygon class="cls-1" points="63 51 57 51 57 57 51 57 51 63 57 63 57 69 63 69 63 63 69 63 69 57 63 57 63 51"/></svg>',
  TAG = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M88,20V14H82v6H38V14H32v6H20v80h80V20ZM32,26v6h6V26H82v6h6V26h6V46H26V26ZM26,94V52H94V94Z"/><rect class="cls-1" x="32" y="70.01" width="6" height="6"/><rect class="cls-1" x="32" y="82.01" width="6" height="6"/><rect class="cls-1" x="44" y="58" width="6" height="6"/><rect class="cls-1" x="44" y="70.01" width="6" height="6"/><rect class="cls-1" x="44" y="82.01" width="6" height="6"/><rect class="cls-1" x="56" y="58" width="6" height="6"/><rect class="cls-1" x="56" y="70.01" width="6" height="6"/><rect class="cls-1" x="56" y="82.01" width="6" height="6"/><polygon class="cls-1" points="83.89 57.88 78.01 63.76 72.13 57.88 67.88 62.13 73.76 68 67.88 73.89 72.13 78.13 78.01 72.25 83.89 78.13 88.13 73.89 82.25 68 88.13 62.13 83.89 57.88"/></svg>',
  LAND = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M69,40a9,9,0,1,0-9,9A9,9,0,0,0,69,40ZM57,40a3,3,0,1,1,3,3A3,3,0,0,1,57,40Z"/><path class="cls-1" d="M90,55H77a44.62,44.62,0,0,0,3-15,20,20,0,0,0-40,0,44.62,44.62,0,0,0,3,15H30L20,100h80ZM60,26A14,14,0,0,1,74,40c0,7.07-3.14,16.16-9.08,26.3C63.22,69.21,61.5,71.83,60,74c-1.5-2.15-3.22-4.77-4.92-7.68C49.14,56.16,46,47.07,46,40A14,14,0,0,1,60,26ZM34.81,61h10.7A119,119,0,0,0,60,84,119,119,0,0,0,74.49,61h10.7l7.33,33h-65Z"/></svg>',
  PRODUCT = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M60,20,25.36,40V80L60,100,94.64,80V40ZM85.64,41.73,60,56.53,34.36,41.73,60,26.93Zm-54.28,5.2L57,61.73V91.34L31.36,76.54ZM63,91.34V61.73l25.64-14.8V76.54Z"/></svg>',
  MEASUREDEFINITION = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M60,42A13,13,0,1,0,47,29,13,13,0,0,0,60,42Zm0-20a7,7,0,1,1-7,7A7,7,0,0,1,60,22Z"/><path class="cls-1" d="M44.41,69a13,13,0,1,0-4.76,17.76h0A13,13,0,0,0,44.41,69Zm-4.5,8.32a7,7,0,1,1-6.77-8.82,7.05,7.05,0,0,1,1.82.24,7,7,0,0,1,4.95,8.58Z"/><path class="cls-1" d="M99.41,72.14A13,13,0,1,0,98.11,82,12.9,12.9,0,0,0,99.41,72.14ZM92.91,79a7,7,0,0,1-12.82-1.68,7,7,0,0,1,5-8.58,7,7,0,0,1,5.31.7h0A7,7,0,0,1,92.91,79Z"/><path class="cls-1" d="M65.85,87.38a27.43,27.43,0,0,1-11.69,0L56,82.07l-10.82,5.3,5.31,10.82,1.74-5.11A33.5,33.5,0,0,0,60,94a34,34,0,0,0,7.8-.91l1.74,5.1,5.31-10.82L64,82.07Z"/><path class="cls-1" d="M38.81,52.65l-5.51-1a28.15,28.15,0,0,1,5.77-10.16l3.71,4.17.71-12-12-.71L35.07,37A34,34,0,0,0,27.38,50.5l-5.31-1,6.8,9.94Z"/><path class="cls-1" d="M80.93,41.45a28,28,0,0,1,5.76,10.16l-5.5,1,9.94,6.79,6.8-9.94-5.32,1A33.86,33.86,0,0,0,84.93,37l3.61-4.06-12,.71.71,12Z"/></svg>',
  BENEFITS = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M94,26V65.51L90.51,62l-4.64-4.63-4.2,5c-1.84,2.19-3.72,4.28-5.61,6.21C61.39,83.59,44.2,92.2,26,93.75V59.1c11.81-1.84,23.4-9,33.16-20.7l3.52-4.21L58.8,30.31,54.49,26H94m6-6H40L54.55,34.55c-9.4,11.25-21.39,19-34.55,19V100c22.06,0,42.92-9.4,60.34-27.18q3.06-3.12,5.93-6.55L100,80V20Z"/></svg>',
  DAMAGEKINND = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M60,100A40,40,0,0,1,32.39,31.07l2.12-2L55.09,49.63l-12,7.82L61.78,70.1l-3.69-9.67,12.36-8L51.37,20.65l4.72-.46C57.41,20.06,58.72,20,60,20a40,40,0,0,1,0,80ZM34.5,37.52A33.66,33.66,0,0,0,26,60,34,34,0,1,0,61.59,26l17,28.33L65.43,62.84l8.75,22.91L32.31,57.36l13.31-8.71Z"/></svg>',
  RESPONSIBLEPLAN = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M60,103c-14.15,0-19.71-10.37-24.19-18.71-2.5-4.66-4.87-9.07-8.29-11.43C22.28,69.25,21,68.32,21,68.32a8.2,8.2,0,0,1-1.56-12A8.4,8.4,0,0,1,30.52,55l7.38,5.12c1.41,1,2.89,1.25,3.85.7s1.59-2.29,1.42-4.6l-2-27.09a8.2,8.2,0,0,1,8.37-8.8,8.38,8.38,0,0,1,8,7.82l.83,11.24,3.8-16.07a8.19,8.19,0,0,1,10.62-5.87,8.35,8.35,0,0,1,5.38,9.33,8,8,0,0,1,1.61-1.1A8.18,8.18,0,0,1,91.14,29.8a8.12,8.12,0,0,1,0,6.27L87,46.13l1.07-1.21a8.38,8.38,0,0,1,11.09-1.38h0a8.21,8.21,0,0,1,1.34,12.07s-5.93,7-11.84,13.39C84,74,81.77,79.74,79.57,85.32,76.15,94,72.61,103,60,103ZM25.63,59.47a2.12,2.12,0,0,0-1.63.7,2.21,2.21,0,0,0-.51,1.72,2.19,2.19,0,0,0,.92,1.52c.05,0,1.36,1,6.52,4.51,4.61,3.18,7.44,8.44,10.17,13.54C45.58,89.79,49.44,97,60,97c8.16,0,10.3-4.51,14-13.88,2.28-5.81,4.87-12.39,10.25-18.2,5.81-6.27,11.64-13.17,11.7-13.24a2.2,2.2,0,0,0-.33-3.29h0a2.35,2.35,0,0,0-3.06.5l-11,12.45a3.5,3.5,0,0,1-5.85-3.65l9.9-23.92a2.22,2.22,0,0,0,0-1.68,2.15,2.15,0,0,0-1.19-1.18,2.18,2.18,0,0,0-2.86,1.19L71.63,56a3.19,3.19,0,0,1-6.06-2l6.66-28.16a2.38,2.38,0,0,0-1.36-2.8,2.21,2.21,0,0,0-1.79.15A2.17,2.17,0,0,0,68,24.69L61.2,53.47a4,4,0,0,1-7.86-.62L51.56,28.58a2.37,2.37,0,0,0-2.14-2.26,2.17,2.17,0,0,0-1.65.68,2.14,2.14,0,0,0-.6,1.68l2,27.09c.34,4.71-1.27,8.44-4.43,10.25-3,1.71-6.92,1.33-10.25-1L27.1,59.92A2.67,2.67,0,0,0,25.63,59.47Z"/></svg>',
  LEGALBASIS = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><path class="cls-1" d="M85.86,80.14V28.28A11.58,11.58,0,0,0,74.29,16.71H28.57A11.58,11.58,0,0,0,17,28.28V39.86H34.14V91.72a11.58,11.58,0,0,0,11.57,11.57H91.43A11.58,11.58,0,0,0,103,91.72V80.14ZM23,33.86V28.28a5.57,5.57,0,0,1,11.14,0v5.58H23ZM40.14,91.72V28.28a11.56,11.56,0,0,0-1.43-5.57H74.29a5.58,5.58,0,0,1,5.57,5.57V80.14H51.28V91.72a5.57,5.57,0,0,1-11.14,0Zm56.86,0a5.58,5.58,0,0,1-5.57,5.57H55.85a11.56,11.56,0,0,0,1.43-5.57V86.14H97Z"/><path class="cls-1" d="M58.81,68.34l1.19.51,1.19-.51c.52-.22,12.67-5.59,12.67-16.91V39.36L60,34.06l-13.86,5.3V51.43C46.14,62.75,58.3,68.12,58.81,68.34ZM52.14,43.49l7.86-3,7.86,3v7.94c0,5.71-5.42,9.41-7.87,10.79-2.46-1.37-7.85-5-7.85-10.79Z"/></svg>',
  STANDARD = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><polygon class="cls-1" points="74.28 44.04 70.04 39.8 56 53.84 49.96 47.8 45.72 52.04 56 62.32 74.28 44.04"/><path class="cls-1" d="M83.33,68.85a30,30,0,1,0-46.66,0L27.25,89.73l15.58-1.58L53.06,100,60,80h0l6.94,20L77.17,88.15l15.58,1.58ZM60,26A24,24,0,1,1,36,50,24,24,0,0,1,60,26ZM50.8,88.2l-5.47-6.33L37,82.71l4.21-9.33a29.81,29.81,0,0,0,12.66,6Zm23.87-6.33L69.2,88.2l-3.07-8.83a29.81,29.81,0,0,0,12.66-6L83,82.71Z"/></svg>',
  MEASURETASK = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><defs><style>.cls-1{fill:#828294;}</style></defs><polygon class="cls-1" points="18.5 26 24.5 26 24.5 94 18.5 94 18.5 100 36.5 100 36.5 94 30.5 94 30.5 26 36.5 26 36.5 20 18.5 20 18.5 26"/><polygon class="cls-1" points="59.43 76.08 68.5 85.16 84.57 69.08 80.33 64.84 68.5 76.67 63.67 71.84 59.43 76.08"/><path class="cls-1" d="M69.74,20H42.5v80h59V51.76ZM71.5,30.24,91.26,50H71.5ZM95.5,94h-47V26h17V56h30Z"/></svg>'
}

@Component({
  selector: 'cmt-geods-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild("treeview") menu: DxTreeViewComponent;
  @ViewChild('toast', { static: false }) dxToast: ToastComponent;
  @ViewChild('drawer', { static: false }) drawer: dxDrawer;
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter((cl) => this.screen.sizes[cl]).join(' ');
  }
  @Output() openMenu = new EventEmitter<any>();
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;
    if (!this.menu.instance) {
      return;
    }
    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }
  @Input() set selectedItem(value: string) {
    this._selectedItem = value;
    if (!this.menu.instance) {
      return;
    }
    this.menu.instance.selectItem(value);
  }

  public cmtWhiteLogo = `<svg style="height: 50px; padding: 30px;" id="iunitgroup" xmlns="http://www.w3.org/2000/svg"viewBox="0 0 117.3 39.92"><defs><style>.cmtLogo { fill: #fff; }
  </style>
</defs>
<path class="cmtLogo"
  d="M174.66,78.1l1.2-3.13h10.72l3.84-10H179.71l3.85-10H172.84l-7.51,19.54a15,15,0,0,0,14,20.39h4.58l3.85-10h-8.43A5,5,0,0,1,174.66,78.1Z"
  transform="translate(-73.13 -54.97)" />
<path class="cmtLogo"
  d="M154.78,55h-39L104.22,84.9H88.14a5,5,0,0,1-4.67-6.8l3.33-8.64A7,7,0,0,1,93.33,65h13.48l3.85-10h-18A16,16,0,0,0,77.71,65.23l-3.57,9.28a15,15,0,0,0,14,20.39h23L122.6,65h10L121.09,94.9h10.72L143.32,65h8.55a1,1,0,0,1,.93,1.36l-11,28.57h10.71l10.66-27.69A9,9,0,0,0,154.78,55Z"
  transform="translate(-73.13 -54.97)" />
</svg>`;
  public cmtLogo = `<svg id="iunitgroup" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.3 39.92"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M174.66,78.1l1.2-3.13h10.72l3.84-10H179.71l3.85-10H172.84l-7.51,19.54a15,15,0,0,0,14,20.39h4.58l3.85-10h-8.43A5,5,0,0,1,174.66,78.1Z" transform="translate(-73.13 -54.97)"/><path class="cls-1" d="M154.78,55h-39L104.22,84.9H88.14a5,5,0,0,1-4.67-6.8l3.33-8.64A7,7,0,0,1,93.33,65h13.48l3.85-10h-18A16,16,0,0,0,77.71,65.23l-3.57,9.28a15,15,0,0,0,14,20.39h23L122.6,65h10L121.09,94.9h10.72L143.32,65h8.55a1,1,0,0,1,.93,1.36l-11,28.57h10.71l10.66-27.69A9,9,0,0,0,154.78,55Z" transform="translate(-73.13 -54.97)"/></svg>`;
  private _compactMode = false;
  public abreviation: string = 'CMT';
  public title = "";
  private readonly base64Format: string = "data:image/png;base64,";
  public selectedRoute = '';
  public profileAvatar: string;
  public menuOpened: boolean;
  public menuMode = 'shrink';
  public menuRevealMode = 'expand';
  public minMenuSize = 0;
  public shaderEnabled = false;
  public tooltipContent: string;
  public target: any;
  public isDefaultLanguageDe: boolean;
  public isVisible = false;
  public fullName: string;
  public currentUser: GeoDsLoginInfo = new GeoDsLoginInfo(null);
  private subscriptions: Subscription[] = [];
  public contextMenuItems = [
    {
      text: 'Logout',
      icon: 'assets/images/logout.svg',
      onClick: () => {
        this.userService.currentProfileAvatar = undefined;
        this.userService.profileInfo = undefined;
        sessionStorage.removeItem('currentCompany');
        this.menu?.instance.unselectAll();
        this.menu?.instance.selectItem('/subProject');
        this.userService.clearLoginInfo();
        this.router.navigate(['/login']);
      }
    }];

  private scope: AccessibleScopes;
  @Input() contextmenuMode: string;
  private _selectedItem: string;
  private _temporaryMenuOpened = false;
  public navigation: NavigationMenu[] = [];
  public path;
  public pointerEvent;

  constructor(
    private screen: ScreenService,
    private elementRef: ElementRef,
    private coreDataService: GeoDsCoreDataService,
    private router: Router,
    private userService: UserService,
    private dataService: DataService,
    public resource: Resource,
    private ideTimerService: IdeTimerService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private resourceManagerService: ResourceManagerService,
    public riskAssessmentService: RiskAssessmentService

  ) {
    if (!sessionStorage.getItem('fixedCulture')) {
      sessionStorage.setItem('fixedCulture', 'en');
    }
    if (sessionStorage.getItem('fixedCulture') === 'de') {
      loadMessages(deMessages);
      locale('de');
      this.isDefaultLanguageDe = true;
    } else {
      loadMessages(enMessages);
      locale('en');
      this.isDefaultLanguageDe = false;
    }

    loadMessages({
      'en': {
        'Cmt-App-Home': 'Home',
        'Cmt-App-Dashboard': 'Dashboard',
        'Cmt-App-Risk-Catalog': 'Risk catalog',
        'Cmt-App-Measure-Catalog': 'Measure catalog',
        'Cmt-App-Libraries': 'Libraries',
        'Cmt-App-Risk-Assessment-Measure': 'Risk assessment measure',
        'Cmt-App-Measure-Type': 'Measure Type',
        'Cmt-App-Measure-Task': 'Tasks',
        'Cmt-App-Module': 'Category',
        'Cmt-App-Scope': 'Subcategory',
        'Cmt-App-Tag': 'Tag',
        'Cmt-App-Land': 'Land',
        'Cmt-App-Standard': 'Standard',
        'Cmt-App-Product': 'Product',
        'Cmt-App-Measures-Definition': 'Measures definition',
        'Cmt-App-Benefit': 'Utility',
        'Cmt-App-Type-Of-Damage': 'Type of damage',
        'Cmt-App-Responsibility-Plan': 'Responsibility plan',
        'Cmt-App-Legal-Foundation': 'Legal foundation'
      },
      'de': {
        'Cmt-App-Home': 'Home',
        'Cmt-App-Dashboard': 'Dashboard',
        'Cmt-App-Risk-Catalog': 'Risikokatalog',
        'Cmt-App-Measure-Catalog': 'Maßnahmenkatalog',
        'Cmt-App-Libraries': 'Bibliotheken',
        'Cmt-App-Risk-Assessment-Measure': 'Risikoeinschätzungsmaßnahme',
        'Cmt-App-Measure-Type': 'Maßnahme Typ',
        'Cmt-App-Measure-Task': 'Aufgaben',
        'Cmt-App-Module': 'Kategorie',
        'Cmt-App-Scope': 'Unterkategorie',
        'Cmt-App-Tag': 'Tag',
        'Cmt-App-Land': 'Land',
        'Cmt-App-Standard': 'Standard',
        'Cmt-App-Product': 'Produkt',
        'Cmt-App-Measures-Definition': 'Maßnahmen Definition',
        'Cmt-App-Benefit': 'Nutzen',
        'Cmt-App-Type-Of-Damage': 'Schadensart',
        'Cmt-App-Responsibility-Plan': 'Verantwortungsplan',
        'Cmt-App-Legal-Foundation': 'rechtliche Grundlage'
      }
    });

    this.navigation = [
      {
        text: this.resource.res('Cmt-App-Home'),
        component: 'Cmt-App-Home',
        path: '/home',
        icon: NavigationSvg.HOME
      },
      {
        text: this.resource.res('Cmt-App-Dashboard'),
        component: 'Cmt-App-Dashboard',
        path: '/dashboard',
        icon: NavigationSvg.DASHBOARD
      },
      {
        text: this.resource.res('Cmt-App-Risk-Catalog'),
        component: 'Cmt-App-Risk-Catalog',
        path: '/risks',
        icon: NavigationSvg.RISK
      },
      {
        text: this.resource.res('Cmt-App-Measure-Catalog'),
        component: 'Cmt-App-Measure-Catalog',
        path: '/measures',
        icon: NavigationSvg.MEASURE
      },
      {
        text: this.resource.res('Cmt-App-Libraries'),
        component: 'Cmt-App-Libraries',
        icon: NavigationSvg.LIBRARY,
        isExpanded: false,
        items: [
          {
            text: this.resource.res('Cmt-App-Risk-Assessment-Measure'),
            component: 'Cmt-App-Risk-Assessment-Measure',
            path: '/RiskAssesmentMeasures',
            icon: NavigationSvg.RISKAMEASURE
          },
          {
            text: this.resource.res('Cmt-App-Measure-Type'),
            component: 'Cmt-App-Measure-Type',
            path: '/measureTypes',
            icon: NavigationSvg.MEASURETYPE
          },
          {
            text: this.resource.res('Cmt-App-Measure-Task'),
            component: 'Cmt-App-Measure-Task',
            path: '/measureTasks',
            icon: NavigationSvg.MEASURETASK
          },

          {
            text: this.resource.res('Cmt-App-Module'),
            component: 'Cmt-App-Module',
            path: '/modules',
            icon: NavigationSvg.MODULE
          },
          {
            text: this.resource.res('Cmt-App-Scope'),
            component: 'Cmt-App-Scope',
            path: '/scopes',
            icon: NavigationSvg.SCOPE
          },
          {
            text: this.resource.res('Cmt-App-Tag'),
            component: 'Cmt-App-Tag',
            path: '/tags',
            icon: NavigationSvg.TAG
          },
          {
            text: this.resource.res('Cmt-App-Land'),
            component: 'Cmt-App-Land',
            path: '/countries',
            icon: NavigationSvg.LAND
          },
          {
            text: this.resource.res('Cmt-App-Standard'),
            component: 'Cmt-App-Standard',
            path: '/standards',
            icon: NavigationSvg.STANDARD
          },
          {
            text: this.resource.res('Cmt-App-Product'),
            component: 'Cmt-App-Product',
            path: '/products',
            icon: NavigationSvg.PRODUCT
          },
          {
            text: this.resource.res('Cmt-App-Measures-Definition'),
            component: 'Cmt-App-Measures-Definition',
            path: '/measureDefinitions',
            icon: NavigationSvg.MEASUREDEFINITION
          },
          {
            text: this.resource.res('Cmt-App-Benefit'),
            component: 'Cmt-App-Benefit',
            path: '/benefits',
            icon: NavigationSvg.BENEFITS
          },
          {
            text: this.resource.res('Cmt-App-Type-Of-Damage'),
            component: 'Cmt-App-Type-Of-Damage',
            path: '/damageskind',
            icon: NavigationSvg.DAMAGEKINND
          },
          {
            text: this.resource.res('Cmt-App-Responsibility-Plan'),
            component: 'Cmt-App-Responsibility-Plan',
            path: '/Responsibleplans',
            icon: NavigationSvg.RESPONSIBLEPLAN
          },
          {
            text: this.resource.res('Cmt-App-Legal-Foundation'),
            component: 'Cmt-App-Legal-Foundation',
            path: '/legalbasis',
            icon: NavigationSvg.LEGALBASIS
          }
        ]
      }

    ];
  }


  public ngOnInit() {
    this.subscriptions.push(this.appService.navigateSubject$.subscribe((notif) => {
      if (notif) {
        this.riskAssessmentService.riskToUpdate = new RiskIssue(null);
        this.riskAssessmentService.risk = new RiskIssue(null);
        this.riskAssessmentService.riskIssuesCountryIds = [];
        this.riskAssessmentService.riskIssuesCountryIdsToUpdate = [];
        this.riskAssessmentService.riskIssuesStandardIds = [];
        this.riskAssessmentService.riskIssuesStandardIdsToUpdate = [];
        this.riskAssessmentService.riskIssuesDamageKindIds = [];
        this.riskAssessmentService.riskIssuesDamageKindIdsToUpdate = [];
        this.appService.navigateSubject.next(false);
        this.navigate(this.pointerEvent, this.path);
      }
    }))
    this.subscriptions.push(this.dataService.companyName$.subscribe((scope: string) => {
      this.title = scope
    }));
    this.subscriptions.push(forkJoin([
      this.dataService.loadListDataSource(ObjectKeys.ADDRESS, "Status"),
      this.dataService.loadListDataSource(ObjectKeys.ADDRESS, "CompanyTitle")
    ]).subscribe((lists) => {
      this.dataService.cachedStatus = lists[0].Data;
      this.dataService.cachedCompaniesTitles = lists[1].Data;
    }, error => {
      console.error(error);
    }));
    if (localStorage.getItem("refresh_token")) {

      if (!this.currentUser.displayName) {
        this.subscriptions.push(this.userService.getProfileInformations().subscribe(user => {
          this.currentUser.displayName = user.DisplayText;
        }));
      }

    }

    this.subscriptions.push(this.userService.currentUser$.subscribe(user => {
      this.currentUser.displayName = user;

    }));

    this.menuOpened = this.screen.sizes['screen-large'];
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });
    this.updateDrawer();
    this.screen.changed.subscribe(() => this.updateDrawer());
    this.ideTimerService.tracker();
    this.subscriptions.push(this.appService.notification$.subscribe((notification) => {
      this.dxToast.showNotification(notification);
    }));

    this.userService.currentUser$.subscribe((args) => {
      this.fullName = args;
    });
  }

  public changeLanguage = (): void => {
    if (sessionStorage.getItem('fixedCulture') === 'de') {
      sessionStorage.setItem('fixedCulture', 'en');
      loadMessages(enMessages);
      locale('en');
      this.isDefaultLanguageDe = true;
    } else {
      sessionStorage.setItem('fixedCulture', 'de');
      loadMessages(deMessages);
      locale('de');
      this.isDefaultLanguageDe = true;
    }
    this.cdr.detectChanges();
    location.reload();
  }

  public localStorageItem(): string {
    return localStorage.getItem('projectName');
  }

  public testChildElement(items, index): any {
    for (let i = index; i > -1; i--) {
      if (items[i]?.items?.length > 0) {
        return [i, index - (i + 1)]
      }
    }
    return 0
  }

  public ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (event: MouseEvent) => {
      this.openMenu.next(event);
    });
  }

  public ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
  }

  public isAuthenticated(): boolean {
    if (this.userService.isAuthenticated() && this.selectedRoute !== '/login') {
      return true;
    }
  }

  public onContentReady(e): void {
    const elements = e.component.element().querySelectorAll(".dx-treeview-item");
    elements.forEach((el, index) => {
      el.addEventListener("mouseenter", event => {
        const icone = el.querySelector(".dx-icon");
        if (!this.menuOpened) {
          if (e.component._dataAdapter.options.items[index]?.text) {
            this.tooltipContent = e.component._dataAdapter.options.items[index].text;
            this.target = icone;
            this.isVisible = true;
          }
          // else if (this.testChildElement(e.component._dataAdapter.options.items, index)) {
          //   const [parentIndex, childIndex] = this.testChildElement(e.component._dataAdapter.options.items, index);
          //   const subElement = e.component._dataAdapter.options.items[parentIndex]?.items[childIndex];
          //   if (subElement.text.length > 0) {
          //     this.tooltipContent = subElement.text;
          //     this.target = icone;
          //     this.isVisible = true;
          //   }
          // }
        }
      });
      el.addEventListener("mouseleave", () => {
        this.isVisible = false;
      });
    });
  }

  private updateDrawer() {
    const isSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];
    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isSmall ? 'slide' : 'expand';
    this.minMenuSize = isSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  public toggleMenu = (e) => {
    this.menuOpened = !this.menuOpened;
  };

  public areArraysEquals(arr1, arr2): boolean {
    return arr1.length == arr2.length &&
      (arr1.every((currElem) => {
        if (arr2.indexOf(currElem) > -1) {
          return (currElem == arr2[arr2.indexOf(currElem)]);
        } return false
      })
      )
  }

  public onMenuItemClick(event) {
    if (this.showMenuAfterClick) {
      this._temporaryMenuOpened = true;
      this.menuOpened = true;
    }
    this.path = event.itemData.path;
    localStorage.setItem("path", this.path);
    this.pointerEvent = event.event;
    if (this.path && this.menuOpened) {
      if (
        !this.appService.shallowEqual(this.riskAssessmentService.riskToUpdate,
          this.riskAssessmentService.risk) ||
        !this.areArraysEquals(this.riskAssessmentService.riskIssuesCountryIds,
          this.riskAssessmentService.riskIssuesCountryIdsToUpdate) ||
        !this.areArraysEquals(this.riskAssessmentService.riskIssuesStandardIds,
          this.riskAssessmentService.riskIssuesStandardIdsToUpdate) ||
        !this.areArraysEquals(this.riskAssessmentService.riskIssuesDamageKindIds,
          this.riskAssessmentService.riskIssuesDamageKindIdsToUpdate)
      ) {
        this.riskAssessmentService.saveBeforeClosePopup.next(true);
      } else {
        this.navigate(this.pointerEvent, this.path)
      }
    } else {
      this.pointerEvent.preventDefault();
    }
    this.menu.instance.selectItem(event.itemData.path);
  }

  public navigate(pointerEvent, path): void {
    this.router.navigate([path]);
    this.scrollView?.instance.scrollTo(0);
    if (this.hideMenuAfterNavigation) {
      this._temporaryMenuOpened = false;
      this.menuOpened = false;
      pointerEvent.stopPropagation();
    }
  }

  public onRouterOutletActivate(event) {
    this.menu?.instance.selectItem(this.router.url)
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this._temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }
  
}