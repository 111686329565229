/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumn, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Measure, RiskAssesmentMeasure, RiskIssue } from '@xmt-models';
import { Observable, Subject } from 'rxjs';
import { DataService, ObjectKeys } from '../../services/data.service';

export enum risksPickLists {
  CMTEVALUATION = "cmtEvaluation",
  CMTRISKSTATUS = "cmtRiskStatus",
  CMTTYPEOFDAMAGE = "cmtTypeOfDamage"
}

export interface riskMeasureToDisplay {
  Id: string,
  Type: string,
  Measure: string,
  MeasureId: string,
  RiskReduction: string
}

@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentService {
  public risk: RiskIssue = new RiskIssue(null);
  public risks: RiskIssue[] = [];
  public riskIds: Array<string> = [];
  public currentIssueIndex: number = 0;
  public riskToUpdate: RiskIssue = new RiskIssue(null);
  public saveBeforeClosePopup = new Subject<boolean>();
  public saveBeforeClosePopup$ = this.saveBeforeClosePopup.asObservable();
  public riskIssuesCountryIds: Array<string> = [];
  public riskIssuesCountryIdsToUpdate: Array<string> = [];
  public riskIssuesStandards: Map<string, string> = new Map<string, string>();
  public riskIssuesStandardIds: Array<string> = [];
  public riskIssuesStandardIdsToUpdate: Array<string> = [];
  public riskIssuesDamageKinds: Map<string, string> = new Map<string, string>();
  public riskIssuesDamageKindIds: Array<string> = [];
  public riskIssuesDamageKindIdsToUpdate: Array<string> = [];
  public assignedMeasures: riskMeasureToDisplay[] = [];
  public proposedMeasures: riskMeasureToDisplay[] = [];


  constructor(public dataService: DataService, private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService) {
  }

  public readRiskssPickList(pickListName: string) {
    const language = sessionStorage.getItem('fixedCulture') === 'en' ? "1033/'" : "1031/'";
    const opath = "ref:ParentId='" + pickListName + "/";
    const columns: QueryColumn[] = [
      { ...this.coreDataService.createQueryColumn('Value', 'Value') },
      { ...this.coreDataService.createQueryColumn('Description', 'Description') }
    ];
    return this.dataService.readObjects(ObjectKeys.PICKLISTITEM, columns, opath + language)
  }

  public updateRiskAssessmentMeasureRiskReduction(ramId: string, typeUpdate: boolean, value: string): Observable<any> {
    const riskAssessmentMeasureQuery: TargetObjectData = new TargetObjectData();
    riskAssessmentMeasureQuery.ObjectKey = new ObjectKey();
    riskAssessmentMeasureQuery.ObjectKey.ObjectType = ObjectKeys.RISKASSESMENTMEASURE;

    const riskAssessmentMeasureColumns: TargetColumnValue[] = [
      { Name: typeUpdate ? 'Type' : 'Value', Value: value }
    ];

    riskAssessmentMeasureQuery.Mode = PersistMode.Update;
    riskAssessmentMeasureQuery.ObjectKey.Id = ramId;
    riskAssessmentMeasureQuery.TargetColumns = riskAssessmentMeasureColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = riskAssessmentMeasureQuery;
    return this.persistenceService.executePersistObjectQuery(persistObject);
  }

  public clear(): void {
    this.risk = new RiskIssue(null);
    this.riskToUpdate = new RiskIssue(null);
    this.riskIssuesCountryIds = [];
    this.riskIssuesDamageKinds.clear();
    this.riskIssuesStandards.clear();

    this.riskIssuesCountryIdsToUpdate = [];
    this.riskIssuesStandardIds = [];
    this.riskIssuesStandardIdsToUpdate = [];
    this.riskIssuesDamageKindIds = [];
    this.riskIssuesDamageKindIdsToUpdate = [];
  }
}
