import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoDsCoreDataService, QueryColumn, QueryColumnSortOrder, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Column } from 'devextreme/ui/data_grid';
import { map, switchMap } from 'rxjs/operators';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { SharedModule } from '../../../shared/shared.module';
import { AppService } from '../../../services/app.service';
import { MeasureTask } from '@xmt-models';
import { MeasureTaskService } from './measure-task.service';
import { forkJoin, Subscription } from 'rxjs';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxPopupComponent } from 'devextreme-angular';
import { PersistModel } from '../../../models/general.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'measure-task',
  templateUrl: './measure-task.component.html',
  styleUrls: ['./measure-task.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureTaskComponent implements OnInit, OnDestroy {

  @ViewChild('taskGrid') taskGrid: GridComponent;
  @ViewChild('deleteTaskPopup') deleteTaskPopup: DxPopupComponent;
  @ViewChild('taskPopup') taskPopup: DxPopupComponent;
  public submitButtonText: string = '';
  public measureTask = new MeasureTask(null);
  public companyId = sessionStorage.getItem('currentCompany');
  public measureTasks = [];
  public measures = [];
  public columnsHeader: Column[] = [];
  public measureTasksColumns: QueryColumn[] = [];
  public showLoader = true;
  public modeCreate = false;
  public selectedTaskId = "";
  public minDate: Date = null;
  private selectedMeasure = "";
  private subscriptions: Subscription[] = [];
  private readonly VISIBLE_TASK_COLUMNS = ['Subject', 'IdRefResponsible', 'ParentId', 'StartDate', 'EndDate', 'TargetMaturityLevel', 'DegreeOfImplementation', 'TotalTimeRequired'];

  constructor(private cdr: ChangeDetectorRef, private coreDataService: GeoDsCoreDataService, private persistenceService: GeoDsPersistenceService,
    private datePipe: DatePipe, public dataService: DataService, public measureTaskService: MeasureTaskService,
    public appService: AppService) { }

  ngOnInit(): void {
    const staffMemberMeasureData = forkJoin([
      this.dataService.prepareStaffMembers(),
      this.dataService.prepareMeasureList()
    ])
    this.subscriptions.push(staffMemberMeasureData.pipe(switchMap(data => {
      if (Array.isArray(data[0]) && data[0].length > 0) {
        this.dataService.cachedResponsiblesResponse = data[0];
      }
      if (Array.isArray(data[1]) && data[1].length > 0) {
        this.measures = data[1];
      }
      return this.coreDataService.executeReadObjectTypeInfo({ ObjectTypeName: ObjectKeys.TASK })
        .pipe(switchMap(layoutStructure => {
          layoutStructure.Columns.forEach(column => {
            this.columnsHeader.push({
              dataField: column.Name,
              caption: (column.Name === 'ParentId') ? this.dataService.res('Cmt-MeasureTask-Measure')
                : this.dataService.res('Cmt-MeasureTask-' + column.DisplayText),
              dataType: this.dataService.getDataType(column.DataType),
              alignment: (column.Name === 'DegreeOfImplementation' || column.Name === 'TotalTimeRequired') ? 'right' : 'left',
              visibleIndex: this.VISIBLE_TASK_COLUMNS.indexOf(column.Name),
              visible: (this.VISIBLE_TASK_COLUMNS.includes(column.Name)) ? true : false,
              lookup: (this.getLookupColumn(column.Name)),
              format: ((column.Name === 'TotalTimeRequired') ? "# h" : ""),
              groupIndex: (column.Name === 'ParentId') ? 0 : undefined,
              showInColumnChooser: (!this.appService.CONFIG_PROPERTIES.includes(column.Name) && !this.VISIBLE_TASK_COLUMNS.includes(column.Name)) ? true : false
            });
            (column.Name === 'ParentId') ? this.measureTasksColumns.push(this.coreDataService.createQueryColumn('!.Designation', column.Name, QueryColumnSortOrder.None)) :
              this.measureTasksColumns.push(this.coreDataService.createQueryColumn(column.Name, column.Name, QueryColumnSortOrder.None));
          });
          this.columnsHeader.push({
            type: "buttons",
            caption: '',
            alignment: 'left',
            minWidth: 70,
            dataField: 'edit',
            buttons: [{
              icon: 'edit',
              onClick: (event) => {
                this.submitButtonText = "Update";
                this.openEditTaskPopup(event);
              }
            }, {
              icon: "trash",
              onClick: (event) => { this.deletePopUp(event); }
            }]
          });
          const taskOpath = '!.!.!.ParentId=' + "'" + sessionStorage.getItem('currentCompany') + "'";
          return this.dataService.readObjects<MeasureTask[]>(ObjectKeys.TASK, this.measureTasksColumns, taskOpath)
            .pipe(map(tasks => (tasks)));
        }))
    })).subscribe((tasks) => {
      if (Array.isArray(tasks) && tasks.length > 0) {
        this.measureTasks = tasks;
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.cdr.markForCheck();
      this.showLoader = false;
    }));
  }

  public onValueChanged(e): void {
    this.minDate = e.value;
  }

  public openCreateTaskPopup(): void {
    this.modeCreate = true;
    if (this.companyId !== null) {
      this.submitButtonText = "Create";
      this.taskPopup.title = this.dataService.res('Cmt-MeasureTask-' + this.submitButtonText);
      this.clearForm();
      this.taskPopup.instance.show();
    } else {
      this.appService.callNotification({
        message: this.dataService.res('Cmt-Create-Task-Info-Mssg'),
        type: ToastType.INFO
      });
    }
  }

  private openEditTaskPopup(event): void {
    this.modeCreate = false;
    this.selectedTaskId = event.row?.data.Id;
    let index = this.measureTasks.findIndex(measureTask => {
      return measureTask.Id === this.selectedTaskId;
    });
    this.measureTask = new MeasureTask(event.row?.data);
    this.measureTask.Responsible = this.measureTasks[index].IdRefResponsible;
    this.measureTask.Label = this.measureTasks[index].Subject;
    if (this.companyId !== null) {
      this.submitButtonText = "Update";
      this.taskPopup.title = this.dataService.res('Cmt-MeasureTask-' + this.submitButtonText);
      this.cdr.markForCheck();
      this.taskPopup.instance.show();
    } else {
      this.appService.callNotification({
        message: this.dataService.res('Cmt-Create-Task-Info-Mssg'),
        type: ToastType.INFO
      });
    }
  }

  public persistTask(event) {
    event.preventDefault();
    if (this.submitButtonText !== "Create") {
      let measure = this.measures.find(measure => { return measure.Designation === this.measureTask.ParentId });
      this.selectedMeasure = measure.Designation;
      this.measureTask.ParentId = measure.Id;
    }
    const objectPersistQuery: TargetObjectData = new TargetObjectData();
    objectPersistQuery.ObjectKey = new ObjectKey();
    objectPersistQuery.ObjectKey.ObjectType = ObjectKeys.TASK;
    const objectColumns: TargetColumnValue[] = [
      { Name: 'Subject', Value: this.measureTask.Label },
      { Name: 'ParentId', Value: this.measureTask.ParentId },
      { Name: 'IdRefResponsible', Value: this.measureTask.Responsible },
      { Name: 'StartDate', Value: this.datePipe.transform(this.measureTask.StartDate, "yyyy-MM-dd") },
      { Name: 'EndDate', Value: this.datePipe.transform(this.measureTask.EndDate, "yyyy-MM-dd") },
      { Name: 'TargetMaturityLevel', Value: this.measureTask.TargetMaturityLevel },
      { Name: 'DegreeOfImplementation', Value: this.measureTask.DegreeOfImplementation },
      { Name: 'TotalTimeRequired', Value: this.measureTask.TotalTimeRequired },
    ];
    if (this.submitButtonText !== "Create") {
      objectPersistQuery.Mode = PersistMode.Update;
      objectPersistQuery.ObjectKey.Id = this.selectedTaskId;
    }
    else {
      objectPersistQuery.Mode = PersistMode.Insert;
    }
    objectPersistQuery.TargetColumns = objectColumns;
    const persistObject: PersistObjectModel = new PersistObjectModel();
    persistObject.Object = objectPersistQuery;
    this.persistenceService.executePersistObjectQuery(persistObject)
      .subscribe(data => {
        if (this.submitButtonText === "Create") {
          this.measureTask.Id = data.Id;
          this.measureTask.ParentId = this.selectedMeasure;
          this.measureTasks.push(this.measureTask);
          let index = this.measureTasks.findIndex(measureTask => { return measureTask.Id === data.Id });
          this.measureTasks[index].IdRefResponsible = this.measureTask.Responsible;
          this.measureTasks[index].Subject = this.measureTask.Label;
        }
        else {
          let index = this.measureTasks.findIndex(measureTask => { return measureTask.Id === data.Id });
          this.measureTasks[index] = this.measureTask;
          this.measureTasks[index].ParentId = this.selectedMeasure;
          this.measureTasks[index].IdRefResponsible = this.measureTask.Responsible;
          this.measureTasks[index].Subject = this.measureTask.Label;
        }
        this.taskGrid.refreshGrid();
      }, () => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
      }, () => {
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Success'),
          type: ToastType.SUCCESS
        });
        this.onCloseTaskPopup();
        this.modeCreate = false;
        this.selectedMeasure = undefined;
      });
  }

  public onCloseTaskPopup(): void {
    this.clearForm();
    this.taskPopup.instance.hide();
  }

  public deleteTask(): void {
    this.subscriptions.push(this.dataService.deleteObject<PersistModel>(ObjectKeys.TASK, this.selectedTaskId).subscribe((deletedTask) => {
      if (deletedTask?.Id) {
        this.measureTasks = this.measureTasks.filter(
          (task) => task.Id != deletedTask?.Id
        );
        this.taskGrid.refreshGrid()
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Success'),
          type: ToastType.SUCCESS
        });
      }
    },
      (error) => {
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Error'),
          type: ToastType.ERROR
        });
      }, () => {
        this.onCloseDeletePopup();
      }))
  }

  private deletePopUp(event): void {
    this.selectedTaskId = event.row.data.Id;
    this.deleteTaskPopup.instance.show();
  }

  public onCloseDeletePopup(): void {
    this.deleteTaskPopup.instance.hide();
  }

  private getLookupColumn(key: string) {
    if (key === 'IdRefResponsible') {
      return { valueExpr: 'Id', displayExpr: this.dataService.getResponsibleFullName, dataSource: (this.dataService.cachedResponsiblesResponse.length > 0) ? this.dataService.cachedResponsiblesResponse.sort((a, b) => a.LastName.localeCompare(b.LastName)) : this.dataService.cachedResponsiblesResponse };
    }
    if (key === 'TargetMaturityLevel') {
      return { valueExpr: 'name', displayExpr: 'value', dataSource: this.appService.maturityLevel };
    }
    if (key === 'DegreeOfImplementation') {
      return { valueExpr: 'name', displayExpr: 'value', dataSource: this.appService.percentageValue };
    }
  }

  public onCellPrepared(e): void {
    if (e.rowType == "header" && e.column.name === 'DegreeOfImplementation' || e.rowType == "header" && e.column.name === 'TotalTimeRequired') {
      e.cellElement.style.textAlign = "left";
    }
  }

  public onSelectMeasure(event) {
    this.selectedMeasure = event.itemData.Designation;
  }

  private clearForm(): void {
    this.measureTask = new MeasureTask(null);
    this.minDate = null;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

}



