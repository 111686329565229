<dx-accordion class="accrodion-style" [collapsible]="true" [animationDuration]="300" [visible]="true" [multiple]="false"
    [dataSource]="['form']">
    <div *dxTemplate=" let data of 'title'">
        {{dataService.res('Cmt-RiskDetail-Measure')}}
    </div>
    <div *dxTemplate=" let data of 'item'">
        <ww-grid #RiskAssesmentDetailsGrid class="grid-group-by-style" [columnsHeader]='columnsHeader'
            [gridData]='measures' [groupable]="false" [enableColumnChooser]="false" [showBorders]="false"
            [enableExport]="false" [showfilterrow]="false" [selectionMode]="'single'" [columnFilter]="false"
            [showRowLines]="false" [showColumnLines]="false" [allowDeleting]="true" [allowUpdating]="true"
            [filterPanel]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false" [searchPanel]="false"
            [allowAdding]="false" (onEditorPreparing)="onEditorPreparing($event)" [editingMode]="editingMode">
        </ww-grid>
        <dx-popup #assignedMeasureDelete [width]="'25%'" [showTitle]="false" fxLayout="column"
            fxLayoutAlign="center center" [height]="'auto'" [showCloseButton]="false">
            <div class="delete-popup">
                <div>{{dataService.res('Cmt-RiskDetail-Move-Assigned-Measure-To-Proposed')}}</div>
                <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <dx-button class="button-confirm" [text]="dataService.res('Cmt-Yes')"
                        (onClick)="moveAssignedMeasureToProposed()">
                    </dx-button>
                    <dx-button class="button-cancel" [text]="dataService.res('Cmt-No')"
                        (onClick)="closeAssignedMeasureDelete()">
                    </dx-button>
                </dxi-item>
            </div>
        </dx-popup>
    </div>
</dx-accordion>