<dx-circular-gauge id="averageRiskAmountOfDamagesGauge" [value]="riskAmountOfDamages">
  <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="10">
    <dxo-label [useRangeColors]="true" [customizeText]="customizeText"></dxo-label>
  </dxo-scale>
  <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="10">
  </dxo-scale>
  <dxo-size [height]="300" [width]="350">
  </dxo-size>
  <dxo-geometry [startAngle]="180" [endAngle]="0">
  </dxo-geometry>
  <dxo-range-container palette="pastel">
    <dxi-range color="#5CE160" [startValue]="0" [endValue]="18"></dxi-range>
    <dxi-range color="#FFDD33" [startValue]="18" [endValue]="42"></dxi-range>
    <dxi-range color="#FFA233" [startValue]="42" [endValue]="71"></dxi-range>
    <dxi-range color="#FF5733" [startValue]="71" [endValue]="100"></dxi-range>
  </dxo-range-container>
</dx-circular-gauge>