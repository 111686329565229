<div class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="titleStyle">{{dataService.res('Cmt-Standard')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-button fxLayoutAlign="end" [text]="dataService.res('Cmt-Standard-Create')" icon="add"
                    style="width: fit-content;" (onClick)="addRow()">
                </dx-button>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <ww-grid #standardGrid [columnsHeader]='columnsHeader' [gridData]='standardList' class="grid-group-by-style"
                [enableStateStorage]="false" (onSaved)="persistStandard($event)" [showRowLines]="true"
                [enableColumnChooser]="false" [editingMode]='"row"' [allowAdding]="false">
            </ww-grid>
        </div>
    </div>
</div>
<cmt-loader *ngIf="showLoader"></cmt-loader>