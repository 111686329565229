<dx-accordion class="accrodion-style" [collapsible]="true" [animationDuration]="300" [visible]="true" [multiple]="false"
    [dataSource]="['form']">
    <div *dxTemplate=" let data of 'title'">
        {{dataService.res('Cmt-RiskDetail-Proposed-Measures')}}
    </div>
    <div *dxTemplate=" let data of 'item'">
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row">
                <ww-grid [id]="'VorgeschlageneGrid'" [groupable]="false" [columnsHeader]='measureSuggestionsColumns'
                    [gridData]='measures' [editingMode]='"row"' [enableColumnChooser]="false" [showBorders]="false"
                    [enableExport]="false" [showfilterrow]="false" [selectionMode]="'single'" [columnFilter]="false"
                    [showRowLines]="false" [showColumnLines]="false" [filterPanel]="false" [allowDeleting]="false"
                    [allowUpdating]="false" [enableHeaderFilter]="false" [rowAlternationEnabled]="false"
                    [searchPanel]="false" [allowAdding]="false">
                </ww-grid>
                <dx-popup #proposedMeasurePopup [width]="'25%'" [showTitle]="false" fxLayout="column"
                    fxLayoutAlign="center center" [height]="'auto'" [showCloseButton]="false">
                    <div class="delete-popup">
                        <div>{{dataService.res('Cmt-RiskDetail-Move-Proposed-Measure-To-Assigned')}}</div>
                        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                            <dx-button class="button-confirm" [text]="dataService.res('Cmt-Yes')"
                                (onClick)="moveProposedMeasureToAssigned()">
                            </dx-button>
                            <dx-button class="button-cancel" [text]="dataService.res('Cmt-No')"
                                (onClick)="closeProposedMeasurePopup()">
                            </dx-button>
                        </dxi-item>
                    </div>
                </dx-popup>
            </div>
        </div>
    </div>
</dx-accordion>