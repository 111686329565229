import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QueryColumn } from '@wissenswerft/core/data';
import { FieldArea } from 'libs/ww-library/src/lib/ww-matrix-grid/matrix-grid.model';
import { DashBoardMeasure, MatrixViewKeyDe, MatrixViewKeyEn, RiskDistribution } from '../../models/dashboard.model';
import { MatrixComponent } from '@wissenswerft/ww-library';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { Module, RiskIssue } from '@xmt-models';


@Component({
  selector: 'cmt-risk-overall-matrix',
  templateUrl: './risk-overall-matrix.component.html',
  styleUrls: ['./risk-overall-matrix.component.scss'],
})
export class RiskOverallMatrixComponent implements OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('riskMatrix') riskMatrix: MatrixComponent;
  @Input() risks: RiskIssue[];

  private data: RiskDistribution[] = [];
  private subscriptions: Subscription[] = [];
  public companyId = sessionStorage.getItem('currentCompany');
  public riskDistributionDataSource: RiskDistribution[] = [];
  public dataSource = {};
  public selectedSwitch: string = (this.dataService.getLanguage() === 'de') ? 'Gesamtübersicht' : 'General overview';
  public measures: DashBoardMeasure[] = [];
  public riskIssueColumns: QueryColumn[] = [];
  public modules: Module[] = [];

  constructor(public dataService: DataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.risks.forEach(risk => {
      if (risk.Probability && risk.AmountOfDamage) {
        this.data.push(new RiskDistribution(this.setMatrixHeader(risk.Probability.toString()), this.setMatrixHeader(risk.AmountOfDamage.toString()), 0));
      }
    });
    this.dataSource = {
      fields: [{
        dataField: 'probability.text',
        width: 50,
        area: FieldArea.ROW,
        sortingMethod: (a, b) => {
          return (this.dataService.getLanguage() == 'de') ? parseInt(MatrixViewKeyDe[b.value?.replace(' ', '')]) - parseInt(MatrixViewKeyDe[a.value?.replace(' ', '')]) : parseInt(MatrixViewKeyEn[b.value?.replace(' ', '')]) - parseInt(MatrixViewKeyEn[a.value?.replace(' ', '')]);
        }
      }, {
        dataField: 'damage.text',
        width: 50,
        area: FieldArea.COLUMN,
        sortingMethod: (a, b) => {
          return (this.dataService.getLanguage() == 'de') ? parseInt(MatrixViewKeyDe[a.value?.replace(' ', '')]) - parseInt(MatrixViewKeyDe[b.value?.replace(' ', '')]) : parseInt(MatrixViewKeyEn[a.value?.replace(' ', '')]) - parseInt(MatrixViewKeyEn[b.value?.replace(' ', '')]);
        }
      },
      {
        dataField: 'sum',
        area: FieldArea.DATA
      }],
      store: this.data
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.riskMatrix.onCellPrepared.subscribe(event => {
      event.cellElement.height = 50;
      event.element.style.textAlign = 'center';
      if (event.area === FieldArea.DATA) {
        if (event.rowIndex === 3 || event.columnIndex === 0) {
          event.cellElement.bgColor = 'lime';
        } else if (event.rowIndex === 2 || event.columnIndex === 1) {
          event.cellElement.bgColor = 'yellow';
        } else if (event.rowIndex === 1 || event.columnIndex === 2) {
          event.cellElement.bgColor = 'orange';
        } else {
          event.cellElement.bgColor = 'red';
        }
        event.cellElement.style.textAlign = 'center';
        event.cellElement.style.borderTop = '0px !important';
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public setMatrixHeader(value: string | number) {
    switch (value) {
      case '1': return { text: (this.dataService.getLanguage() == 'en') ? 'Low' : 'Niedrig', value: 1 };
      case '3': return { text: (this.dataService.getLanguage() == 'en') ? 'Medium' : 'Mittel', value: 3 };
      case '5': return { text: (this.dataService.getLanguage() == 'en') ? 'High' : 'Hoch', value: 5 };
      case '7': return { text: (this.dataService.getLanguage() == 'en') ? 'Very High' : 'Sehr Hoch', value: 7 };
    }
  }


}
