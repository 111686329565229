<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly center">
    <p class="polar-chart-title">
        {{this.dataService.res('Cmt-DashBoard-Risk-Per-Module')}}</p>
    <dx-select-box [(value)]="selectedSwitch" [dataSource]="spiderChartDataSource" (onValueChanged)="switchChanged()"
        [width]="'auto'">
    </dx-select-box>
</div>
<dx-polar-chart #polarChart id="chart" class="area-chart-style" [useSpiderWeb]="true" [dataSource]="spiderData"
    [series]="spiderChartOptions">
    <dxo-argument-axis>
        <dxo-label>
            <dxo-font size="12px" weight=100>
            </dxo-font>
        </dxo-label>
    </dxo-argument-axis>
    <dxo-common-series-settings type="line">
    </dxo-common-series-settings>
    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
    </dxo-legend>
    <dxo-export [enabled]="false"></dxo-export>
    <dxo-tooltip [enabled]="true"></dxo-tooltip>
</dx-polar-chart>