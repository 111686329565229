/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProfileInfo, UserService } from '@wissenswerft/core/authentication';
import { AccessibleScopes } from 'libs/core/authentication/src/lib/models/profile-info.model';
import { AppService } from '../services/app.service';
import { DataService, ObjectKeys } from '../services/data.service';
import { HomeOptions } from '../models/home.model';
import { CoreConfigService } from '@wissenswerft/core/configuration';
import { Company } from '@wissenswerft/organizational-structure';
import { DxPopupComponent, DxScrollViewComponent, DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { Column } from 'devextreme/ui/data_grid';
import { GeoDsCoreDataService, QueryColumn, TargetColumnValue } from '@wissenswerft/core/data';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HomeService } from './home.service';
import { PersistModel } from '../models/general.model';

@Component({
  selector: 'cmt-geods-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  @ViewChild('filterSelectBox') filterSelectBox: DxSelectBoxComponent;
  @ViewChild("addCompanyPopup") addCompanyPopup: DxPopupComponent;
  @ViewChild("deleteCompanyPopup") deleteCompanyPopup: DxPopupComponent;
  @ViewChild("companyGrid") companyGrid: GridComponent;
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild('emailTextBox') emailTextBox: DxTextBoxComponent;
  @ViewChild('phoneTextBox') phoneTextBox: DxTextBoxComponent;
  @ViewChild('faxTextBox') faxTextBox: DxTextBoxComponent;

  private subscriptions: Subscription[] = [];
  public columns: QueryColumn[] = [
    this.coreDataService.createQueryColumn('name', 'name'),
    this.coreDataService.createQueryColumn('matchCode', 'matchCode'),
    this.coreDataService.createQueryColumn('status', 'status'),
    this.coreDataService.createQueryColumn('id', 'Id'),
    this.coreDataService.createQueryColumn('companyTitle', 'companyTitle'),
    this.coreDataService.createQueryColumn('phone', 'phone'),
    this.coreDataService.createQueryColumn('eMail', 'eMail'),
    this.coreDataService.createQueryColumn('street', 'street'),
    this.coreDataService.createQueryColumn('houseNumber', 'houseNumber'),
    this.coreDataService.createQueryColumn('zip', 'zip'),
    this.coreDataService.createQueryColumn('country', 'country'),
    this.coreDataService.createQueryColumn('city', 'city')];

  public profileInformation: ProfileInfo = new ProfileInfo(null);
  public rowIndex: number;
  public companies: Company[];
  public clonedCompanies: Company[];
  public company: Company;
  public homeOptions = HomeOptions;
  public companyChoices: AccessibleScopes[] = [];
  public displayGrid = false;
  public emptyCompany = false;
  public isUpdate = false;
  public buttonAction = '';
  private comapnyNumber = 100;
  public items = [];
  public selectedCompanyId: string;
  public searchTerm = new Subject<string>();
  public filterActive = false;
  public buttonTitle = "";
  public showLoader: boolean;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Cmt-Columns-Header-Name'),
      dataField: "name",
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Match-Code'),
      dataField: "matchCode",
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Company-Titles'),
      dataField: 'companyTitle',
      visibleIndex: 2,
      lookup: {
        dataSource:
          this.dataService.cachedCompaniesTitles,
        displayExpr: 'Description',
        valueExpr: 'Value'
      }
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Status'),
      dataField: 'status',
      visibleIndex: 3,
      lookup: {
        dataSource:
          this.dataService.cachedStatus,
        displayExpr: 'Description',
        valueExpr: 'Value'
      }
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Customer-Number'),
      dataField: "customerNumber",
      visibleIndex: 9,
      visible: false
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-City'),
      dataField: "city",
      visibleIndex: 4
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Street'),
      dataField: "street",
      visibleIndex: 5
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Zip'),
      dataField: "zip",
      visibleIndex: 6
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Phone'),
      dataField: "phone",
      visibleIndex: 7,
      visible: false
    },
    {
      caption: this.dataService.res('Cmt-Columns-Header-Email'),
      dataField: "eMail",
      visibleIndex: 2,
      visible: false
    }
  ];

  public createButtonOptions = {
    text: 'Create',
    useSubmitBehavior: true
  };

  public editButtonOptions = {
    text: 'Edit',
    useSubmitBehavior: true
  };

  public CancelButtonOptions = {
    text: 'Cancel',
    onClick: () => this.onClosePopup(false)
  };

  constructor(
    public dataService: DataService, public appService: AppService,
    private userService: UserService,
    private coreConfigservice: CoreConfigService,
    private coreDataService: GeoDsCoreDataService,
    private cdr: ChangeDetectorRef,
    public homeService: HomeService

  ) {
  }

  ngOnInit(): void {
    this.buttonTitle = this.dataService.res('Cmt-Home-Create-Company');
    this.company = new Company(null);
    this.columnsHeader.push({
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      dataField: 'edit',
      buttons: [{
        icon: "edit",
        text: this.dataService.res('Cmt-Edit'),
        onClick: (e) => { this.openCompanyDetail(e); }
      }, {
        icon: 'trash',
        text: this.dataService.res('Cmt-Delete'),
        onClick: (e) => {
          this.openDeleteCompanyDialog(e);
        }
      }]
    });
    this.userService.getProfileInformations().subscribe(profileInfo => {
      this.profileInformation = new ProfileInfo(profileInfo);
      this.companyChoices = this.profileInformation.accessibleScopes;
      this.companyChoices.forEach(choice => {
        choice.tileIconUrl = this.coreConfigservice.configuration.WebApi.BaseURL.concat(choice.tileIconUrl);
      });
    });

    this.getCompanies();
    this.search(this.searchTerm).subscribe((results) => {
      this.companies = [];
      this.emptyCompany = false;
      if (results !== null) {
        this.filterActive = true;
        if (results.length === 0) {
          this.emptyCompany = true;
        } else {
          this.companies = results;
        }
      } else {
        this.filterActive = false;
        this.comapnyNumber = 0;
        this.companies = this.clonedCompanies.slice(0, this.comapnyNumber + 100);
      }
      this.cdr.markForCheck();
    })
  }

  public getCompanies(): void {
    this.subscriptions.push(this.homeService.prepareQueryToGetCompanies(this.columns).subscribe(companiesData => {
      this.companies = companiesData.slice(0, this.comapnyNumber);
      this.clonedCompanies = [...companiesData];
      this.items = this.clonedCompanies.map((company) => {
        return {
          ...company,
          searchProperty: company.name.toUpperCase(),
        };
      });
      this.cdr.markForCheck()
      this.showLoader = false;
    }, error => {
      console.error(error);
      this.showLoader = false;
    }));
  }

  public search(terms: Observable<string>) {
    return terms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  public searchEntries(term: string) {
    if (!term) return of(null);
    term = term.toUpperCase();
    return of(
      this.items.filter((a) => a.searchProperty.indexOf(term) >= 0)
    );
  }

  public onCreateCompany(event): void {
    event.preventDefault();
    this.persistCompany();
  }

  public persistCompany(): void {
    const companyColumns: TargetColumnValue[] = [
      { Name: 'Name', Value: this.company.name },
      { Name: 'Status', Value: this.company.status },
      { Name: 'CustomerNumber', Value: this.company.customerNumber },
      { Name: 'Country', Value: <string>this.company.country },
      { Name: 'City', Value: this.company.city },
      { Name: 'Phone', Value: this.homeService.formatContactNumber(this.company.phone) },
      { Name: 'Fax', Value: this.homeService.formatContactNumber(this.company.fax) },
      { Name: 'EMail', Value: this.company.eMail },
      { Name: 'Street', Value: this.company.street },
      { Name: 'MatchCode', Value: this.company.matchCode },
      { Name: 'HouseNumber', Value: this.company.houseNumber },
      { Name: 'Zip', Value: this.company.zip },
      { Name: 'CompanyTitle', Value: this.company.companyTitle }
    ];

    if (this.homeService.validTextBoxStatus(this.emailTextBox, this.phoneTextBox, this.faxTextBox)) {
      this.subscriptions.push(this.homeService.persistCompany<PersistModel>(this.buttonAction, companyColumns, this.company).subscribe(data => {
        this.getCompanyById(this.columns, data.Id);
      }, () => {
        this.appService.callNotification({ message: this.dataService.res('Cmt-Notification-Error'), type: ToastType.ERROR });
      }))
    }
  }

  public getCompanyById(columns: QueryColumn[], id): void {
    let opath = 'Id=' + "'" + id + "'";
    this.subscriptions.push(this.dataService.readObjects(ObjectKeys.ADDRESS, columns, opath)
      .subscribe((companyData) => {
        if (this.buttonAction == 'edit') {
          let selectedIndex = this.companies.findIndex((company) => company.Id === id);
          const clonedItem = { ...this.company };
          this.companies[selectedIndex] = clonedItem;
          this.clonedCompanies[selectedIndex] = clonedItem;
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Success'),
            type: ToastType.SUCCESS
          });
        } else {
          this.clonedCompanies.push(companyData[0]);
          this.companies.push(companyData[0]);
          this.cdr.markForCheck();
          this.companyGrid.refreshGrid();
          this.appService.callNotification({
            message: this.dataService.res('Cmt-Notification-Error'),
            type: ToastType.SUCCESS
          });
        }
        this.onClosePopup(true);
      })
    );
  }

  public deleteCompany(): void {
    this.subscriptions.push(this.homeService.deleteCompany(this.selectedCompanyId).subscribe((deletedCompany) => {
      if (deletedCompany?.Id) {
        this.companies = this.filterCompanies(this.companies, deletedCompany);
        this.clonedCompanies = this.filterCompanies(this.clonedCompanies, deletedCompany);
        this.cdr.markForCheck();
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Success'),
          type: ToastType.SUCCESS
        });
      } else {
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Notification-Error'),
          type: ToastType.ERROR
        })
      }
    }, error => {
      this.appService.callNotification({
        message: this.dataService.res('Cmt-Notification-Error'),
        type: ToastType.ERROR
      });
    }))
    this.deleteCompanyPopup.instance.hide();
  }

  public filterCompanies(companies: Company[], deletedCompany: PersistModel) {
    return companies.filter((company) => company.Id != deletedCompany?.Id
    );
  }
  public onClosePopup(persist?: boolean): void {
    if (!persist) {
      if (this.isUpdate) {
        this.isUpdate = false;
      }
    }
    this.emptyData();
    this.addCompanyPopup.instance.hide();
    this.deleteCompanyPopup.instance.hide();
  }

  public openCompanyDialog(isUpdate?: boolean): void {
    this.isUpdate = isUpdate;
    this.addCompanyPopup.instance.show();
  }

  public openCompanyDetail(event): void {
    this.buttonAction = event.column.name;
    this.company = new Company(event.row?.data);
    this.selectedCompanyId = event.row.dataIndex;
    this.openCompanyDialog(true);
  }

  public openDeleteCompanyDialog(event): void {
    this.selectedCompanyId = event.row?.data.Id;
    this.deleteCompanyPopup.instance.show();
  }


  public emptyData(): void {
    this.company = new Company(null);
    this.buttonAction = '';
  }

  public(): void {
    this.emptyData();
  }

  public changedisplay(value: boolean): void {
    this.displayGrid = value;
  }
  public returnDxItemCssClass(className: string): string {
    return className;
  }
}
