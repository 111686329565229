<div class="specificContentStyleClass">
  <div>
    <h2 class="title-style">{{dataService.res('Cmt-App-Dashboard')}}</h2>
  </div>
  <div class="content-block">
    <div class="dx-card responsive-paddings">
      <dx-scroll-view class="layout-body">
        <div class="content">
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="75px">
            <div fxLayout="column" fxFlex="30" fxLayoutAlign="stretch center">
              <div fxLayout="row">
                <p class="risk-matrix-title">{{this.dataService.res('Cmt-DashBoard-Risk-Matrix')}}</p>
              </div>
              <div fxLayout="row">
                <p fxFlex="15" class="rotate-Label">{{this.dataService.res('Cmt-DashBoard-Probability')}} </p>
                <cmt-risk-overall-matrix fxFlex="85" [risks]="riskAssessmentService.risks"> </cmt-risk-overall-matrix>
              </div>
              <div fxLayout="row">
                <div fxLayout="column" fxFlex="25">
                </div>
                <div fxLayout="column" fxFlex="25">
                  <p style="font-weight: bold;">{{this.dataService.res('Cmt-DashBoard-Damage-Amount')}} </p>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="stretch center">
              <div fxLayout="row" fxLayoutAlign="center">
                <p class="gauge-Bar-title">{{this.dataService.res('Cmt-DashBoard-Realistic-Damage')}}</p>
              </div>
              <div fxLayout="row">
                <amount-of-damage-gauge [riskAmountOfDamages]="averageRiskAmountOfDamages"></amount-of-damage-gauge>
              </div>
              <div fxLayout="row" style="padding-left: 100px; padding-top: 40px">
              </div>
            </div>
            <div fxLayout="column" fxFlex="40" fxLayoutAlign="stretch center">
              <div>
                <cmt-risk-per-module-spider-chart [risks]="riskAssessmentService.risks">
                </cmt-risk-per-module-spider-chart>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px">
            <div fxLayout="column" fxFlex="49">
              <amount-of-damage-per-module [risks]="dashboardService.dashboardRisk"></amount-of-damage-per-module>
            </div>
            <div fxLayout="column" fxFlex="1"></div>
            <div fxLayout="column" fxFlex="49">
              <amount-of-risk-assesment-measure>
              </amount-of-risk-assesment-measure>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px">
            <div fxLayout="column" fxFlex="100">
              <!-- <cmt-risk-overall-chart></cmt-risk-overall-chart> -->
            </div>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </div>
</div>

<cmt-loader *ngIf="showLoader"></cmt-loader>