import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'amount-of-damage-gauge',
  templateUrl: './amount-of-damage.component.html',
  styleUrls: ['./amount-of-damage.component.scss']
})
export class AmountOfDamageComponent implements OnInit {
  @Input('riskAmountOfDamages') riskAmountOfDamages = 0;

  ngOnInit(): void { }

  public customizeText = (arg) => {
    return arg.valueText + ' %';
  };
}
