import { Injectable } from '@angular/core';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Module } from '@wissenswerft/organizational-structure';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  constructor(private persistenceService: GeoDsPersistenceService) { }
  public persistModule<T>(objectKey: string, persistMode: PersistMode, value: string, id?: string): Observable<T> {
    const modulePersistQuery: TargetObjectData = new TargetObjectData();
    modulePersistQuery.ObjectKey = new ObjectKey();
    modulePersistQuery.ObjectKey.ObjectType = objectKey;
    const objectColumns: TargetColumnValue[] = [
      { Name: 'Designation', Value: value },
      { Name: 'ParentId', Value: sessionStorage.getItem('currentCompany') }
    ];
    modulePersistQuery.Mode = persistMode;
    if (persistMode === PersistMode.Update) {
      modulePersistQuery.ObjectKey.Id = id;
    }
    modulePersistQuery.TargetColumns = objectColumns;
    const modulePersistObject: PersistObjectModel = new PersistObjectModel();
    modulePersistObject.Object = modulePersistQuery;
    return this.persistenceService.executePersistObjectQuery(modulePersistObject);
  }


}
