import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DxSpeedDialActionComponent } from 'devextreme-angular';
import { DataService, ObjectKeys } from '../../../../services/data.service';
import { Comment } from 'libs/core/comments/src/lib/models/comment.model';
import { Observable, Subscription } from 'rxjs';
import { Country, DamageKind, Standard, Module, SubModule, RiskIssue } from '@xmt-models';
import { AppService } from '../../../../services/app.service';
import { GeoDsCoreDataService, Query, QueryColumn, QueryObjectsModel } from '@wissenswerft/core/data';
import { Person } from '@geods/base';
import { ToastType } from '@wissenswerft/ww-library';
import { RiskAssessmentService } from '../../risk-assessment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input() risk: RiskIssue;
  @Input() modules: Module[];
  @ViewChild('commentsPopup') commentsPopup: DxSpeedDialActionComponent;
  public language = sessionStorage.getItem('fixedCulture');
  public allComments: Comment;
  public riskAssessmentId = "";
  public riskAssessmentComments: Comment[];
  public moduleId = null;
  public damageKinds: Observable<DamageKind[]>;
  public countries: Observable<Country[]>;
  public standards: Observable<Standard[]>;
  public responsibles: Observable<Person[]>;
  public subModules: SubModule[] = [];
  private subscriptions: Subscription[] = [];
  constructor(public dataService: DataService,
    public appService: AppService,
    private coreDataService: GeoDsCoreDataService,
    public riskAssessmentService: RiskAssessmentService,
    private router: Router) { }

  ngOnInit(): void {
    this.standards = this.dataService.readObjects(ObjectKeys.STANDARDLIBRARY);
    this.damageKinds = this.dataService.readObjects(ObjectKeys.DAMAGEKINDLIBRARY);
    this.countries = this.dataService.readObjects(ObjectKeys.COUNTRYLIBRARY);
    this.responsibles = this.dataService.prepareStaffMembers()
    this.subscriptions.push(this.getModuleswithSubModules().subscribe((modules) => {
      this.modules = modules;
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modules && Array.isArray(changes.modules.currentValue) && changes.modules.currentValue.length > 0) {
      const module: Module = changes.modules.currentValue.find((module) => module.SubModules && this.containsRiskIsueSubModule(module.SubModules));
      this.moduleId = module ? module.Id : null;
      this.subModules = module ? module.SubModules : [];
    }
    if (changes.risk && changes.risk.currentValue.SysDateUpdate) {

      this.riskAssessmentService.riskIssuesStandardIds =
        changes.risk.currentValue.Standards ?
          changes.risk.currentValue.Standards?.map((standard) => standard.IdRefstandard) : [];
      this.riskAssessmentService.riskIssuesDamageKindIds =
        changes.risk.currentValue.DamageKinds ?
          changes.risk.currentValue.DamageKinds?.map((damageKind) => damageKind.IdRefdamageKindLibrary) : [];

      this.riskAssessmentService.riskIssuesStandardIdsToUpdate =
        Array.isArray(this.riskAssessmentService.riskIssuesStandardIds) &&
          this.riskAssessmentService.riskIssuesStandardIds.length > 0 ?
          [...this.riskAssessmentService.riskIssuesStandardIds] : [];

      this.riskAssessmentService.riskIssuesDamageKindIdsToUpdate =
        Array.isArray(this.riskAssessmentService.riskIssuesDamageKindIds) &&
          this.riskAssessmentService.riskIssuesDamageKindIds.length > 0 ?
          [...this.riskAssessmentService.riskIssuesDamageKindIds] : [];

    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
  private containsRiskIsueSubModule(array: Array<SubModule>): boolean {
    const subModule = array.find((subModule) => subModule.Id === this.risk.IdRefsubModule);
    return subModule ? true : false;
  }

  public getComments(): void {
    this.commentsPopup.visible = true;
  }

  public getModuleswithSubModules(): Observable<Module[]> {
    const queryColumns: Array<QueryColumn> = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ]
    const moduleQuery: Query = new Query();
    moduleQuery.ObjectType = ObjectKeys.MODULE;
    moduleQuery.Columns = queryColumns;
    const subModuleQuery: Query = new Query();
    subModuleQuery.Name = 'SubModules';
    subModuleQuery.OPath = 'SubModules';
    subModuleQuery.Columns = queryColumns;
    moduleQuery.ObjectQueries = [subModuleQuery];
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectQueries = [moduleQuery];
    return this.coreDataService.executeReadObjectsQuery(queryDocument);
  }

  public modulesSelect(event): void {
    const designation = event.selectedItem ? event.selectedItem.Designation : "";
    if (event.selectedItem && event.selectedItem.SubModules) {
      this.subModules = event.selectedItem.SubModules;
    } else {
      if (designation.length) {
        this.appService.callNotification({
          message: this.dataService.res('Cmt-Create-Risk-Sub-Module-Warning-Mssg') + ' ' + designation + ' ' + this.dataService.res('Cmt-Create-Risk-Sub-Module-Warning-Mssg-Category'),
          type: ToastType.WARNING
        });
      }
      this.subModules = [];
    }
  }

  public selectionChanged(event, mapName: string): void {
    if (event.addedItems.length > 0) {
      this.riskAssessmentService[mapName].set(event.addedItems[0].Id, event.addedItems[0].Designation)
    }
    if (event.removedItems.length > 0) {
      this.riskAssessmentService[mapName].delete(event.removedItems[0].Id);
    }
  }

  public moveIssue(next: boolean): void {
    if (next) {
      this.riskAssessmentService.currentIssueIndex++;
    } else {
      this.riskAssessmentService.currentIssueIndex--;
    }
    let newIssueId = this.riskAssessmentService.riskIds[this.riskAssessmentService.currentIssueIndex];
    this.router.navigate(['riskAssessmentDetail', 'risks', newIssueId]);
  }


}
