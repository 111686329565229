<form action="post" (submit)="createMeasure($event)">
    <dx-form #form id="form" [(formData)]="measure" [colCount]="1">
        <dxi-item>
            <dx-select-box class="drop-down-style" valueExpr='Id' displayExpr="Designation" [stylingMode]="'outlined'"
                [labelMode]="'floating'" [dataSource]="projects" (onValueChanged)="loadSubProjects($event)"
                [label]="dataService.res('Cmt-Create-Measure-Project')">
            </dx-select-box>
        </dxi-item>
        <dxi-item [isRequired]="true">
            <dx-select-box class="drop-down-style" valueExpr='Id' displayExpr="Designation" [stylingMode]="'outlined'"
                [labelMode]="'floating'" [(value)]="subProjectId" [dataSource]="subProjects"
                [label]="dataService.res('Cmt-Create-Measure-SubProject')">
            </dx-select-box>
            <dxi-validation-rule type="required" message="{{dataService.res('Ubt-validator-RequiredField')}}">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item [isRequired]="true">
            <dx-text-box #label [labelMode]="labelMode" [stylingMode]="stylingMode" [(value)]="measure.Title"
                [label]="this.dataService.res('Cmt-Designation')" [width]="'100%'">
                <dx-validator>
                    <dxi-validation-rule type="required" message="{{this.dataService.res('Cmt-Designation')}}">
                    </dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item [isRequired]="true">
                <dx-date-box #startDate class="dateBoxStyle" [type]="'date'" [stylingMode]="stylingMode"
                    [labelMode]="labelMode" [(value)]="measure.StartDate"
                    [label]="this.dataService.res('Cmt-CreateMeasure-Start-Date')">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="{{this.dataService.res('Cmt-CreateMeasure-Start-Date-Required')}}">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </dxi-item>
            <dxi-item [isRequired]="true">
                <dx-date-box #endDate class="dateBoxStyle" [(min)]="measure.StartDate" [type]="'date'"
                    [(value)]="measure.EndDate" [labelMode]="labelMode" [stylingMode]="stylingMode"
                    [label]="this.dataService.res('Cmt-CreateMeasure-End-Date')">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="{{this.dataService.res('Cmt-CreateMeasure-End-Date-Required')}}">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item [isRequired]="true">
                <dx-select-box #responsibility [dataSource]="dataService.cachedResponsiblesResponse"
                    [labelMode]="labelMode" [width]="'100%'" class="drop-down-style" [stylingMode]="stylingMode"
                    [displayExpr]="dataService.getResponsibleFullName"
                    [label]="this.dataService.res('Cmt-CreateMeasure-Responsibility')" valueExpr="Id"
                    [acceptCustomValue]="true" [(value)]="measure.Responsible" [acceptCustomValue]="true">
                </dx-select-box>
            </dxi-item>
            <dxi-item [isRequired]="true">
                <dx-select-box class="drop-down-style" valueExpr='Id' [displayExpr]="dataService.getResponsibleFullName"
                    [stylingMode]="'outlined'" [(value)]="measure.AreaManager"
                    [dataSource]="dataService.cachedResponsiblesResponse"
                    [label]="dataService.res('Cmt-CreateMeasure-Area-Manager')" [labelMode]="'floating'">
                </dx-select-box>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item>
                <dx-select-box #structure class="drop-down-style" fxFlex="100" [dataSource]="orgaTech"
                    [(value)]='measure.OrgaTech' [label]="this.dataService.res('Cmt-CreateMeasure-structure')"
                    [labelMode]="labelMode" [stylingMode]="stylingMode" displayExpr='label' valueExpr='id'>
                </dx-select-box>
            </dxi-item>
            <dxi-item>
                <dx-select-box #controls class="drop-down-style" fxFlex="100" [dataSource]="prevDect"
                    [(value)]='measure.PrevDect' [label]="this.dataService.res('Cmt-CreateMeasure-controls')"
                    [labelMode]="labelMode" [stylingMode]="stylingMode" displayExpr='label' valueExpr='id'>
                </dx-select-box>
            </dxi-item>
        </dxi-item>
        <dxi-item>
            <dx-text-area #target [labelMode]="labelMode" [(value)]="measure.Goal" [stylingMode]="stylingMode"
                [label]="this.dataService.res('Cmt-CreateMeasure-Target')" [width]="'100%'">
            </dx-text-area>
        </dxi-item>
        <dxi-item>
            <dx-text-area [stylingMode]="'outlined'" [width]="'100%'" [(value)]="measure.InitialSituation"
                [label]="dataService.res('Cmt-Measure-List-InitialSituation')" [labelMode]="'floating'">
            </dx-text-area>
        </dxi-item>
        <dxi-item itemType="group" [cssClass]="'form-buttons-container'">
            <dxi-item itemType="button" [cssClass]="'submit-button'" [buttonOptions]="createButtonOptions"></dxi-item>
            <dxi-item>
                <dx-button class="cancel-button" stylingMode="contained"
                    text="{{this.dataService.res('Cmt-CreateMesure-Cancel')}}" type="normal" [width]="'auto'"
                    id="button" (onClick)="cancel()">
                </dx-button>
            </dxi-item>
        </dxi-item>
    </dx-form>
</form>