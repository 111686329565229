<div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxLayout="column" fxLayoutAlign="space-around center">
        <div fxLayout="row">
            <p class="ellipsis label">{{dataService.res('Cmt-MeasureDetail-Implementation')}}</p>
        </div>
        <div fxLayout="row">
            <dx-linear-gauge id="degreeOfImplementationGauge" [value]='average("DegreeOfImplementation")'>
                <dxo-subvalue-indicator [offset]="5"></dxo-subvalue-indicator>
                <dxo-range-container [offset]="10">
                    <dxi-range [startValue]="0" [endValue]="25" color="#92000A"></dxi-range>
                    <dxi-range [startValue]="25" [endValue]="50" color="#FF4500"></dxi-range>
                    <dxi-range [startValue]="50" [endValue]="75" color="#E6E200"></dxi-range>
                    <dxi-range [startValue]="75" [endValue]="100" color="#77DD77"></dxi-range>
                </dxo-range-container>
                <dxo-size [height]="200"></dxo-size>
                <dxo-geometry orientation="vertical"></dxo-geometry>
                <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="25">
                    <dxo-tick color="#536878"></dxo-tick>
                    <dxo-label [customizeText]="customizeText"></dxo-label>
                </dxo-scale>
                <dxo-value-indicator type="textCloud" color="#536878"></dxo-value-indicator>
            </dx-linear-gauge>
        </div>
    </div>
    <div fxLayout="column" fxFlex="30">
        <div fxLayout="row">
            <p class="ellipsis label">{{dataService.res('Cmt-MeasureDetail-Efficiency')}}</p>
        </div>
        <div fxLayout="row">
            <dx-linear-gauge id="degreeOfImplementationGauge" [value]='average("Efficiency")'>
                <dxo-subvalue-indicator [offset]="5"></dxo-subvalue-indicator>
                <dxo-range-container [offset]="10">
                    <dxi-range [startValue]="0" [endValue]="25" color="#92000A"></dxi-range>
                    <dxi-range [startValue]="25" [endValue]="50" color="#FF4500"></dxi-range>
                    <dxi-range [startValue]="50" [endValue]="75" color="#E6E200"></dxi-range>
                    <dxi-range [startValue]="75" [endValue]="100" color="#77DD77"></dxi-range>
                </dxo-range-container>
                <dxo-size [height]="200"></dxo-size>
                <dxo-geometry orientation="vertical"></dxo-geometry>
                <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="25">
                    <dxo-tick color="#536878"></dxo-tick>
                    <dxo-label [customizeText]="customizeText"></dxo-label>
                </dxo-scale>
                <dxo-value-indicator type="textCloud" color="#536878"></dxo-value-indicator>
            </dx-linear-gauge>
        </div>
    </div>
</div>