import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { ChangeMode, DataService, ObjectKeys } from '../../../services/data.service';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { PersistMode } from '@wissenswerft/core/data';
import { AppService } from '../../../services/app.service';
import { MeasureBenefit } from '@xmt-models';
@Component({
  selector: 'measure-benefit',
  templateUrl: './measure-benefit.component.html',
  styleUrls: ['./measure-benefit.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureBenefitComponent implements OnInit, OnDestroy {
  @ViewChild('MeasureBenefitGrid') MeasureBenefitGrid: GridComponent;
  public measureBenefitList: MeasureBenefit[] = [];
  public showLoader: boolean;
  public title: string;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public appService: AppService) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.dataService.readObjects<MeasureBenefit[]>(ObjectKeys.MEASUREBENEFITLIBRARY).subscribe(measureBenefits => {
      this.columnsHeader.push({
        dataField: 'Designation',
        caption: this.dataService.res('Cmt-Designation'),
        dataType: 'string'
      });
      if (Array.isArray(measureBenefits) && measureBenefits.length > 0) {
        this.measureBenefitList = measureBenefits;
      } else {
        this.measureBenefitList = [];
      }
      this.cdr.markForCheck();
    }, error => {
      this.appService.callNotification({ message: error, type: ToastType.ERROR })
      console.error(error);
    }, () => {
      this.showLoader = false;
    })
  }

  public addRow() {
    this.MeasureBenefitGrid.addRow();
  }

  public persistMeasureBenefit = (event): void => {
    if (event.changes[0].type === ChangeMode.Insert) {
      this.subscriptions.push(this.dataService.persistObject<MeasureBenefit>(ObjectKeys.MEASUREBENEFITLIBRARY, PersistMode.Insert, event.changes[0].key.Designation).subscribe(measureBenefit => {
        if (!this.measureBenefitList[this.measureBenefitList.length - 1]?.Id) {
          this.measureBenefitList[this.measureBenefitList.length - 1].Id = measureBenefit.Id;
        }
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    } else if (event.changes[0].type === ChangeMode.Remove) {
      this.subscriptions.push(this.dataService.deleteObject(ObjectKeys.MEASUREBENEFITLIBRARY, event.changes[0].key.Id).subscribe(measureBenefits => {
        this.dataService.updateGridData(measureBenefits);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    } else {
      this.subscriptions.push(this.dataService.persistObject(ObjectKeys.MEASUREBENEFITLIBRARY, PersistMode.Update, event.changes[0].key.Designation, event.changes[0].key.Id).subscribe(measureBenefits => {
        this.dataService.updateGridData(measureBenefits);
      }, error => {
        console.error(error);
        this.appService.callNotification({ message: error, type: ToastType.ERROR })
      }, () => {
        this.appService.callNotification({ message: this.dataService.res("Cmt-Notification-Success"), type: ToastType.SUCCESS })
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }

}

