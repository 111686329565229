import { Injectable } from '@angular/core';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumn, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Company } from '@wissenswerft/organizational-structure';
import { Observable } from 'rxjs';
import { DataService, ObjectKeys } from '../services/data.service';
@Injectable({
    providedIn: 'root'
})

export class HomeService {
    constructor(
        public dataService: DataService,
        private persistenceService: GeoDsPersistenceService
    ) { }

    public prepareQueryToGetCompanies(columns: QueryColumn[]): Observable<Company[]> {
        return this.dataService.readObjects<Company[]>(ObjectKeys.ADDRESS, columns)
    }

    public persistCompany<T>(buttonAction: string, companyColumns: TargetColumnValue[], company: Company): Observable<T> {
        const companyPersistQuery: TargetObjectData = new TargetObjectData();
        companyPersistQuery.ObjectKey = new ObjectKey();
        companyPersistQuery.ObjectKey.ObjectType = ObjectKeys.ADDRESS;
        if (buttonAction === 'edit') {
            companyPersistQuery.Mode = PersistMode.Update;
            companyPersistQuery.ObjectKey.Id = company.Id;
        } else {
            companyPersistQuery.Mode = PersistMode.Insert;
        }
        companyPersistQuery.TargetColumns = companyColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = companyPersistQuery;
        return this.persistenceService.executePersistObjectQuery(persistObject)

    }
    public deleteCompany(companyId: string): Observable<any> {
        return this.dataService.deleteObject<Company>(ObjectKeys.ADDRESS, companyId)
    }

    public formatContactNumber(value: string) {
        if (value) {
            return "+" + value.substring(0, 2) + "(" + value.substring(2, 6) + ")" + value.substring(6, 11) + "-" + value.substring(11, 13);
        } else {
            return null;
        }
    }

    public validTextBoxStatus(emailTextBox, phoneTextBox, faxTextBox): boolean {
        if ((emailTextBox.validationStatus !== 'invalid') && (phoneTextBox.validationStatus !== 'invalid') && (faxTextBox.validationStatus !== 'invalid')) {
            return true;
        } else return false;
    }
}